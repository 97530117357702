import { Container } from 'inversify'
import { probeActiveEntityModules } from './modules'

const probeContainer = new Container()

probeContainer.load(probeActiveEntityModules)

export const getProbeModule = <T>(key: symbol) => {
  return probeContainer.get<T>(key)
}

export { probeContainer }
