import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'

import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Button, RadioButtonGroup } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'

import FilterDropdown from '../FilterDropdown'

import styles from './index.scss'
import { useDebounceEffect } from '@clain/core/hooks'

const cx = classnames.bind(styles)

type AmountFilterValue = { from: string; to: string }

interface AmountFilterProps {
  value: AmountFilterValue
  onChange: (value: AmountFilterValue) => void
  disabled?: boolean
  code: string
}

const DEBOUNCE_DELAY = 300

const AmountFilter: React.FC<PropsWithChildren<AmountFilterProps>> = ({
  value,
  onChange,
  children,
  disabled,
  code,
}) => {
  const [radioState, setState] = React.useState('greater')
  const [amount, setAmount] = React.useState<AmountFilterValue>(value)

  useDebounceEffect(
    () => {
      onChange(amount)
    },
    DEBOUNCE_DELAY,
    [amount]
  )

  const radioOptions = [
    {
      value: 'greater',
      label: (
        <Typography variant="body1-sm" color="black">
          Greater or equal to ( ≥ )
        </Typography>
      ),
    },
    {
      value: 'less',
      label: (
        <Typography variant="body1-sm" color="black">
          Less or equal to ( ≤ )
        </Typography>
      ),
    },
    {
      value: 'equal',
      label: (
        <Typography variant="body1-sm" color="black">
          Equal to ( = )
        </Typography>
      ),
    },
    {
      value: 'between',
      label: (
        <Typography variant="body1-sm" color="black">
          Between ( ≥ ≤ )
        </Typography>
      ),
    },
  ]

  const onReset = () => {
    setState('greater')
    setAmount({ from: undefined, to: undefined })
  }

  const changeRadioState = (radioState: string) => {
    setAmount({ from: undefined, to: undefined })
    setState(radioState)
  }

  const isValid = (value: string) => {
    return /^\d*\.?\d*$/.test(value)
  }

  const handleFromAmount = (from: string) => {
    if (isValid(from)) {
      setAmount((prevValue) => ({ ...prevValue, from }))
    }
  }
  const handleToAmount = (to: string) => {
    if (isValid(to)) {
      setAmount((prevValue) => ({ ...prevValue, to }))
    }
  }

  const content = (
    <Container className={cx('AmountFilter')}>
      <ColDeprecated>
        <RadioButtonGroup
          options={radioOptions}
          direction="column"
          value={radioState}
          onChange={changeRadioState}
        />
        {radioState === 'greater' && (
          <TextField
            variant="outline"
            size="sm"
            startAddon={{ label: `≥ ${code}` }}
            value={amount.from || ''}
            onChange={handleFromAmount}
          />
        )}
        {radioState === 'less' && (
          <TextField
            variant="outline"
            size="sm"
            startAddon={{ label: `≤ ${code}` }}
            value={amount.to || ''}
            onChange={handleToAmount}
          />
        )}
        {radioState === 'equal' && (
          <TextField
            variant="outline"
            size="sm"
            startAddon={{ label: `= ${code}` }}
            value={amount.from || ''}
            onChange={(newValue) => {
              if (isValid(newValue)) {
                setAmount({
                  from: newValue,
                  to: newValue,
                })
              }
            }}
          />
        )}
        {radioState === 'between' && (
          <>
            <TextField
              variant="outline"
              size="sm"
              startAddon={{ label: `≥ ${code}` }}
              value={amount.from || ''}
              onChange={handleFromAmount}
            />
            <TextField
              variant="outline"
              size="sm"
              startAddon={{ label: `≤ ${code}` }}
              value={amount.to || ''}
              onChange={handleToAmount}
            />
          </>
        )}
        <Button size="md" variant="outline" color="secondary" onClick={onReset}>
          reset
        </Button>
      </ColDeprecated>
    </Container>
  )

  return (
    <FilterDropdown content={content} disabled={disabled}>
      {children}
    </FilterDropdown>
  )
}

export default AmountFilter
