import 'reflect-metadata'
import App, { RootContent } from '@clain/core/App'
import React, { PropsWithChildren } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { observer } from 'mobx-react-lite'
import { useAuth, useHttpInterceptorEffect } from '@clain/core/Auth'
import { useSettings } from './hooks'
import { ctx, useCtx } from './ctx'
import { ThemeProvider } from 'styled-components'
import { enterprise } from '@clain/core/ui-kit'
import SubscriptionExpired from './components/SubscriptionExpired'
import NoAccess from './components/NoAccess'
import { navItems } from './navItems'
import { defaultParams, paramsState } from './routes'
import SidebarTop from './components/SidebarTop'
import SidebarBottom from './components/SidebarBottom'
import http from '@clain/core/http'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()

const wss = config?.PLATFORM_WS
const refreshApiToken = async (token: string) => {
  http.config({ token })
  await ctx.reconnectWithNewToken(token)
}
const initApiToken = async (
  token: string,
  refreshToken: () => Promise<string>
) => {
  http.config({ token })
  ctx.init({ wss, token, refreshToken })
}
const AppComponent: React.FC<PropsWithChildren> = withAuthenticationRequired(
  observer(({ children }) => {
    const { isTokenProvided, logout, refreshToken } = useAuth({
      initApiToken,
      refreshApiToken,
    })
    useHttpInterceptorEffect({
      refreshToken,
      logout,
    })
    const { settingsProfile } = useSettings()
    const {
      userState: { noAccess, subscriptionExpired },
    } = useCtx()
    const renderApp = !noAccess && !subscriptionExpired && isTokenProvided

    return (
      <ThemeProvider theme={enterprise['light']}>
        {subscriptionExpired && <SubscriptionExpired />}
        {noAccess && <NoAccess />}
        {renderApp && (
          <App
            navItems={navItems}
            paramsState={paramsState}
            defaultParams={defaultParams}
            sidebarTop={SidebarTop}
            sidebarBottom={SidebarBottom}
            logout={logout}
          >
            {children}
          </App>
        )}
      </ThemeProvider>
    )
  })
)
export default function RootApp() {
  return <RootContent AppComponent={AppComponent} />
}
