import React, { memo, useEffect, useRef } from 'react'
import { RightOverlapSidebarSlot } from '@clain/core/Layout/slots'
import { Presets } from '@platform/components/BlockchainFilter/ui/PresetsList/Presets'
import { InjectComponentModel } from '@clain/core/utils'
import { useRightOverlapSidebarStateSetter } from '@clain/core/state'
import { withErrorBoundary } from '@clain/core/ErrorBoundary'
import { RightOverlapSidebar } from '@clain/core/Layout/RightOverlapSidebar'
import { PresetDeleteModal } from '@platform/components/BlockchainFilter/ui'
import { dataTransactionsSearchViewModel } from '@platform/components/BlockchainFilter/di/transactions/containers'
import { toJS } from 'mobx'
import { convertFromUnixTimestamp } from '@clain/core/utils/date'
import { useFiltersActions } from '@platform/components/BlockchainFilter/entity/TransactionsSearch'
import { normalizeInternFilters } from '@platform/components/BlockchainFilter/entity/TransactionsSearch/Filters/TrxFilters.hooks'
import cloneDeep from 'lodash/cloneDeep'

type BlockchainFilterRightSidebarProps = Pick<
  typeof dataTransactionsSearchViewModel,
  | 'presets'
  | 'createPreset'
  | 'deletePreset'
  | 'selectedPreset'
  | 'setSelectedPreset'
  | 'isTransactionsTableDataLoading'
  | 'createPresetAllowed'
>

const _BlockchainFilterRightSidebar: React.FC<BlockchainFilterRightSidebarProps> =
  memo(
    ({
      presets,
      createPreset,
      deletePreset,
      selectedPreset,
      setSelectedPreset,
      isTransactionsTableDataLoading,
      createPresetAllowed,
    }) => {
      const deletePresetIdRef = useRef(null)
      const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false)
      const { setFilters } = useFiltersActions()

      const handleReadyToDeletePreset = (id: number) => {
        setIsDeleteModalOpen(true)
        deletePresetIdRef.current = id
      }

      const handleDeletePreset = async (id: number) => {
        deletePresetIdRef.current = null
        await deletePreset(id)
      }

      const handleSelectPreset = (preset: (typeof presets)[number]) => {
        setSelectedPreset(preset)
        const newFilters = preset.filters
        setFilters(
          normalizeInternFilters({
            ...newFilters,
            trx_time_from: convertFromUnixTimestamp(newFilters.trx_time_from),
            trx_time_to: convertFromUnixTimestamp(newFilters.trx_time_to),
          })
        )
      }
      const setIsRightOverlapSidebarOpen = useRightOverlapSidebarStateSetter()

      useEffect(() => {
        return () => {
          setIsRightOverlapSidebarOpen(false)
        }
      }, [])

      RightOverlapSidebarSlot.useContent(
        ({ toggle, isOpen }) => {
          return (
            <>
              <RightOverlapSidebar
                isOpen={isOpen}
                toggle={toggle}
                width={'360px'}
                clickOutside={!isDeleteModalOpen}
              >
                <Presets>
                  <Presets.Layout>
                    <Presets.Header
                      isDisabledCreatePreset={!createPresetAllowed}
                      isLoading={isTransactionsTableDataLoading}
                      onCloseModal={toggle}
                    />
                    <Presets.Body
                      isDisabledCreatePreset={!createPresetAllowed}
                      isLoading={isTransactionsTableDataLoading}
                      data={presets}
                      selectedPreset={selectedPreset?.id}
                      selectPreset={handleSelectPreset}
                      createPreset={createPreset}
                      deletePreset={handleReadyToDeletePreset}
                    />
                  </Presets.Layout>
                </Presets>
              </RightOverlapSidebar>
              <PresetDeleteModal
                onClose={() => {
                  setIsDeleteModalOpen(false)
                }}
                isOpen={isDeleteModalOpen}
                onDelete={() => handleDeletePreset(deletePresetIdRef.current)}
              />
            </>
          )
        },
        [
          presets,
          createPreset,
          handleDeletePreset,
          handleReadyToDeletePreset,
          setIsDeleteModalOpen,
          selectedPreset,
          isDeleteModalOpen,
        ]
      )
      return null
    }
  )

_BlockchainFilterRightSidebar.displayName = 'BlockchainFilterRightSidebar'

export const BlockchainFilterRightSidebar = withErrorBoundary(
  InjectComponentModel<BlockchainFilterRightSidebarProps>(
    _BlockchainFilterRightSidebar,
    {
      useFactory: () => {
        return {
          presets: cloneDeep(dataTransactionsSearchViewModel.presets),
          createPreset: dataTransactionsSearchViewModel.createPreset,
          deletePreset: dataTransactionsSearchViewModel.deletePreset,
          selectedPreset: toJS(dataTransactionsSearchViewModel.selectedPreset),
          setSelectedPreset: dataTransactionsSearchViewModel.setSelectedPreset,
          isTransactionsTableDataLoading: toJS(
            dataTransactionsSearchViewModel.isTransactionsTableDataLoading
          ),
          createPresetAllowed: toJS(
            dataTransactionsSearchViewModel.createPresetAllowed
          ),
        }
      },
    }
  )
)
