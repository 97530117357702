import React, { FC } from 'react'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clainio/web-platform'
import { Icon } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { TagDeprecated } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import classnames from 'classnames/bind'
import styles from './ProbeGenerateModalHeader.scss'
const cx = classnames.bind(styles)
interface ProbeGenerateModalHeaderProps {
  hide: () => void
}

const AI_DOCS_URL = 'https://docs.clain.io/'

export const ProbeGenerateModalHeader: FC<ProbeGenerateModalHeaderProps> = ({
  hide,
}) => {
  return (
    <ColDeprecated gap={0.75} className={cx('Header')}>
      <RowDeprecated align="between">
        <RowDeprecated gap={0.5}>
          <Typography variant="subheading2" color="black">
            AI Report
          </Typography>
          <TagDeprecated>BETA</TagDeprecated>
        </RowDeprecated>
        <Icon variant={'Close'} onClick={hide} color={'onBackgroundVariant1'} />
      </RowDeprecated>
      <Typography variant={'body1-sm'} color={'grey2'}>
        Our AI Report feature is currently in the testing phase. We invite you
        to help us uncover any potential issues and enhance the tool, aiming to
        make it a valuable and dependable asset for your investigations. We
        recommend reading the&nbsp;
        <Link to={AI_DOCS_URL}>complete documentation</Link>&nbsp;for further
        information.
      </Typography>
    </ColDeprecated>
  )
}
