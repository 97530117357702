import {
  ApiServicesStateReactionFacade,
  APIServiceStateFacade,
  APIServiceStateViewModel,
  StateViewModel,
} from '@clain/core/utils/mobxUtils'
import {
  AddressesRequestParams,
  CounterpartiesRequestParams,
  OsintsRequestParams,
  StatsRequestParams,
  TokenByAddressRequestParams,
  TokensRequestParamsCluster,
  TransactionsRequestParams,
} from '../../ProbeSandbox/types/requestParams/EntityServices'
import { ClusterTransactions } from '../../ProbeSandbox/types/converted/ClusterTransactions'
import { ClusterStats } from '../../ProbeSandbox/types/converted/Stats'
import { GetTokensPayload } from '../../ProbeSandbox/vm/services/EntitiesServices/ClusterService'
import { TransactionsBtcFilters } from '../../ProbeSandbox/types/filters/TransactionsBtcFilters'
import { Tokens } from '../../ProbeSandbox/types/converted/Tokens'
import { CoinType } from '../../../types/coin'
import { ClusterCounterparties } from '../../ProbeSandbox/types/converted/ClusterCounterparties'
import { CounterpartiesFilters } from '../../ProbeSandbox/types/filters/CounterpartiesFilters'
import { ClusterAddresses } from '../../ProbeSandbox/types/converted/ClusterAddresses'
import { AddressesFilters } from '../../ProbeSandbox/types/filters/AddressesFilters'
import { Osints } from '../../ProbeSandbox/types/converted/Osints'
import { OsintsFilters } from '../../ProbeSandbox/types/filters/OsintsFilters'
import { Token } from '@platform/components/ProbeSandbox/types/converted/TokenBalance'
import { GetTokenByAddressPayload } from '@platform/components/ProbeSandbox/vm/services/EntitiesServices/ExplorerService'

const statsFacade = new APIServiceStateFacade<StatsRequestParams, ClusterStats>(
  new APIServiceStateViewModel()
)
const tokensFacade = new APIServiceStateFacade<
  TokensRequestParamsCluster,
  Tokens
>(new APIServiceStateViewModel())
const transactionsFacade = new APIServiceStateFacade<
  TransactionsRequestParams,
  ClusterTransactions
>(new APIServiceStateViewModel())
const counterpartiesFacade = new APIServiceStateFacade<
  CounterpartiesRequestParams,
  ClusterCounterparties
>(new APIServiceStateViewModel())
const addressesFacade = new APIServiceStateFacade<
  AddressesRequestParams,
  ClusterAddresses
>(new APIServiceStateViewModel())
const osintFacade = new APIServiceStateFacade<OsintsRequestParams, Osints>(
  new APIServiceStateViewModel()
)
const tokenByAddressFacade = new APIServiceStateFacade<
  TokenByAddressRequestParams,
  Token
>(new APIServiceStateViewModel())

const tokensApiParams = new StateViewModel<
  Omit<GetTokensPayload, 'clusterId'>
>()
const transactionsApiParams = new StateViewModel<TransactionsBtcFilters>({})
const counterpartiesApiParams = new StateViewModel<CounterpartiesFilters>({})
const addressesApiParams = new StateViewModel<AddressesFilters>({})
const osintApiParams = new StateViewModel<OsintsFilters>({})
const tokenByAddressApiParams = new StateViewModel<
  Partial<GetTokenByAddressPayload>
>({})

export const commonPageData = new StateViewModel<{
  entityId: number
  blockchain: CoinType
}>({
  entityId: null,
  blockchain: null,
})

export const apiServicesStateFacade = new ApiServicesStateReactionFacade({
  apiServiceStates: {
    tokens: tokensFacade,
    stats: statsFacade,
    transactions: transactionsFacade,
    counterparties: counterpartiesFacade,
    addresses: addressesFacade,
    osint: osintFacade,
    token: tokenByAddressFacade,
  },
  apiParams: {
    tokens: tokensApiParams,
    transactions: transactionsApiParams,
    counterparties: counterpartiesApiParams,
    addresses: addressesApiParams,
    osint: osintApiParams,
    token: tokenByAddressApiParams,
  },
})
