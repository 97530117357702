import React, { useRef } from 'react'
import { Typography, Icon, Button, AlertCaution } from '@clain/core/ui-kit'
import Form, { Field, validators } from '@clain/core/Form'
import { ColDeprecated } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Textarea } from '@clain/core/ui-kit'
import { Checkbox } from '@clain/core/ui-kit'

export const AddMultipleNodesModalBody = ({
  hide,
  formError,
  formLoading,
  handleBulkSearch,
}) => {
  const cancelButtonRef = useRef(null)
  const onFormSubmit = async (data) => {
    await handleBulkSearch(data, hide)
  }

  return (
    <React.Fragment>
      <ColDeprecated gap={2.1}>
        <RowDeprecated align="between">
          <Typography variant="subheading2" color="black">
            Add Multiple Addresses and Transactions
          </Typography>
          <div>
            <Icon
              variant={'Close'}
              onClick={hide}
              color={'onBackgroundVariant1'}
            />
          </div>
        </RowDeprecated>
        <RowDeprecated>
          <Form onSubmit={onFormSubmit}>
            {({ form }) => {
              const { valid } = form.getState()

              return (
                <ColDeprecated gap={1.5}>
                  <RowDeprecated>
                    <Typography variant={'body1'} color="grey2">
                      Paste the text containing the hashes of blockchain
                      addresses and/or transactions to add to the graph. Our
                      system will recognize all the necessary data.
                    </Typography>
                  </RowDeprecated>
                  <RowDeprecated>
                    <Field
                      fullWidth
                      name="term"
                      variant="outline"
                      as={Textarea}
                      rows={15}
                      placeholder="Paste the text with hashes"
                      autoFocus={true}
                      validate={validators.required()}
                    />
                  </RowDeprecated>
                  <RowDeprecated gap={0.75}>
                    <Field
                      name="withClusters"
                      as={Checkbox}
                      defaultValue={false}
                    />
                    <Typography variant={'body1'} color="black">
                      Plot relevant clusters for the addresses
                    </Typography>
                  </RowDeprecated>
                  {formError && (
                    <AlertCaution variant="error">{formError}</AlertCaution>
                  )}
                  <RowDeprecated align={'right'}>
                    <Button
                      ref={cancelButtonRef}
                      size="lg"
                      variant="outline"
                      color="secondary"
                      onClick={hide}
                    >
                      Close
                    </Button>
                    <Button
                      style={{
                        width: cancelButtonRef.current?.clientWidth || 'auto',
                      }}
                      isLoading={formLoading}
                      disabled={!valid}
                      size="lg"
                      variant="solid"
                      color="primary"
                      type="submit"
                    >
                      Add
                    </Button>
                  </RowDeprecated>
                </ColDeprecated>
              )
            }}
          </Form>
        </RowDeprecated>
      </ColDeprecated>
    </React.Fragment>
  )
}
