import React from 'react'
import {
  ComparatorsValueFilter,
  ComparatorsValueFilterMenu,
} from '@platform/components/Filters'
import { ComparatorFilterState } from '@platform/components/BlockchainFilter/types'
import { injectFiltersModel } from '@platform/components/BlockchainFilter/context'
import { ComponentFilterKey } from 'packages/core/utils'

export type ComparatorFilterContainerProps = ComponentFilterKey & {
  label: string
  title: string
  units?: string
  isDecimalsOnly?: boolean
}

export type ComparatorMenuContainerProps = ComponentFilterKey & {
  title?: string
  units?: string
  isDecimalsOnly?: boolean
}

const MenuContainer = injectFiltersModel<
  ComparatorMenuContainerProps,
  Record<string, ComparatorFilterState>
>(({ filterKey, title, units, isDecimalsOnly, useFilterSlice }) => {
  const [filter, { setFilter }] = useFilterSlice(filterKey.root)

  return (
    <ComparatorsValueFilterMenu
      values={filter.data}
      title={title}
      label={units}
      onChange={setFilter}
      selectedCondition={filter.condition}
      size={'md'}
      isDecimalsOnly={isDecimalsOnly}
    />
  )
})

const FilterContainer = injectFiltersModel<
  ComparatorFilterContainerProps,
  Record<string, ComparatorFilterState>
>(({ filterKey, label, units, title, useFilterSlice }) => {
  const [, { resetFilter }] = useFilterSlice(filterKey.root)
  const [values] = useFilterSlice(filterKey.data)
  const [condition] = useFilterSlice(filterKey.condition)

  return (
    <ComparatorsValueFilter
      values={values}
      selectedCondition={condition}
      size={'md'}
      label={label}
      units={units}
      clearFilter={resetFilter}
      menuComponent={
        <MenuContainer filterKey={filterKey} title={title} units={units} />
      }
    />
  )
})

export const ComparatorFilter = {
  Container: FilterContainer,
  Menu: MenuContainer,
}
