import Header from '../../Header'
import { Typography, BlockchainIcon, Button, Stack } from '@clain/core/ui-kit'
import useTitle from '@clain/core/useTitle'
import { useParams } from '@clain/core/Router/router'
import { dataStatsViewModel } from '../../EntityPage/ClusterViewModels'
import { CoinType } from '../../../types/coin'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { usePlotEntitiesOnGraph } from '@platform/hooks'

export const useHeaderSlot = () => {
  const { data } = dataStatsViewModel
  const { plotSelectedOnGraph, setSelectEntities } =
    usePlotEntitiesOnGraph('clusters')

  const params = useParams<{ id: string; name: string; coin: CoinType }>()

  const clusterTitle =
    params.id && Number(params.id) !== data?.clusterId
      ? params.name
      : data?.entity?.name || params.id

  useTitle(clusterTitle)

  HeaderSlot.useContent(
    () => (
      <Header
        withNavSearch={false}
        withNavIcons={false}
        title={
          <Stack gap="md" align="center">
            <Typography variant={'heading1'} color="black">
              Cluster
            </Typography>
            <BlockchainIcon currency={params.coin} />
          </Stack>
        }
        actions={
          <Button
            variant="outline"
            color="secondary"
            endIcon="GraphPlot"
            onClick={() => {
              setSelectEntities(
                { clusterId: Number(params.id), currency: params.coin },
                true
              )
              plotSelectedOnGraph()
            }}
          >
            Plot Cluster
          </Button>
        }
      />
    ),
    [params.coin]
  )
}
