import React from 'react'
import { observer } from 'mobx-react-lite'
import { ScoreSquare, RowDeprecated, ActivityPeriod } from '@clain/core/ui-kit'
import {
  EntityDescriptionWrapper,
  EntityDetailsLayout,
  RowAddresses,
  RowBalance,
  RowCategory,
  RowCountry,
  RowEntityName,
  RowWebsite,
} from '../../../EntityDetails'
import { useClusterDetailsData } from './useClusterDetailsData'

const ClusterDetailsComponent = () => {
  const {
    detailsData: {
      isLoading,
      score,
      entityName,
      category,
      country,
      size,
      description,
      statsPeriodLocal,
      balance,
      totalReceived,
      totalSent,
      transactionsCount,
      firstLink,
      restLinks,
      hrefs,
    },
    formatDate,
    formatNumber,
  } = useClusterDetailsData()

  return (
    <EntityDetailsLayout
      isLoading={isLoading}
      scoreSlot={<ScoreSquare value={score} />}
      titleSlot={<RowEntityName name={entityName} />}
      informationSlot={
        <RowDeprecated gap={1.5}>
          <RowCategory category={category} />
          <RowWebsite
            firstLink={firstLink}
            hrefs={hrefs}
            restLinks={restLinks}
          />
          <RowCountry country={country} />
          <RowAddresses size={size} formatNumber={formatNumber} />
        </RowDeprecated>
      }
      descriptionSlot={
        <EntityDescriptionWrapper>{description}</EntityDescriptionWrapper>
      }
      activityPeriodSlot={
        <ActivityPeriod
          today={formatDate(new Date(), 'date')}
          activityPeriod={statsPeriodLocal}
        />
      }
      balanceSlot={
        <RowBalance
          balance={balance}
          received={totalReceived}
          sent={totalSent}
          transactions={transactionsCount}
        />
      }
    />
  )
}

export const ClusterDetails = observer(ClusterDetailsComponent)
