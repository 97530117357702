import React from 'react'

import { useTableStubs } from '@clain/core/ui-kit'
import { Checkbox } from '@clain/core/ui-kit'

import {
  TransactionTokenAddressBtc,
  TransactionUtxo,
} from '../../../../types/converted/TransactionUtxo'

import { TokenItem } from '../../../../../TransactionBlock/utxo/TokenItem'
import { CoinType } from '../../../../../../types/coin'
import { TokenAmountBlock } from '../../../../../TransactionBlock/utxo/TokenAmountBlock'
import { DataGrid, Icon, TableColumn } from '@clainio/web-platform'

import * as S from './TokenUtxoTable.styles'
import { IconLeftRightWrapperStyled } from '../../../../../TransactionBlock/utxo/TransactionBlockUTXO.styles'
import { TokenIconBlock } from '../../../../../TransactionBlock/utxo/TokenIconBlock'

export interface TokenBtcTableItem
  extends Pick<TransactionUtxo, 'inputs' | 'outputs' | 'id' | 'token'> {
  selected: boolean
  isProcessing: boolean
}

interface TokenBtcTableProps {
  data?: Array<TokenBtcTableItem>
  setSelected: (data: TokenBtcTableItem, selected: boolean) => void
  isActive?: (item: TransactionTokenAddressBtc) => boolean
  blockchain: CoinType
}

const TokenUtxoTable: React.FC<TokenBtcTableProps> = ({
  data,
  setSelected,
  isActive,
  blockchain,
}) => {
  const { stubbedData, stubbedColumns } = useTableStubs(
    [
      {
        name: 'address',
        width: 3,
        render: (item: TokenBtcTableItem) => {
          const { token } = item
          return (
            <S.AddressRow gap={0.75} fullWidth>
              <IconLeftRightWrapperStyled>
                <TokenIconBlock
                  valid={token.valid}
                  reason={token.reason}
                  type={token.type}
                  blockchain={blockchain}
                  icon={token?.icon}
                />
              </IconLeftRightWrapperStyled>
              <Checkbox
                value={item.selected}
                isLoading={item.isProcessing}
                onChange={() => setSelected(item, !item.selected)}
              />
              <TokenItem
                name={token.from.entity?.name}
                address={token.from.hash}
                aid={token.from.addressId}
                score={token.from.score}
                clusterId={token.from.clusterId}
                blockchain={blockchain}
                highlighted={isActive?.(token.from)}
                withPaddings={false}
                isFullWidth
              />
            </S.AddressRow>
          )
        },
      },
      {
        name: 'amount',
        justify: 'end',
        width: 1,
        render: ({ token }: TokenBtcTableItem) => {
          return <TokenAmountBlock amount={String(token.amount)} />
        },
      } as const,
      {
        name: '-->',
        width: '48px',
        render: (_) => (
          <Icon
            variant={'ArrowRight'}
            color={'onBackgroundVariant1'}
            size={'sm'}
          />
        ),
      },
      {
        name: 'address',
        width: 3,
        render: (item: TokenBtcTableItem) => {
          const { token } = item
          return (
            <S.AddressRow gap={0.75} fullWidth>
              <Checkbox
                value={item.selected}
                isLoading={item.isProcessing}
                onChange={() => setSelected(item, !item.selected)}
              />
              <TokenItem
                name={token.to.entity?.name}
                address={token.to.hash}
                aid={token.to.addressId}
                score={token.to.score}
                clusterId={token.to.clusterId}
                blockchain={blockchain}
                highlighted={isActive?.(token.to)}
                withPaddings={false}
                isFullWidth
              />
            </S.AddressRow>
          )
        },
      },
      {
        name: 'amount',
        justify: 'end',
        width: 1,
        render: ({ token }: TokenBtcTableItem) => {
          return (
            <S.AddressRow gap={0.75}>
              <TokenAmountBlock amount={String(token.amount)} />
              <IconLeftRightWrapperStyled>
                <TokenIconBlock
                  valid={token.valid}
                  reason={token.reason}
                  type={token.type}
                  blockchain={blockchain}
                  icon={token?.icon}
                />
              </IconLeftRightWrapperStyled>
            </S.AddressRow>
          )
        },
      } satisfies Omit<TableColumn<TokenBtcTableItem & { key: string }>, 'key'>,
    ].filter(Boolean),
    data?.map((item, index) => ({ ...item, key: index })),
    1
  )

  return (
    <DataGrid
      type="simple"
      columns={stubbedColumns}
      data={stubbedData}
      spaces={['none', 'lg']}
      size={'md'}
    />
  )
}

export default TokenUtxoTable
