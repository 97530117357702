import React from 'react'
import classNames from 'classnames/bind'

import { RowDeprecated } from '@clain/core/ui-kit'

import { TransactionAddressUtxo } from '../../types/converted/TransactionUtxo'
import {
  ClusterAddress,
  CLUSTER_ADDRESS_TYPE,
  ADDRESS_TYPE,
} from '../../types/converted/ClusterAddress'
import { getFeatureTagColor } from '../../../../utils/getFeatureTagColor'

import styles from './index.scss'
import { FeatureTag } from '@clainio/web-platform'

const cx = classNames.bind(styles)

interface TransactionAddressFeatureTagsProps {
  transactionAddress?: TransactionAddressUtxo
}

export const TransactionAddressFeatureTags: React.FC<
  TransactionAddressFeatureTagsProps
> = ({ transactionAddress }) => {
  return (
    <RowDeprecated gap={0.25}>
      {transactionAddress?.type !== 'ND' && (
        <FeatureTag
          size={'sm'}
          variant={getFeatureTagColor(transactionAddress?.type)}
        >
          {transactionAddress?.type}
        </FeatureTag>
      )}
      {(transactionAddress?.rbf || transactionAddress?.next?.rbf) && (
        <FeatureTag size={'sm'} variant={getFeatureTagColor('RBF')}>
          RBF
        </FeatureTag>
      )}
      {(transactionAddress?.segwit || transactionAddress?.next?.segwit) && (
        <FeatureTag size={'sm'} variant={getFeatureTagColor('SEGWIT')}>
          SEGWIT
        </FeatureTag>
      )}
      {!transactionAddress?.compressed &&
        !transactionAddress?.next?.compressed && (
          <FeatureTag size={'sm'} variant={getFeatureTagColor('UCP')}>
            UCP
          </FeatureTag>
        )}
    </RowDeprecated>
  )
}

interface AddressFeatureTagsProps {
  address?: Pick<ClusterAddress, 'type' | 'segwit'>
}

export const AddressFeatureTags: React.FC<AddressFeatureTagsProps> = ({
  address,
}) => {
  return (
    <RowDeprecated gap={0.25}>
      {address?.type !== ADDRESS_TYPE.ND && (
        <FeatureTag
          size={'sm'}
          variant={getFeatureTagColor(CLUSTER_ADDRESS_TYPE[address?.type])}
        >
          {CLUSTER_ADDRESS_TYPE[address?.type]}
        </FeatureTag>
      )}
      {address?.segwit && (
        <FeatureTag size={'sm'} variant={getFeatureTagColor('SEGWIT')}>
          SEGWIT
        </FeatureTag>
      )}
    </RowDeprecated>
  )
}

interface TransactionFeatureTagsProps {
  transaction?: {
    version?: 1 | 2
    lockTime?: number
    rbf?: boolean
    segwit?: boolean
    token?: unknown
  }
}

export const TransactionFeatureTags: React.FC<TransactionFeatureTagsProps> = ({
  transaction,
}) => {
  return (
    <RowDeprecated gap={0.25}>
      {transaction?.token && (
        <div className={cx('FeautureTag', 'omni')}>omni</div>
      )}
      {transaction?.version && (
        <FeatureTag
          size={'sm'}
          variant={getFeatureTagColor(`V${transaction?.version}`)}
        >
          {`V${transaction?.version}`}
        </FeatureTag>
      )}
      {transaction?.lockTime > 0 && (
        <FeatureTag size={'sm'} variant={getFeatureTagColor('LT')}>
          LT
        </FeatureTag>
      )}
      {transaction.rbf && (
        <FeatureTag size={'sm'} variant={getFeatureTagColor('RBF')}>
          RBF
        </FeatureTag>
      )}
      {transaction?.segwit && (
        <FeatureTag size={'sm'} variant={getFeatureTagColor('SEGWIT')}>
          SEGWIT
        </FeatureTag>
      )}
    </RowDeprecated>
  )
}
