import React, { FC, memo } from 'react'
import { usePresetsContext } from './PresetsContext'
import {
  IconButton,
  Separator,
  Stack,
  Stub,
  Tooltip,
  TypographyNew,
} from '@clainio/web-platform'
import { type PresetsHeaderProps } from './Presets.types'
import { NO_FILTERS_TOOLTIP_MESSAGE } from '@platform/components/BlockchainFilter/ui/PresetsList/Presets.constants'

export const PresetsHeader: FC<PresetsHeaderProps> = memo(
  ({
    title = 'Presets',
    isLoading = false,
    onCloseModal,
    isDisabledCreatePreset = false,
  }) => {
    const { isCreatePresetOpen, toggleCreatePreset } = usePresetsContext()

    return (
      <Stub isActive={isLoading}>
        <Stack
          gap={'xs'}
          align={'center'}
          justify={'space-between'}
          padding={['md', 'none']}
        >
          <TypographyNew variant="heading300Accent" color="onBackgroundBase">
            {title}
          </TypographyNew>
          <Stack align={'center'} fullWidth={false} gap={'md'}>
            <Tooltip
              content={NO_FILTERS_TOOLTIP_MESSAGE}
              disabled={!isDisabledCreatePreset}
            >
              <div>
                <IconButton
                  color={'secondary'}
                  iconVariant={'Plus'}
                  size={'md'}
                  disabled={
                    isCreatePresetOpen || isLoading || isDisabledCreatePreset
                  }
                  onClick={toggleCreatePreset}
                />
              </div>
            </Tooltip>
            <Separator variant={'vertical'} />
            <IconButton
              color={'secondary'}
              iconVariant={'Close'}
              size={'md'}
              onClick={onCloseModal}
            />
          </Stack>
        </Stack>
      </Stub>
    )
  }
)
PresetsHeader.displayName = 'PresetsHeader'
