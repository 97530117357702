import {
  EntitiesCount,
  PlotOnGraphPanel,
  SelectedEntitiesCount,
} from '../../containers'
import { ListToPanelLayout } from '../../ui'

export const AddressesListToPanel: React.FC = () => {
  return (
    <ListToPanelLayout
      title="Addresses"
      leftComponent={
        <>
          <EntitiesCount />
          <SelectedEntitiesCount entityName="Addresses" />
        </>
      }
      rightComponent={<PlotOnGraphPanel />}
    />
  )
}
