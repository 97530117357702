import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import useVm from '@clain/core/useVm'
import { TextField } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { Select } from '@clain/core/ui-kit'
import { Modal } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'

import { ReactComponent as MoreIcon } from '@clain/core/assets/more_vertical.svg'
import { ReactComponent as EditPenIcon } from '@clain/core/assets/edit_pen.svg'
import { ReactComponent as TrashIcon } from '@clain/core/assets/trash.svg'
import { ReactComponent as RemoveIcon } from '@clain/core/assets/remove.svg'
import probePreviewStub from '@clain/core/assets/probe_preview.png'

import { GraphsViewModel } from './GraphsViewModel'
import { useCtx } from '../../../../ctx'

import styles from './index.scss'
import { useSettings } from '../../../../hooks'
import { PopoverBaseContainer } from '@clainio/web-platform'

const cx = classnames.bind(styles)

const sortByOptions = [
  {
    value: 'last_updated',
    label: 'Last updated',
  },
  {
    value: 'last_created',
    label: 'Last created',
  },
]

const Graphs: React.FC = () => {
  const graphsVm = useVm(GraphsViewModel, useCtx())
  const settings = useSettings()

  if (graphsVm.showStub) {
    return (
      <div className={cx('GraphsStub')}>
        <Typography variant="heading1" color="grey3">
          Start investigation with the{' '}
          <Typography
            variant="heading1"
            color="ocean3"
            className={cx('CreateGraph')}
            onClick={graphsVm.createProbe}
          >
            first graph
          </Typography>
        </Typography>
        <img src={probePreviewStub} className={cx('Stub')} />
      </div>
    )
  }

  return (
    <div className={cx('Graphs')}>
      <RowDeprecated className={cx('TopRow')} align="between">
        <TextField
          variant="outline"
          value={graphsVm.searchValue}
          onChange={graphsVm.setSearchValue}
          startAddon={{ iconVariant: 'Search' }}
          placeholder="Search by name or description"
          className={cx('SearchInput')}
        />
        <Select
          value={graphsVm.sortBy}
          onChange={graphsVm.setSortBy}
          options={sortByOptions}
          className={cx('SortBySelect')}
        />
      </RowDeprecated>
      {graphsVm.probes?.length > 0 && (
        <div className={cx('PreviewsContainer')}>
          {graphsVm.probes.map(({ id, name, preview }) => {
            const openDeleteGraphModal = () => {
              graphsVm.openDeleteGraphModal(id)
            }

            const openEditNameModal = () => {
              graphsVm.openEditNameModal(id)
            }

            return (
              <div className={cx('Preview')} key={id}>
                <Link
                  className={cx('PreviewLink')}
                  to="/cases/:caseId/probes/:id"
                  params={{ id, caseId: graphsVm.caseId }}
                  target={settings.userSettings.graph.openInNewTab}
                />
                <div
                  className={cx('PreviewImage')}
                  style={{
                    backgroundImage: `url(${preview || probePreviewStub})`,
                  }}
                />
                <RowDeprecated className={cx('Info')} align="between">
                  <div className={cx('TextInfo')}>
                    <Typography
                      className={cx('PreviewName')}
                      variant="body1"
                      color="black"
                    >
                      {id} - {name}
                    </Typography>
                    {/* <Typography variant='body1-sm' color='grey2'>
                      Updated by Nicolas Cage
                      <br />
                      June 2021
                    </Typography> */}
                  </div>
                  <PopoverBaseContainer
                    placement="top-start"
                    content={
                      <div className={cx('MorePanel')}>
                        <div
                          className={cx('MorePanelOption')}
                          onClick={openEditNameModal}
                        >
                          <EditPenIcon className={cx('MorePanelIcon')} />
                          <Typography variant="body1" color="black">
                            Rename
                          </Typography>
                        </div>
                        <div
                          className={cx('MorePanelOption')}
                          onClick={openDeleteGraphModal}
                        >
                          <TrashIcon className={cx('MorePanelIcon')} />
                          <Typography variant="body1" color="black">
                            Delete
                          </Typography>
                        </div>
                      </div>
                    }
                    anchor={<MoreIcon className={cx('MoreIcon')} />}
                  />
                </RowDeprecated>
              </div>
            )
          })}
        </div>
      )}
      {graphsVm.editNameValue ? null : null}
      <Modal
        target={null}
        visible={graphsVm.isDeleteGraphModalOpen}
        onRequestClose={graphsVm.closeDeleteGraphModal}
        preventScroll={true}
      >
        {() => (
          <div className={cx('ModalWrapper')}>
            <div className={cx('ModalHead')}>
              <Typography variant="heading5" color="black">
                Delete graph
              </Typography>
              <RemoveIcon
                className={cx('RemoveIcon')}
                onClick={graphsVm.closeDeleteGraphModal}
              />
            </div>
            <ColDeprecated gap={2}>
              <Typography variant="body1">
                Graph
                <Typography variant="body3">
                  {' '}
                  {graphsVm.activeProbe?.name}{' '}
                </Typography>
                will be deleted. It will not be possible to recover it later.
              </Typography>
              <RowDeprecated align="between">
                <Button
                  variant="solid"
                  color="critical"
                  endIcon={'Delete'}
                  onClick={graphsVm.deleteProbe}
                  size="lg"
                >
                  delete
                </Button>
                <Button
                  variant="outline"
                  color="secondary"
                  onClick={graphsVm.closeDeleteGraphModal}
                  size="lg"
                >
                  cancel
                </Button>
              </RowDeprecated>
            </ColDeprecated>
          </div>
        )}
      </Modal>
      <Modal
        target={null}
        visible={graphsVm.isEditNameModalOpen}
        onRequestClose={graphsVm.closeEditNameModal}
        preventScroll={true}
      >
        {() => (
          <div className={cx('ModalWrapper')}>
            <div className={cx('ModalHead')}>
              <Typography variant="heading5" color="black">
                Edit graph name
              </Typography>
              <RemoveIcon
                className={cx('RemoveIcon')}
                onClick={graphsVm.closeEditNameModal}
              />
            </div>
            <ColDeprecated gap={1.5}>
              <TextField
                variant="outline"
                value={graphsVm.editNameValue}
                defaultValue={graphsVm.editNameValue}
                onChange={graphsVm.setEditNameValue}
              />
              <RowDeprecated>
                <Button
                  variant="outline"
                  color="secondary"
                  onClick={graphsVm.closeEditNameModal}
                  size="lg"
                >
                  cancel
                </Button>
                <Button
                  variant="solid"
                  color="primary"
                  onClick={graphsVm.rename}
                  size="lg"
                >
                  apply
                </Button>
              </RowDeprecated>
            </ColDeprecated>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default observer(Graphs)
