import { action, makeObservable } from 'mobx'
import { defaultFilters } from '../../Address/Address.constants'
import { dataStatsViewModel } from './DataStatsViewModel'
import { commonPageData } from './ApiServicesStateFacade'
import { QueryParamsController } from '@clain/core/utils/queryParamsUtils'
import { StateViewModel } from '@clain/core/utils/mobxUtils'
import { AddressPageQueryParams } from '../../Address/Address.types'
import { CoinType } from '../../../types/coin'
import { QueryParamsViewModel } from '../baseClasses/QueryParamsViewModel'
import { getConvertToValue, getDefaultTimestamp } from '../utils'
import {
  DEFAULT_COUNTERPARTIES_FILTERS_CURRENCY,
  DEFAULT_FILTERS,
} from '../constants'

export const queryParamsController = new QueryParamsController(
  new StateViewModel<AddressPageQueryParams>()
)

class AddressQueryParamsViewModel extends QueryParamsViewModel<
  Partial<AddressPageQueryParams>
> {
  private statsViewModel = dataStatsViewModel
  private commonPageData = commonPageData
  constructor() {
    super(queryParamsController)
    makeObservable(this)
  }
  public queryParamsController = queryParamsController

  @action
  public initQueryParams = () => {
    const blockchain: CoinType = this.commonPageData.state.blockchain
    const defaultPeriodStart: Date = this.statsViewModel.period?.[0]
    const defaultPeriodEnd: Date = this.statsViewModel.period?.[1]

    const prevFilters: Partial<AddressPageQueryParams> =
      this.queryParamsController.getUrlFormattedQueryParams({
        include_tokens: 'parseAsString',
        timestamp_from: 'parseAsDate',
        timestamp_to: 'parseAsDate',
        cp: {
          timestamp_from: 'parseAsDate',
          timestamp_to: 'parseAsDate',
          include_tokens: 'parseAsString',
        },
        counterparties: {
          includeTokens: ['parseAsString'],
          page: 'parseAsInt',
          sortBy: 'parseAsString',
          sortOrder: 'parseAsString',
          counterpartyName: 'parseAsString',
        },
        trns: {
          includeTokens: ['parseAsString'],
          page: 'parseAsInt',
          sortBy: 'parseAsString',
          sortOrder: 'parseAsString',
        },
      })

    const mergedFilters = {
      ...defaultFilters,
      ...prevFilters,
    }

    const dynamicInitialFilters = {
      ...DEFAULT_COUNTERPARTIES_FILTERS_CURRENCY[blockchain],
      includeTokens: DEFAULT_COUNTERPARTIES_FILTERS_CURRENCY[
        blockchain
      ].includeTokens?.map((token) => `${token.id}`),
    }

    this.queryParamsController.initDefaultQueryParamsState({
      ...defaultFilters,
      timestamp_from: defaultPeriodStart,
      timestamp_to: defaultPeriodEnd,

      convert_to: getConvertToValue(defaultFilters.convert_to),
      cp: {
        ...defaultFilters.cp,
        timestamp_from: defaultPeriodStart,
        timestamp_to: defaultPeriodEnd,
        convert_to: getConvertToValue(defaultFilters.cp.convert_to),
      },
      trns: {
        ...defaultFilters.trns,
        convert_to: getConvertToValue(defaultFilters.trns.convert_to),
      },
      counterparties: {
        ...defaultFilters.counterparties,
        convert_to: getConvertToValue(defaultFilters.counterparties.convert_to),
      },
    })

    this.queryParamsController.initQueryParamsState({
      ...mergedFilters,
      timestamp_from: getDefaultTimestamp(
        mergedFilters.timestamp_from,
        defaultPeriodStart
      ),
      timestamp_to: getDefaultTimestamp(
        mergedFilters.timestamp_to,
        defaultPeriodEnd
      ),
      convert_to: getConvertToValue(mergedFilters.convert_to),
      cp: {
        ...mergedFilters.cp,
        timestamp_from: getDefaultTimestamp(
          mergedFilters.cp.timestamp_from,
          defaultPeriodStart
        ),
        timestamp_to: getDefaultTimestamp(
          mergedFilters.cp.timestamp_to,
          defaultPeriodEnd
        ),
        convert_to: getConvertToValue(mergedFilters.cp.convert_to),
      },
      trns: {
        ...dynamicInitialFilters,
        ...mergedFilters.trns,
        convert_to: getConvertToValue(mergedFilters.trns.convert_to),
      },
      counterparties: {
        ...dynamicInitialFilters,
        ...mergedFilters.counterparties,
        convert_to: getConvertToValue(mergedFilters.counterparties.convert_to),
      },
    })

    this.queryParamsController.initDefaultSchemaQueryParamsState({
      trns: DEFAULT_FILTERS,
      counterparties: DEFAULT_FILTERS,
    })
  }
}

export const queryParamsViewModel = new AddressQueryParamsViewModel()
