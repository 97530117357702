import {
  action,
  makeObservable,
  observable,
  autorun,
  IReactionDisposer,
} from 'mobx'
import { injectable } from 'inversify'
import {
  IAutoSelectTransaction,
  AutoSelectTransactionFlowData,
} from '../models'
import { probeState } from './ProbeState'
import { getBoundedDomainBlock } from '../utils/getDomainBlock'
import { probeGraph } from './ProbeGraph'
import { probeEvents } from './ProbeEvents'

@injectable()
export class AutoSelectTransaction implements IAutoSelectTransaction {
  private reactionDisposers: Array<IReactionDisposer> = []
  @observable private flowId?: AutoSelectTransactionFlowData['flowId'] | null
  @observable private transactionHash?: string | null
  private autoSelectHandle: Parameters<
    IAutoSelectTransaction['injectAutoSelectHandle']
  >[0]

  constructor() {
    makeObservable(this)
    this.clear()
    this.initReaction()
  }

  public injectAutoSelectHandle: IAutoSelectTransaction['injectAutoSelectHandle'] =
    (autoSelectHandle) => {
      this.autoSelectHandle = autoSelectHandle
    }

  private initReaction = () => {
    this.reactionDisposers.push(
      autorun(() => {
        const flowProcessing = probeEvents.meta.nodesInProcessingFlow

        if (this.flowId && !flowProcessing[this.flowId]) {
          this.setSelectedTransaction(this.transactionHash)
          this.clearFlowMeta()
        }
      })
    )
  }

  @action
  public setFlowData: IAutoSelectTransaction['setFlowData'] = (data) => {
    this.flowId = data.flowId
    this.transactionHash = data.transactionHash
  }

  private setSelectedTransaction = (hash: string) => {
    const transactionBlock = getBoundedDomainBlock(probeGraph, hash)

    if (transactionBlock) {
      probeState.setSelectedNodeIds(new Set([transactionBlock.nodeKey]))
      probeState.setSelectedEdgeIds(new Set(transactionBlock.edgeKeys))

      const { data } = probeState.selectedNode
      if (data.nodeType !== 'utxo_transaction') return
      this.autoSelectHandle(data)
    }
  }

  private clearFlowMeta = () => {
    this.flowId = undefined
    this.transactionHash = undefined
  }

  public clear = () => {
    this.clearFlowMeta()
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
  }
}
