import React, { useState } from 'react'
import {
  Button,
  ColDeprecated,
  Divider,
  TextField,
  ListItem,
  ListItemContainer,
  ListItemContent,
  RadioButton,
  Stack,
  TypographyNew,
  usePopoverState,
} from '@clain/core/ui-kit'
import {
  ComparatorsValueFilterOptions,
  CONTAINER_ITEM_SIZE,
  TEXT_FIELD_SIZE,
} from './MultisigFilter.constants'
import {
  MultisigFilterMenuProps,
  MultisigFilterValues,
} from './MultisigFilter.types'
import * as S from './MultisigFilter.styles'
import { FiltersBarProps } from '../../FiltersBar'
import { validateValue } from './MultisigFilter.utils'
import { useParsedNumber } from '@clain/core/hooks'
import { EllipsisTypography, ListHeader } from '@clainio/web-platform'

export const MultisigFilterMenu = ({
  values,
  title,
  selectedCondition,
  onChange,
  size,
  label,
  isDecimalsOnly,
}: MultisigFilterMenuProps & Partial<Pick<FiltersBarProps, 'size'>>) => {
  const options = ComparatorsValueFilterOptions
  const [conditionValue, setConditionValue] = useState(selectedCondition)
  const [error, setError] = useState('')
  const [innerValues, setInnerValues] = useState(
    Array.isArray(values) ? values : ([null, null] as MultisigFilterValues)
  )
  const [fromValue, toValue] = innerValues

  const { setOpen } = usePopoverState()

  const handleSetFromValue = useParsedNumber((value: string) => {
    if (conditionValue === 'is') {
      setInnerValues([value, toValue])
    } else {
      setInnerValues([value])
    }
  }, isDecimalsOnly)

  const handleSetToValue = useParsedNumber((value: string) => {
    setInnerValues([fromValue, value])
  }, isDecimalsOnly)

  const handleSubmit = () => {
    if (conditionValue === 'is') {
      const isError = validateValue(innerValues)
      if (isError) {
        setError(isError)
        return
      } else {
        setError('')
      }
    }

    setOpen(false)
    onChange({
      data: conditionValue === 'is' ? innerValues : [],
      condition: conditionValue,
    })
  }

  return (
    <div>
      <ListHeader>
        <ListItemContainer size={CONTAINER_ITEM_SIZE}>
          <ListItemContent
            contentScale={size}
            density="sm"
            slots={[
              {
                type: 'text',
                component: ({ variant: [body] }) => (
                  <EllipsisTypography variant={body} color={'onBackgroundBase'}>
                    {title}
                  </EllipsisTypography>
                ),
              },
            ]}
          />
        </ListItemContainer>
      </ListHeader>
      <Divider type="empty" spaces={['xxs', 'none']} />
      <ul>
        {options?.map(({ value, label }) => (
          <ListItem
            key={value}
            onClick={() => setConditionValue(value)}
            size={[CONTAINER_ITEM_SIZE, size]}
            content={({ ...params }) => (
              <ListItemContent
                {...params}
                slots={[
                  {
                    type: 'checkRadio',
                    component: ({ size }) => (
                      <RadioButton
                        size={size}
                        checked={conditionValue === value}
                        onChange={() => setConditionValue(value)}
                      />
                    ),
                  },
                  {
                    type: 'text',
                    component: ({ variant: [body] }) => (
                      <EllipsisTypography
                        variant={body}
                        color={'onBackgroundBase'}
                      >
                        {label}
                      </EllipsisTypography>
                    ),
                  },
                ]}
              />
            )}
          />
        ))}
      </ul>
      <Divider
        type="line"
        color="base"
        size="1"
        spaces={conditionValue === 'is' ? ['xs', 'none'] : ['xs', 'none', 'md']}
      />
      <S.FooterContainer>
        <Stack direction="column" gap="md">
          {error && (
            <S.StyledErrorContainer>
              <TypographyNew variant="body100Normal" color="criticalBase">
                {error}
              </TypographyNew>
            </S.StyledErrorContainer>
          )}
          {conditionValue === 'is' && (
            <Stack gap="md" align="center" padding={['none', 'lg']}>
              <>
                <S.StyledCol $isLabel={!!label}>
                  <TextField
                    error={!!error}
                    variant="underline"
                    value={fromValue}
                    minWidth={false}
                    size={TEXT_FIELD_SIZE[size]}
                    onChange={handleSetFromValue}
                  />
                </S.StyledCol>
                <S.DividerCol>
                  <Stack align="center" padding={['sm', 'none', 'none']}>
                    <TypographyNew
                      variant="body100Normal"
                      color="onBackgroundBase"
                    >
                      /
                    </TypographyNew>
                  </Stack>
                </S.DividerCol>
                <S.StyledCol $isLabel={!!label}>
                  <TextField
                    error={!!error}
                    variant="underline"
                    value={toValue}
                    minWidth={false}
                    size={TEXT_FIELD_SIZE[size]}
                    onChange={handleSetToValue}
                  />
                </S.StyledCol>
              </>
              {label && (
                <S.StyledLabel>
                  <TypographyNew
                    variant="body200Normal"
                    color="onBackgroundBase"
                  >
                    {label}
                  </TypographyNew>
                </S.StyledLabel>
              )}
            </Stack>
          )}
          <ListItemContainer size={CONTAINER_ITEM_SIZE}>
            <ColDeprecated align={'center'} style={{ width: '100%' }}>
              <Button
                variant="outline"
                color="secondary"
                fullWidth
                onClick={handleSubmit}
                size={size}
              >
                APPLY
              </Button>
            </ColDeprecated>
          </ListItemContainer>
        </Stack>
      </S.FooterContainer>
    </div>
  )
}
