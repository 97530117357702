import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { Modal, ModalSlot } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import Form, { Field, FORM_ERROR, validators } from '@clain/core/Form'
import { TextField } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'

import { ReactComponent as CaseIcon } from '@clain/core/assets/cases_new.svg'
import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'

import TagsInput from './TagsInput'
import { NewCase } from '../../types'

import styles from './index.scss'
import { CaseImportanceCategories } from './CaseImportanceCategories'
import { TypographyNew } from '@clainio/web-platform'

const cx = classnames.bind(styles)

const initialValues: NewCase = {
  title: '',
  internalId: '',
  tags: [],
}

interface NewCaseModalFormProps {
  onSubmit: (data: NewCase) => Promise<void>
}

const NewCaseModalForm: React.FC<NewCaseModalFormProps> = ({ onSubmit }) => {
  const target: ModalSlot = ({ show }) => (
    <Button
      size="lg"
      variant="solid"
      color="primary"
      endCustomIcon={CaseIcon}
      onClick={show}
    >
      new case
    </Button>
  )

  const body: ModalSlot = ({ hide }) => {
    const onFormSubmit = async (data: NewCase) => {
      try {
        await onSubmit(data)
        hide()
      } catch (data) {
        return {
          [FORM_ERROR]: data.error.title
            ? 'Should be at least 3 character(s)'
            : JSON.stringify(data.error),
        }
      }
    }

    return (
      <React.Fragment>
        <RowDeprecated align="between">
          <Typography variant="heading5" color="black">
            New case
          </Typography>
          <CloseIcon className={cx('CloseIcon')} onClick={hide} />
        </RowDeprecated>
        <Form
          className={cx('NewCaseForm')}
          initialValues={initialValues}
          onSubmit={onFormSubmit}
        >
          {({ form }) => {
            const { valid, submitError, submitting, dirtySinceLastSubmit } =
              form.getState()
            return (
              <ColDeprecated gap={1.5}>
                <Label content="Case label (required)">
                  <Field
                    fullWidth
                    name="title"
                    variant="outline"
                    as={TextField}
                    placeholder="Type case label"
                    autoFocus={true}
                    validate={validators.required()}
                  />
                </Label>
                <Label content="Severity">
                  <Field name="severityId" as={CaseImportanceCategories} />
                </Label>
                <Label content="Tags (optional)">
                  <Field
                    fullWidth
                    name="tags"
                    variant="outline"
                    as={TagsInput}
                    placeholder="Insert up to 5 words. Separate with Space"
                  />
                </Label>
                <Label content="Internal case # (optional)">
                  <Field
                    fullWidth
                    name="internalId"
                    variant="outline"
                    as={TextField}
                    placeholder="Insert an ID of the internal case"
                  />
                </Label>
                <RowDeprecated className={cx('FormButtons')}>
                  <Button
                    size="lg"
                    variant="outline"
                    color="secondary"
                    onClick={hide}
                  >
                    cancel
                  </Button>
                  <Button
                    isLoading={submitting}
                    disabled={!valid && !dirtySinceLastSubmit}
                    size="lg"
                    variant="solid"
                    color="primary"
                    type="submit"
                  >
                    start case
                  </Button>
                </RowDeprecated>
                {submitError && (
                  <TypographyNew variant={'body100Normal'} color="criticalBase">
                    {submitError}
                  </TypographyNew>
                )}
              </ColDeprecated>
            )
          }}
        </Form>
      </React.Fragment>
    )
  }

  return (
    <Modal target={target} className={cx('NewCaseModalForm')}>
      {body}
    </Modal>
  )
}

export default NewCaseModalForm
