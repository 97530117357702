import React from 'react'
import classNames from 'classnames/bind'

import { ReactComponent as ExchangeIcon } from '@clain/core/assets/exchange.svg'

import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import styles from './AlertInfobarList.scss'
import {
  AlertInfobarItemListProps,
  GetOptionsParams,
} from './AlertInfobarList.types'
import { CheckboxSwitch } from '@clain/core/ui-kit'
import {
  AlertInfobarOptions,
  AlertInfobarOptionsItemProps,
} from '../AlertInfobarOptions'
import InfobarTable from '../../InfobarTable'
import { AlertInfobarConfirm } from './AlertInfobarConfirm'
import { OPTIONS_ALERT_ID } from './AlertInfobarList.constants'
import { createTableOptions } from './AlertInfobarList.utils'

const cx = classNames.bind(styles)

const getOptions = ({
  tag,
  onRemove,
  disabledSeeEvents,
}: GetOptionsParams): AlertInfobarOptionsItemProps[] => {
  const list: AlertInfobarOptionsItemProps[] = [
    {
      type: 'content',
      content: <AlertInfobarConfirm onClick={onRemove} tag={tag} />,
    },
  ]

  if (!disabledSeeEvents) {
    return [
      {
        value: OPTIONS_ALERT_ID.seeEvents,
        type: 'default',
        name: 'See events',
        icon: <ExchangeIcon className={cx('OptionIcon')} />,
      },
      ...list,
    ]
  }

  return list
}

export const AlertInfobarList: React.FC<AlertInfobarItemListProps> = ({
  list,
  onRemove,
  onToggle,
  onSeeEvents,
  disabledSeeEvents,
  isOpen,
}) => {
  return (
    <RowDeprecated
      align="between"
      gap={0}
      fullWidth
      direction="column"
      className={cx('AlertInfobarList', isOpen ? 'Opened' : '')}
    >
      {list?.map((item, index) => (
        <RowDeprecated
          key={item.id}
          gap={0}
          align="between"
          fullWidth
          className={cx('AlertInfobarItem')}
        >
          <ColDeprecated>
            <Typography variant="body1-sm" color="black">
              {item.tag}
            </Typography>
          </ColDeprecated>
          <ColDeprecated>
            <RowDeprecated gap={0.75}>
              <CheckboxSwitch
                size="sm"
                value={item.active}
                onChange={() => onToggle(index)}
              />
              <AlertInfobarOptions
                topContent={
                  <InfobarTable
                    className={cx('AlertInfobarOptions')}
                    classNameItem={cx('AlertInfobarOptionsItem')}
                    data={createTableOptions(item)}
                  />
                }
                onChange={(value) => {
                  if (value === OPTIONS_ALERT_ID.seeEvents) {
                    onSeeEvents(item.id)
                  }
                }}
                options={getOptions({
                  disabledSeeEvents,
                  tag: item.tag,
                  onRemove: () => onRemove(index),
                })}
              />
            </RowDeprecated>
          </ColDeprecated>
        </RowDeprecated>
      ))}
    </RowDeprecated>
  )
}
