import React from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'

import { capitalizeFirstLetter, Container, Icon } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { CopyIcon } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../../hooks'
import ProbeVM from '../../../vm/ProbeViewModel'

import { ReactComponent as GraphBarIcon } from '@clain/core/assets/graph_bar.svg'

import { Address } from '../../../types/converted/AddressBtc'
import { TransactionAddressUtxo } from '../../../types/converted/TransactionUtxo'

import InfobarTable from '../InfobarTable'
import { TransactionAddressFeatureTags } from '../../FeautureTags'

import styles from './index.scss'
import TokensBalance from '../TokensBalance'
import { AlertInfobar } from '../AlertInfobar'
import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'
import { CoinTypeUTXO } from '../../../../../types/coin'
import { FORMATS_WITHOUT_DECIMALS } from '@clain/core/utils/format'
import { ActiveEntityType } from '../../../vm/active-entity/ActiveEntityViewModel.types'
import { observer } from 'mobx-react-lite'

const cx = classnames.bind(styles)

interface AddressBtcInfobarProps {
  address: Address
  transactionAddress?: TransactionAddressUtxo
  isUsd: boolean
  currency: CoinTypeUTXO
}

const AddressUtxoInfobar: React.FC<AddressBtcInfobarProps> = observer(
  ({ address, transactionAddress, isUsd, currency = 'btc' }) => {
    const formatDate = useFormatDate()
    const formatMoney = useFormatMoneySettings({
      type: 'probe-tabels',
      formats: FORMATS_WITHOUT_DECIMALS,
    })

    if (!address) return null

    const handleAnalyticsClick = () => {
      const { activeEntity } = ProbeVM
      const activeEntityType = activeEntity.type as Extract<
        ActiveEntityType,
        'cluster' | 'address'
      >

      const newAnalyticsLayerOpenState = !ProbeVM.isAnalyticsLayerActive
      ProbeVM.setIsAnalyticsLayerActive(newAnalyticsLayerOpenState)

      if (newAnalyticsLayerOpenState) {
        ProbeVM.probeState.setBottombarStatus('hidden')
        activeEntity.loadAnalytics({
          entityId: address.addressId,
          entityType: activeEntityType,
        })
      } else {
        ProbeVM.probeState.setBottombarStatus('default')
      }
    }

    const noAddress = !address.address || address.address.startsWith('7')

    return (
      <Container className={cx('AddressBtcInfobar')} gap={[1.5, 1]}>
        <ColDeprecated gap={2}>
          <ColDeprecated>
            <ColDeprecated gap={0.5}>
              <RowDeprecated align="between">
                <Typography variant="subheading2">Address</Typography>
                <Score value={address.score} useNewColors={true} size="sm" />
              </RowDeprecated>
              <RowDeprecated gap={0.5}>
                <Icon variant={capitalizeFirstLetter(currency)} size="sm" />
                {noAddress ? (
                  <Typography variant="code1" color="grey4">
                    unable to decode address
                  </Typography>
                ) : (
                  <>
                    <Typography className={cx('Address')} variant="code1">
                      <Link
                        className={cx('AddressLink')}
                        to="/:coin/address/:hash"
                        params={{ hash: address.address, coin: currency }}
                        target="_blank"
                      >
                        {address.address}
                      </Link>
                    </Typography>
                    <CopyIcon value={address.address} />
                  </>
                )}
              </RowDeprecated>
            </ColDeprecated>
            {ProbeVM.activeEntity.type !== 'transactionAddress' && (
              <Button
                disabled={ProbeVM.activeEntity.address.tokensLoading}
                isLoading={ProbeVM.activeEntity.address.tokensLoading}
                className={cx('AnalyticsButton')}
                variant="outline"
                color="secondary"
                size="sm"
                onClick={handleAnalyticsClick}
              >
                <RowDeprecated gap={0.375}>
                  analytics
                  <GraphBarIcon className={cx('GraphBarIcon')} />
                </RowDeprecated>
              </Button>
            )}
          </ColDeprecated>
          {!transactionAddress && <AlertInfobar />}
          <div>
            <InfobarTable
              closingDelimiter={
                address.tokensBalance?.filter(({ id }) => id > 2).length > 0
              }
              data={[
                transactionAddress && [
                  {
                    name: 'Features:',
                    value: (
                      <TransactionAddressFeatureTags
                        transactionAddress={transactionAddress}
                      />
                    ),
                  },
                ],
                [
                  {
                    name: 'Parent cluster:',
                    value: (
                      <Link
                        to="/:coin/cluster/:clusterId"
                        params={{
                          clusterId: address.clusterId,
                          coin: currency,
                        }}
                        target="_blank"
                      >
                        {address.entity?.name || address.clusterId}
                      </Link>
                    ),
                  },
                  {
                    name: 'Category:',
                    value: address.entity?.category || 'Unidentified',
                  },
                ],
                [
                  {
                    name: 'Received:',
                    value: formatMoney({
                      value: isUsd
                        ? address.balanceUsd.totalIn
                        : address.balance.totalIn,
                      currency: isUsd ? 'usd' : currency,
                    }),
                  },
                  {
                    name: 'Sent:',
                    value: formatMoney({
                      value: isUsd
                        ? address.balanceUsd.totalOut
                        : address.balance.totalOut,
                      currency: isUsd ? 'usd' : currency,
                    }),
                  },
                  {
                    name: 'Balance:',
                    value: formatMoney({
                      value: isUsd
                        ? address.balanceUsd.balance
                        : address.balance.balance,
                      currency: isUsd ? 'usd' : currency,
                    }),
                  },
                  {
                    name: 'Transactions:',
                    value: `${address.trxCount.in + address.trxCount.out} (In ${
                      address.trxCount.in
                    }/ Out ${address.trxCount.out})`,
                  },
                ],
                [
                  {
                    name: 'First seen:',
                    value: formatDate(
                      fromUnixTime(address.firstSeen),
                      'date-time'
                    ),
                  },
                  {
                    name: 'Last seen:',
                    value: formatDate(
                      fromUnixTime(address.lastSeen),
                      'date-time'
                    ),
                  },
                ],
              ].filter(Boolean)}
            />
            <TokensBalance tokens={address.tokensBalance} />
          </div>
        </ColDeprecated>
      </Container>
    )
  }
)

export default AddressUtxoInfobar
