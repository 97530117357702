import React from 'react'
import classnames from 'classnames/bind'

import { Tooltip } from '@clain/core/ui-kit'

import styles from '../index.scss'
import { RowDeprecated } from '@clain/core/ui-kit'
import { IconButton } from '@clain/core/ui-kit'

const cx = classnames.bind(styles)

interface CrossChainSwapToolpanelProps {
  deleteNode: () => void
  isDeleteNodeDisabled?: boolean
}

export const CrossChainSwapToolpanel: React.FC<
  CrossChainSwapToolpanelProps
> = ({ deleteNode, isDeleteNodeDisabled = false }) => (
  <React.Fragment>
    <RowDeprecated className={cx('Container')} direction="column" gap={0.25}>
      <Tooltip
        placement="right"
        content={
          isDeleteNodeDisabled
            ? 'Deleting disabled while adding a new object'
            : 'Remove node'
        }
      >
        <IconButton
          size="md"
          disabled={isDeleteNodeDisabled}
          iconVariant="Delete"
          onClick={isDeleteNodeDisabled ? null : deleteNode}
        />
      </Tooltip>
    </RowDeprecated>
  </React.Fragment>
)
