import { InjectComponentModel } from '@clain/core/utils'
import { formatNumber, TypographyNew } from '@clain/core/ui-kit'
import { useInjectBlockchainModel } from '../hooks'

export const EntitiesCount = InjectComponentModel<{ total: number | string }>(
  ({ total }) => {
    return (
      <TypographyNew variant={'heading300Accent'} color={'onBackgroundBase'}>
        {total}
      </TypographyNew>
    )
  },
  {
    useFactory: () => {
      const { tableData } = useInjectBlockchainModel()

      const total = tableData?.pagination?.totalEntries
        ? formatNumber(tableData?.pagination?.totalEntries, 0)
        : ''

      return { total }
    },
  }
)
