import React from 'react'
import {
  Counter,
  EllipsisTypography,
  Spinner,
  Stack,
  Tooltip,
  TypographyNew,
} from '@clainio/web-platform'
import { type CreatedPresetCardProps } from './PresetCard.types'
import * as S from './PresetCard.styles'

export const CreatedPresetCard = ({
  id,
  name,
  timestamp,
  isMonitored,
  selectCard,
  removeCard,
  isSelected,
  notificationCount = null,
  isLoading = false,
}: CreatedPresetCardProps) => {
  return (
    <S.CreatedCardContainer
      onClick={() => {
        if (!isSelected) {
          selectCard()
        }
      }}
      preventPseudoActive={true}
      interactive={true}
      borderRadius={'md'}
      spaces={['none', 'sm', 'md', 'lg']}
      variant={'base'}
      active={isSelected}
    >
      <Stack align={'center'} justify={'space-between'} gap={'xs'}>
        <Tooltip content={name} visibleDelay={800}>
          <S.CreatedCardTitleSection>
            <EllipsisTypography
              variant={'body100Normal'}
              color={'onBackgroundBase'}
            >
              {name}
            </EllipsisTypography>
          </S.CreatedCardTitleSection>
        </Tooltip>
        {isLoading ? (
          <Stack align={'center'} padding={['none', 'sm']} fullWidth={false}>
            <Spinner size={'xxxs'} color={'grey2'} />
          </Stack>
        ) : (
          <S.DeleteIconButton
            onClick={(e) => {
              e.stopPropagation()
              removeCard(id)
            }}
            size={'sm'}
            iconVariant={'Delete'}
            color={'secondary'}
          />
        )}
      </Stack>
      <S.CreatedCardMetaSection align={'center'} gap={'sm'}>
        <TypographyNew variant={'body200Normal'} color={'onBackgroundVariant1'}>
          {isMonitored ? 'Monitoring' : timestamp}
        </TypographyNew>
        {isMonitored ? (
          <Tooltip content={'Monitoring for new transactions'}>
            <Stack align={'center'} fullWidth={false} justify={'center'}>
              <Counter
                variant={'secondary'}
                type={'clear'}
                startIcon={'Notification'}
                isMaxValue
                size={'xs'}
                value={notificationCount}
              />
            </Stack>
          </Tooltip>
        ) : null}
      </S.CreatedCardMetaSection>
    </S.CreatedCardContainer>
  )
}
