import React from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'

import { capitalizeFirstLetter, Container, Icon } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { CopyIcon } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import CountryFlag from '../../../../CountryFlag'
import { Button } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../../hooks'

import { ReactComponent as GraphBarIcon } from '@clain/core/assets/graph_bar.svg'

import { Cluster } from '../../../types/converted/Cluster'
import TokensBalance from '../TokensBalance'
import InfobarTable from '../InfobarTable'
import ProbeVM from '../../../vm/ProbeViewModel'

import styles from './index.scss'
import { AlertInfobar } from '../AlertInfobar'
import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'
import { useFormatNumberSettings } from '../../../../../hooks/useFormatNumberSettings'
import { CoinType } from '../../../../../types/coin'
import { FORMATS_WITHOUT_DECIMALS } from '@clain/core/utils/format'
import { ActiveEntityType } from '../../../vm/active-entity/ActiveEntityViewModel.types'
import { observer } from 'mobx-react-lite'

const cx = classnames.bind(styles)

interface ClusterInfobarProps {
  cluster: Cluster
  currency: CoinType
  isUsd: boolean
}

const ClusterInfobar: React.FC<ClusterInfobarProps> = observer(
  ({ cluster, currency, isUsd }) => {
    const formatDate = useFormatDate()
    const formatMoney = useFormatMoneySettings({
      type: 'probe-tabels',
      formats: FORMATS_WITHOUT_DECIMALS,
    })
    const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })

    if (!cluster) return null

    const handleAnalyticsClick = () => {
      const { activeEntity } = ProbeVM
      const activeEntityType = activeEntity.type as Extract<
        ActiveEntityType,
        'cluster' | 'address'
      >

      const newAnalyticsLayerOpenState = !ProbeVM.isAnalyticsLayerActive
      ProbeVM.setIsAnalyticsLayerActive(newAnalyticsLayerOpenState)

      if (newAnalyticsLayerOpenState) {
        ProbeVM.probeState.setBottombarStatus('hidden')
        activeEntity.loadAnalytics({
          entityId: cluster.clusterId,
          entityType: activeEntityType,
        })
      } else {
        ProbeVM.probeState.setBottombarStatus('default')
      }
    }

    return (
      <Container className={cx('ClusterInfobar')} gap={[1.5, 1]}>
        <ColDeprecated gap={2}>
          <ColDeprecated>
            <ColDeprecated gap={0.5}>
              <RowDeprecated align="between">
                <RowDeprecated gap={0.5} className={cx('TopRow')}>
                  <Typography
                    variant="subheading2"
                    className={cx('TopRowHeading')}
                  >
                    <Link
                      to="/:coin/cluster/:clusterId"
                      params={{ clusterId: cluster.clusterId, coin: currency }}
                      className={cx('TopRowHeadingLink')}
                      target="_blank"
                    >
                      {cluster.entity?.name || cluster.clusterId}
                    </Link>
                  </Typography>
                  <CopyIcon value={cluster.entity?.name || cluster.clusterId} />
                </RowDeprecated>
                <Score value={cluster.score} useNewColors={true} size="sm" />
              </RowDeprecated>
              <RowDeprecated gap={0.5}>
                <Icon variant={capitalizeFirstLetter(currency)} size="sm" />
                <Typography
                  className={cx('CategoryTag')}
                  variant="caption1"
                  color="white"
                  transform="uppercase"
                >
                  {cluster.entity?.category || 'Unidentified'}
                </Typography>
                {cluster.entity?.country && (
                  <CountryFlag
                    className={cx('CountryFlag')}
                    country={cluster.entity?.country}
                  />
                )}
              </RowDeprecated>
            </ColDeprecated>
            <Button
              disabled={ProbeVM.activeEntity.cluster.tokensLoading}
              isLoading={ProbeVM.activeEntity.cluster.tokensLoading}
              className={cx('AnalyticsButton')}
              variant="outline"
              color="secondary"
              size="sm"
              onClick={handleAnalyticsClick}
            >
              <RowDeprecated gap={0.375}>
                analytics
                <GraphBarIcon className={cx('GraphBarIcon')} />
              </RowDeprecated>
            </Button>
          </ColDeprecated>
          <AlertInfobar />
          {/* UNCOMMENT AFTER ALERTS RELEASE! */}
          {/* <RowDeprecated className={cx('AlertIncut')}>
          <div className={cx('BellIconWrapper')}>
            <BellIcon />
          </div>
          <Typography variant='caption1' color='grey2'>
            In {'>'} $ 1000 (stub)
          </Typography>
          <div className={cx('AlertIncutDelimiter')} />
          <Typography variant='caption1' color='grey2'>
            In {'>'} $ 1000 (stub)
          </Typography>
        </RowDeprecated> */}
          <div>
            <InfobarTable
              closingDelimiter={!cluster.tokensBalance?.length}
              data={[
                [
                  {
                    name: 'Received:',
                    value: formatMoney({
                      value: isUsd
                        ? cluster.balanceUsd.totalIn
                        : cluster.balance.totalIn,
                      currency: isUsd ? 'usd' : currency,
                    }),
                  },
                  {
                    name: 'Sent:',
                    value: formatMoney({
                      value: isUsd
                        ? cluster.balanceUsd.totalOut
                        : cluster.balance.totalOut,
                      currency: isUsd ? 'usd' : currency,
                    }),
                  },
                  {
                    name: 'Balance:',
                    value: formatMoney({
                      value: isUsd
                        ? cluster.balanceUsd.balance
                        : cluster.balance.balance,
                      currency: isUsd ? 'usd' : currency,
                    }),
                  },
                ],
                [
                  {
                    name: 'First seen:',
                    value: formatDate(
                      fromUnixTime(cluster.firstSeen),
                      'date-time'
                    ),
                  },
                  {
                    name: 'Last seen:',
                    value: formatDate(
                      fromUnixTime(cluster.lastSeen),
                      'date-time'
                    ),
                  },
                ],
                [
                  { name: 'Addresses:', value: formatNumber(cluster.size, 0) },
                  {
                    name: 'Website:',
                    value: cluster.entity?.web ? (
                      <Link
                        to={cluster.entity?.web}
                        className={cx('WebsiteLink')}
                        target="_blank"
                      >
                        {cluster.entity?.web}
                      </Link>
                    ) : (
                      <Typography color="grey4">N/A</Typography>
                    ),
                  },
                ],
              ]}
            />
            <TokensBalance tokens={cluster.tokensBalance} />
          </div>
        </ColDeprecated>
      </Container>
    )
  }
)

export default ClusterInfobar
