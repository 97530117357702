import { FeatureTag, Tag, Tooltip } from '@clainio/web-platform'
import { Fragment } from 'react'
import {
  generateFeatureTags,
  isInputTransaction,
} from './TransactionBlockUTXO.utils'
import { PREFIX_ADDRESS_NULLDATA } from '../TransactionBlock.constants'
import { RowAddressItem } from '../components/RowAddressItem'
import {
  FullWidthFlexBlock,
  FullWidthFlexTypography,
  RowAddressItemContainer,
  RowAddressItemUTXOContainer,
  RowAddressItemUTXOStyled,
  TagsContainerStyled,
  TagsTitleStyled,
} from './TransactionBlockUTXO.styles'
import { TransactionItemProps } from './TransactionBlockUTXO.types'
import { Stack, SurfaceContainer, TypographyNew } from '@clain/core/ui-kit'

export const AddressItem = ({
  leftIcon = null,
  rightIcon = null,
  highlighted = false,
  amountSlot = null,
  blockchain,
  isFullWidth,
  withPaddings = true,
  transactionTagsProperties = {},
  ...props
}: TransactionItemProps) => {
  const tags = generateFeatureTags({ ...props, ...transactionTagsProperties })
  const isInputTrans = isInputTransaction(props)

  const { address, clusterId, score, name } = props
  const showUnableToDecode = address?.[0] === PREFIX_ADDRESS_NULLDATA
  const showFullInputTransaction =
    !showUnableToDecode && isInputTrans && !props.coinbase
  const showFullOutputTransaction = !showUnableToDecode && !isInputTrans

  const showCoinbase = isInputTrans && props.coinbase

  return (
    <RowAddressItemUTXOStyled
      $isFullWidth={isFullWidth}
      $withPaddings={withPaddings}
      $direction={isInputTrans ? 'left' : 'right'}
    >
      {leftIcon}
      <RowAddressItemUTXOContainer>
        <RowAddressItemContainer>
          {showUnableToDecode && (
            <FullWidthFlexTypography
              variant={'body200NormalCode'}
              color={'onBackgroundBase'}
            >
              Unable to decode
            </FullWidthFlexTypography>
          )}
          {(showFullInputTransaction || showFullOutputTransaction) && (
            <>
              <RowAddressItem
                highlighted={highlighted}
                wrapper={Fragment}
                score={score}
                blockchain={blockchain}
                address={address}
                clusterName={name}
                clusterId={clusterId}
              />
              {amountSlot}
            </>
          )}
        </RowAddressItemContainer>
        {props.type !== 'ND' && tags.length && (
          <SurfaceContainer
            variant="base"
            spaces={['xs', 'lg']}
            borderRadius="md"
          >
            <Stack direction="row" gap="xs">
              <TagsTitleStyled>
                <Tooltip content="Features">
                  <TypographyNew
                    variant="body300NormalCode"
                    color="onBackgroundVariant2"
                  >
                    FT
                  </TypographyNew>
                </Tooltip>
              </TagsTitleStyled>
              <TagsContainerStyled gap={0.25}>
                {tags.map((t, index) => (
                  <Tooltip
                    placement={'top-start'}
                    disabled={!t.tooltip}
                    content={t.tooltip}
                    key={index}
                  >
                    <FeatureTag variant={t.color} size={'xxs'}>
                      {t.label}
                    </FeatureTag>
                  </Tooltip>
                ))}
              </TagsContainerStyled>
            </Stack>
          </SurfaceContainer>
        )}
        {showCoinbase && (
          <FullWidthFlexBlock>
            <Tag variant={'accent2'} type={'solid'} size={'sm'}>
              Coinbase
            </Tag>
          </FullWidthFlexBlock>
        )}
      </RowAddressItemUTXOContainer>
      {rightIcon}
    </RowAddressItemUTXOStyled>
  )
}
