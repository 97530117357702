import { Paths } from '@clain/core/ui-kit'
import { selectByKeys } from '@clain/core/utils'
import { FindPath } from '@clain/core/utilsTypes'
import { action, computed, makeObservable, observable } from 'mobx'
import { EdgeType } from '../../types/edgeEntitiesData/EdgeData'
import { NodeType } from '../../types/nodeEntitiesData/NodeData'
import { Position } from '../../types/Position'
import {
  INIT_BOTTOM_BAR_STATUS,
  INIT_BOTTOM_TAB_ACTIVE,
} from './ProbeState.constants'
import {
  ApplayEntitiesAdditionals,
  BottombarStatus,
  BottombarTabActiveTypes,
  EntitiesAdditionals,
  IProbeState,
  ProbeEdges,
  ProbeNodes,
} from './ProbeState.types'

export class ProbeState implements IProbeState {
  @observable public probeId: number
  @observable private _isInitialized = false
  @observable public nodes = new Map<string, ProbeNodes>()
  @observable public edges = new Map<string, ProbeEdges>()
  @observable public selectedNodeIds: Set<string> = new Set()
  @observable public selectedEdgeIds: Set<string> = new Set()
  @observable public isBottombarActive = false
  @observable public isInfobarActive = false
  @observable private _bottombarStatus: 'hidden' | 'default' | 'fullScreen' =
    INIT_BOTTOM_BAR_STATUS
  @observable private _bottombarTabActive: BottombarTabActiveTypes =
    INIT_BOTTOM_TAB_ACTIVE

  @observable public caseData: { title: string; id?: number }

  constructor() {
    makeObservable(this)
  }

  @action
  public setProbeId(probeId: number) {
    this.probeId = probeId
  }

  @computed
  public get isInitialized(): boolean {
    return this._isInitialized
  }

  @computed
  public get getNodes(): Array<ProbeNodes> {
    return Array.from(this.nodes.values())
  }

  @computed
  public get getEdges(): Array<ProbeEdges> {
    return Array.from(this.edges.values())
  }

  @computed
  public get getSelectedEdgeIds(): Array<string> {
    return Array.from(this.selectedEdgeIds.values())
  }

  @computed
  public get getSelectedNodeIds(): Array<string> {
    return Array.from(this.selectedNodeIds.values())
  }

  @computed
  public get getSelectedEdges(): Array<ProbeEdges> {
    return this.getEdges.filter((edge) =>
      this.getSelectedEdgeIds.includes(edge.key)
    )
  }

  @computed
  public get getSelectedNodes(): Array<ProbeNodes> {
    return this.getNodes.filter((node) =>
      this.getSelectedNodeIds.includes(node.key)
    )
  }

  @action
  public setSelectedNodeIds(selectedNodeIds: Set<string>) {
    this.selectedNodeIds = selectedNodeIds
  }

  @action
  public setSelectedEdgeIds(selectedEdgeIds: Set<string>) {
    this.selectedEdgeIds = selectedEdgeIds
  }

  public getNodesDataByType = <
    K extends Paths<ProbeNodes>,
    Type extends NodeType,
    F = FindPath<ProbeNodes, K, Type>,
  >(
    key: K,
    type: Type
  ): F[] => {
    return this.getNodes
      .filter((node) => selectByKeys(key, node) === type)
      .map((node) => node?.data) as F[]
  }

  public getSelectedEdgesDataByType = <
    K extends Paths<ProbeEdges>,
    Type extends EdgeType,
    F = FindPath<ProbeEdges, K, Type>,
  >(
    key: K,
    type: Type[] | Type
  ): ApplayEntitiesAdditionals<F, EntitiesAdditionals>[] => {
    return this.getSelectedEdges
      .filter((edge) => {
        if (Array.isArray(type)) {
          return type.includes(selectByKeys(key, edge))
        }

        return selectByKeys(key, edge) === type
      })
      .map((edge) => ({
        data: edge.data,
        key: edge.key,
      })) as ApplayEntitiesAdditionals<F, EntitiesAdditionals>[]
  }

  public getSelectedNodesDataByType = <
    K extends Paths<ProbeNodes>,
    Type extends NodeType,
    F = FindPath<ProbeNodes, K, Type>,
  >(
    key: K,
    type: Type[] | Type
  ): ApplayEntitiesAdditionals<F, EntitiesAdditionals>[] => {
    return this.getSelectedNodes
      .filter((node) => {
        if (Array.isArray(type)) {
          return type.includes(selectByKeys(key, node))
        }

        return selectByKeys(key, node) === type
      })
      .map((node) => ({
        data: node.data,
        key: node.key,
      })) as ApplayEntitiesAdditionals<F, EntitiesAdditionals>[]
  }

  public getEdgesDataByType = <
    K extends Paths<ProbeEdges>,
    Type extends EdgeType,
    F = FindPath<ProbeEdges, K, Type>,
  >(
    key: K,
    type: Type[] | Type
  ): ApplayEntitiesAdditionals<F, EntitiesAdditionals>[] => {
    return this.getEdges
      .filter((edge) => {
        if (Array.isArray(type)) {
          return type.includes(selectByKeys(key, edge))
        }
        return selectByKeys(key, edge) === type
      })
      .map((edge) => ({
        data: edge.data,
        key: edge.key,
      })) as ApplayEntitiesAdditionals<F, EntitiesAdditionals>[]
  }

  public getNodeDataByType = <
    K extends Paths<ProbeNodes>,
    Type extends NodeType,
    F = FindPath<ProbeNodes, K, Type>,
  >(
    key: K,
    type: Type,
    entityKey: string
  ): F => {
    if (!this.nodes.has(entityKey)) return

    if (selectByKeys(key, this.nodes.get(entityKey)) === type) {
      return this.nodes.get(entityKey).data as F
    }
  }

  @computed
  public get bottombarStatus() {
    return this._bottombarStatus
  }

  @action
  public setBottombarStatus = (status: BottombarStatus) => {
    this._bottombarStatus = status
  }

  @computed
  public get bottombarTabActive() {
    return this._bottombarTabActive
  }

  @action
  public setInitialized(status: boolean) {
    this._isInitialized = status
  }

  @action
  public setBottombarTabActive = (status: BottombarTabActiveTypes) => {
    this._bottombarTabActive = status
  }

  @computed
  get selectedNode() {
    const nodesCount = this.selectedNodeIds.size

    if (nodesCount === 1) {
      return this.nodes.get(Array.from(this.selectedNodeIds)[0])
    }
  }

  @computed
  get selectedEdge() {
    const nodesCount = this.selectedNodeIds.size
    const edgesCount = this.selectedEdgeIds.size

    if (edgesCount && !nodesCount) {
      return this.edges.get(Array.from(this.selectedEdgeIds)[0])
    }
  }

  @action
  public setIsInfobarActive = (isInfobarActive: boolean) => {
    this.isInfobarActive = isInfobarActive
  }

  @action
  public setIsBottombarActive = (isBottombarActive: boolean) => {
    this.isBottombarActive = isBottombarActive
  }

  public getNodePosition = (key: string): Position | undefined => {
    if (this.nodes.has(key)) {
      return this.nodes.get(key).position
    }
  }

  @action
  public setCaseData(caseData: { title: string; id?: number }) {
    this.caseData = caseData
  }

  public clear = () => {
    this.edges.forEach((edge) => {
      edge.destroy()
    })
    this.nodes.forEach((node) => {
      node.destroy()
    })

    this.edges.clear()
    this.nodes.clear()
    this.selectedNodeIds.clear()
    this.selectedEdgeIds.clear()
    this.isBottombarActive = false
    this.isInfobarActive = false
    this.setBottombarStatus(INIT_BOTTOM_BAR_STATUS)
    this.setBottombarTabActive(INIT_BOTTOM_TAB_ACTIVE)
  }
}

export const probeState = new ProbeState()
