import React, { useState } from 'react'

import classnames from 'classnames/bind'
import { ColDeprecated } from '@clain/core/ui-kit'
import { TagDeprecated } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { Icon } from '@clain/core/ui-kit'

import styles from './ImportantCategory.scss'
import { ImportantCategoryProps } from './ImportantCategory.types'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ImportantCategoryField } from './ImportantCategoryField'
import { ImportantCategoryRemoveConfirm } from './ImportantCategoryRemoveConfirm'

const cx = classnames.bind(styles)

export const ImportantCategory: React.FC<ImportantCategoryProps> = ({
  id,
  isDefault,
  label,
  color,
  onSave,
  list,
  onDelete,
  onDefault,
  onError,
}) => {
  const [isEdit, setIsEdit] = useState(false)

  return !isEdit ? (
    <div className={cx('ImportantCategorySection', isEdit && 'Edit')}>
      <ColDeprecated gap={1}>
        <RowDeprecated>
          <TagDeprecated style={{ background: color, color: '#fff' }}>
            {label}
          </TagDeprecated>
        </RowDeprecated>
      </ColDeprecated>
      <ColDeprecated gap={0}>
        <RowDeprecated gap={0.5}>
          <RowDeprecated>
            {isDefault && (
              <TagDeprecated variant="alternate" size="sm">
                DEFAULT
              </TagDeprecated>
            )}
            {!isDefault && (
              <RowDeprecated className={cx('ImportantCategorySetDefault')}>
                <Button onClick={() => onDefault(id)}>Make default</Button>
              </RowDeprecated>
            )}
          </RowDeprecated>
          <Button
            endIcon={'Edit'}
            variant="text"
            color="secondary"
            size="md"
            onClick={() => setIsEdit(true)}
            className={cx('ImportantCategoryButton')}
          >
            Edit
          </Button>
          <ImportantCategoryRemoveConfirm
            label={label}
            color={color}
            onClick={() => onDelete(id)}
          >
            <Button
              endIcon={'Delete'}
              variant="text"
              color="secondary"
              size="md"
              className={cx('ImportantCategoryButton')}
            >
              Delete
            </Button>
          </ImportantCategoryRemoveConfirm>
        </RowDeprecated>
      </ColDeprecated>
    </div>
  ) : (
    <ImportantCategoryField
      color={color}
      label={label}
      id={id}
      onSave={(data) => {
        onSave(data)
        setIsEdit(false)
      }}
      isEdit={isEdit}
      onIsEdit={setIsEdit}
      list={list}
      isDefault={isDefault}
      onError={onError}
    />
  )
}
