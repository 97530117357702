import React from 'react'

import { useFilterSlice } from '../TrxFilters.service'

import {
  SelectFilter,
  SelectFilterCondition,
  SelectFilterMenu,
} from '../../../../../Filters'
import {
  FilterDropdownMenuComponentProps,
  FilterDropdownMenuContainerItem,
} from '@clainio/web-platform/dist/components/Filters/FilterDropdownMenuContainer/FilterDropdownMenuContainer.types'

import { SELECT_FILTER_OPTIONS } from '../../../../../Filters/SelectFilter/SelectFilter.constants'
import { COMPRESSION_OPTIONS } from '@platform/components/BlockchainFilter/constants'
import {
  CompressionFilterState,
  FilterStatesByFilter,
} from '@platform/components/BlockchainFilter/types'

// const ID = 'compression'
const LABEL = 'Compression'
// const TITLE = 'Input'

type FilterProps = Partial<FilterDropdownMenuComponentProps> & {
  id: keyof FilterStatesByFilter<CompressionFilterState>
  title?: string
}

const MenuContainer = ({ id, title }: FilterProps) => {
  const [filter, { setFilter }] = useFilterSlice(`${id}.data`)

  return (
    <SelectFilterMenu
      selected={filter ? filter.value : null}
      title={title}
      onChange={setFilter}
      size={'md'}
      options={COMPRESSION_OPTIONS}
    />
  )
}

const FilterContainer = ({ id, title }: FilterProps) => {
  const [, { resetFilter }] = useFilterSlice(id)
  const [filter] = useFilterSlice(`${id}.data`)
  const [condition] = useFilterSlice(`${id}.condition`)

  return (
    <SelectFilter
      selectedCondition={condition}
      value={filter ? filter.label : ''}
      size={'md'}
      label={title}
      clearFilter={resetFilter}
      menuComponent={<MenuContainer id={id} title={title} />}
      conditionComponent={<FilterConditionContainer id={id} />}
    />
  )
}

const FilterConditionContainer = ({ id }: FilterProps) => {
  const [condition, { setFilter }] = useFilterSlice(`${id}.condition`)

  return (
    <SelectFilterCondition
      options={SELECT_FILTER_OPTIONS}
      selected={condition}
      onChange={setFilter}
      size={'md'}
    />
  )
}

const MenuItem: FilterDropdownMenuContainerItem = {
  id: '',
  label: LABEL,
  icon: null,
  isDisabled: false,
  // component: MenuContainer,
}

export const CompressionFilter = {
  MenuItem: MenuItem,
  Container: FilterContainer,
  Menu: MenuContainer,
}
