import { TransactinEvmContext } from '../TransactionEvmFilters'
import { TransactionsEvmFilters } from '../../../../ProbeSandbox/types/filters/TransactionsEvmFilters'
import {
  TransactionEvmFiltersParams,
  TransactionFiltersParams,
  TransactionUtxoFiltersParams,
} from './TransactionFilters.types'
import { TransactionsBtcFilters } from '../../../../ProbeSandbox/types/filters/TransactionsBtcFilters'
import { TransactinUtxoContext } from '../TransactionUtxoFilters'
import { convertToUnixTimestamp } from '@clain/core/utils/date'

export const normalizeTransactionFiltersEvm = (
  trxFilters: TransactinEvmContext.TransactionEvmFiltersState,
  filterType: TransactionFiltersParams['filterType'],
  defaultFilters: TransactionEvmFiltersParams['defaultFilters'],
  counterpartyType: 'address' | 'cluster'
): TransactionsEvmFilters => {
  const normalizedFilters: TransactionsEvmFilters = {
    ...defaultFilters,
    includeTokens: defaultFilters?.includeTokens,
    scoreFrom: undefined,
    scoreTo: undefined,
    amountFrom: undefined,
    amountTo: undefined,
    from: undefined,
    to: undefined,
    counterpartyName: undefined,
    spam: undefined,
    ...(filterType === 'default'
      ? {
          counterpartyId: undefined,
          direction: undefined,
          counterpartyAddressId: undefined,
        }
      : {}),
  }

  if (trxFilters.spam.data && trxFilters.spam.data.value) {
    normalizedFilters['spam'] = trxFilters.spam.condition === 'is'
  }

  if (trxFilters.sortBy.data) {
    normalizedFilters['sortBy'] = trxFilters.sortBy.data
  }

  if (trxFilters.sortOrder.data) {
    normalizedFilters['sortOrder'] = trxFilters.sortOrder.data
  }

  if (trxFilters.cluster.data && trxFilters.cluster.data?.length) {
    const cluster = trxFilters.cluster.data[0]

    if (counterpartyType === 'address') {
      normalizedFilters['counterpartyAddressId'] = cluster.id
    } else {
      normalizedFilters['counterpartyId'] = cluster.id
    }
    normalizedFilters['counterpartyName'] = cluster.label
  }

  if (trxFilters.score.data) {
    const [scoreFrom, scoreTo] = trxFilters.score.data

    normalizedFilters['scoreFrom'] = scoreFrom
    normalizedFilters['scoreTo'] = scoreTo
  }

  if (trxFilters.asset.data) {
    normalizedFilters['includeTokens'] = trxFilters.asset.data
  }

  if (trxFilters.assetValue.data) {
    const [fromValue, toValue] = trxFilters.assetValue.data
    const assetCondition = trxFilters.assetValue.condition

    if (assetCondition !== 'isLess') {
      normalizedFilters['amountFrom'] = `${fromValue}`
    }

    if (toValue) {
      normalizedFilters['amountTo'] = `${toValue}`
    }

    if (assetCondition === 'is' || assetCondition === 'isLess') {
      normalizedFilters['amountTo'] = `${fromValue}`
    }
  }

  /*
  if (trxFilters.usdAssetValue.data) {
    const { fromValue, toValue } = trxFilters.usdAssetValue.data
    const usdAssetCondition = trxFilters.usdAssetValue.condition

    normalizedFilters['amountFrom'] = `${fromValue}`
    if (toValue) {
      normalizedFilters['amountTo'] = `${toValue}`
    }

    if (usdAssetCondition === 'is' || usdAssetCondition === 'isLess') {
      normalizedFilters['amountTo'] = `${fromValue}`
    }
  }*/

  if (trxFilters.calendar.data && trxFilters.calendar.data) {
    const [fromDate, toDate] = trxFilters.calendar.data

    normalizedFilters['from'] = convertToUnixTimestamp(fromDate)
    normalizedFilters['to'] = convertToUnixTimestamp(toDate)
  }

  if (trxFilters.transferType.data) {
    normalizedFilters['direction'] = trxFilters.transferType.data
  }

  return normalizedFilters
}

export const normalizeTransactionFiltersUtxo = (
  trxFilters: TransactinUtxoContext.TransactionUtxoFiltersState,
  defaultFilters: TransactionUtxoFiltersParams['defaultFilters']
): TransactionsBtcFilters => {
  const normalizedFilters: TransactionsBtcFilters = {
    ...defaultFilters,
    includeTokens: defaultFilters?.includeTokens,
    scoreFrom: undefined,
    scoreTo: undefined,
    amountFrom: undefined,
    amountTo: undefined,
    from: undefined,
    to: undefined,
    direction: undefined,
    counterpartyId: undefined,
    counterpartyName: undefined,
    version: undefined,
    rbf: undefined,
    segwit: undefined,
    lt: undefined,
    feeFrom: undefined,
    feeTo: undefined,
    feePerByteFrom: undefined,
    feePerByteTo: undefined,
  }

  if (trxFilters.sortBy.data) {
    normalizedFilters['sortBy'] = trxFilters.sortBy.data
  }

  if (trxFilters.sortOrder.data) {
    normalizedFilters['sortOrder'] = trxFilters.sortOrder.data
  }

  if (trxFilters.score.data) {
    const [scoreFrom, scoreTo] = trxFilters.score.data

    normalizedFilters['scoreFrom'] = scoreFrom
    normalizedFilters['scoreTo'] = scoreTo
  }

  if (trxFilters.asset.data) {
    normalizedFilters['includeTokens'] = trxFilters.asset.data
  }

  if (trxFilters.assetValue.data) {
    const [fromValue, toValue] = trxFilters.assetValue.data
    const assetCondition = trxFilters.assetValue.condition

    if (assetCondition !== 'isLess') {
      normalizedFilters['amountFrom'] = fromValue
    }

    if (toValue) {
      normalizedFilters['amountTo'] = toValue
    }

    if (assetCondition === 'is' || assetCondition === 'isLess') {
      normalizedFilters['amountTo'] = fromValue
    }
  }

  /*
  if (trxFilters.usdAssetValue.data) {
    const { fromValue, toValue } = trxFilters.usdAssetValue.data
    const usdAssetCondition = trxFilters.usdAssetValue.condition

    normalizedFilters['amountFrom'] = `${fromValue}`
    if (toValue) {
      normalizedFilters['amountTo'] = `${toValue}`
    }

    if (usdAssetCondition === 'is' || usdAssetCondition === 'isLess') {
      normalizedFilters['amountTo'] = `${fromValue}`
    }
  }*/

  if (trxFilters.calendar.data) {
    const [fromDate, toDate] = trxFilters.calendar.data

    normalizedFilters['from'] = convertToUnixTimestamp(fromDate)
    normalizedFilters['to'] = convertToUnixTimestamp(toDate)
  }

  if (trxFilters.transferType.data) {
    normalizedFilters['direction'] = trxFilters.transferType.data
  }

  if (trxFilters.cluster.data && trxFilters.cluster.data?.length) {
    const cluster = trxFilters.cluster.data[0]
    normalizedFilters['counterpartyId'] = cluster.id
    normalizedFilters['counterpartyName'] = cluster.label
  }

  if (trxFilters.fee.data) {
    const [feeFromValue, feeToValue] = trxFilters.fee.data
    const feeCondition = trxFilters.fee.condition

    if (feeCondition !== 'isLess') {
      normalizedFilters['feeFrom'] = feeFromValue
    }

    if (feeToValue) {
      normalizedFilters['feeTo'] = feeToValue
    }

    if (feeCondition === 'is' || feeCondition === 'isLess') {
      normalizedFilters['feeTo'] = feeFromValue
    }
  }

  if (trxFilters.feeByte.data) {
    const [feeByteFromValue, feeByteToValue] = trxFilters.feeByte.data
    const feeByteCondition = trxFilters.feeByte.condition

    if (feeByteCondition !== 'isLess') {
      normalizedFilters['feePerByteFrom'] = feeByteFromValue
    }

    if (feeByteToValue) {
      normalizedFilters['feePerByteTo'] = feeByteToValue
    }

    if (feeByteCondition === 'is' || feeByteCondition === 'isLess') {
      normalizedFilters['feePerByteTo'] = feeByteFromValue
    }
  }

  if (trxFilters.version.data && trxFilters.version.condition === 'is') {
    normalizedFilters['version'] = trxFilters.version.data.value
  }

  if (trxFilters.rbf.data && trxFilters.rbf.data.value) {
    normalizedFilters['rbf'] = trxFilters.rbf.condition === 'is'
  }

  if (trxFilters.segwit.data && trxFilters.segwit.data.value) {
    normalizedFilters['segwit'] = trxFilters.segwit.condition === 'is'
  }

  if (trxFilters.locktime.data && trxFilters.locktime.data.value) {
    normalizedFilters['lt'] = trxFilters.locktime.condition === 'is'
  }

  return normalizedFilters
}
