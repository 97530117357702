import React, { useState } from 'react'
import {
  List,
  Button,
  usePopoverState,
  ColDeprecated,
  Calendar,
} from '@clain/core/ui-kit'
import { ListItemContainer } from '@clainio/web-platform'
import { CalendarFilterMenuProps } from './CalendarFilter.types'

const size = 'md'

export const CalendarFilterMenu = ({
  selectedDate,
  allowedRange,
  onSelect,
  mode = 'datetime',
  timeframe = true,
  disableDaysAfterToday,
  freezeFrom,
}: CalendarFilterMenuProps) => {
  const { setOpen } = usePopoverState()

  const [date, setDate] = useState(selectedDate ? selectedDate : allowedRange)

  const onSubmitCalendar = () => {
    setOpen(false)
    onSelect(date)
  }
  return (
    <List withHeader>
      <Calendar
        value={date || undefined}
        // @ts-expect-error TODO fix Calendar types in ui-kit
        onChange={setDate}
        allowedRange={allowedRange}
        mode={mode}
        timeframe={timeframe}
        disableDaysAfterToday={disableDaysAfterToday}
        freezeFrom={freezeFrom}
      />
      <div style={{ marginBottom: 4 }}>
        <ListItemContainer size={size}>
          <ColDeprecated align={'center'} style={{ width: '100%' }}>
            <Button
              variant="outline"
              color="secondary"
              fullWidth
              onClick={onSubmitCalendar}
            >
              APPLY
            </Button>
          </ColDeprecated>
        </ListItemContainer>
      </div>
    </List>
  )
}
