import { dataAddressesSearchViewModel } from '../model/addresses'
import { useBlockchainFilterEntityPage } from './useBlockchainFilterEntityPage'
import { dataTransactionsSearchViewModel } from '@platform/components/BlockchainFilter/di/transactions/containers'

export const useInjectBlockchainModel = () => {
  const { isTransactions } = useBlockchainFilterEntityPage()

  const viewModel = isTransactions
    ? dataTransactionsSearchViewModel
    : dataAddressesSearchViewModel

  return viewModel
}
