import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from 'mobx'
import {
  apiServicesStateFacade,
  transactionState,
} from './ApiServicesStateFacade'
import { CoinType, Notification } from '@clain/core/ui-kit'
import { queryParamsTrxSearchViewModel } from './QueryParamsTrxSearchViewModel'
import download from 'downloadjs'
import { formatDate } from 'date-fns'
import * as Sentry from '@sentry/react'
import { convertToUnixTimestamp } from '@clain/core/utils/date'
import {
  plotEntitiesController,
  PlotEntitiesController,
} from '@platform/components/ProbeSandbox/vm/PlotEntitiesController'
import {
  ExplorerService,
  TransactionSearchRequestParams,
} from '@platform/apiServices'
import { BlocksHeightState } from '@platform/states/BlocksHeightState'
import { ctx } from '@platform/ctx'
import { UnexpectedErrorText } from '@platform/components/ProbeSandbox/ui/ProbeHeader/AddMultiple/AddMultipleNodes.constants'
import { createProbePath } from '@platform/constants'

export class TransactionsSearchViewModel {
  private blocksHeightState: BlocksHeightState
  private plotEntitiesController: PlotEntitiesController
  private apiServicesStateFacade: typeof apiServicesStateFacade
  private queryParamsViewModel: typeof queryParamsTrxSearchViewModel
  private transactionState: typeof transactionState
  private reactionDisposers: Array<IReactionDisposer> = []
  private explorerService: ExplorerService
  @observable isDownloadingCSV = false

  constructor() {
    makeObservable(this)
    this.explorerService = new ExplorerService()
    this.blocksHeightState = ctx.blocksHeightState
    this.plotEntitiesController = plotEntitiesController
    this.apiServicesStateFacade = apiServicesStateFacade
    this.queryParamsViewModel = queryParamsTrxSearchViewModel
    this.transactionState = transactionState
  }

  @action
  public init = (currency: CoinType) => {
    this.transactionState.initState({
      blockchain: currency,
    })
    this.initQueryParams()
    this.initApiServicesAndFetchData()
    this.initReactions()
  }

  @action
  private initReactions = () => {
    this.reactionDisposers.push(
      reaction(
        () => ({
          ...this.transactionsFilters,
        }),
        (filters) => {
          this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
            ...prevState,
            ...filters,
          }))
        }
      )
    )
  }

  @action
  private initApiServicesAndFetchData = () => {
    this.initTransactions()
    this.apiServicesStateFacade.initDataLoadingReaction('transactions')
  }

  @computed
  private get transactionsFilters() {
    return this.apiServicesStateFacade.getApiParamsStateByService(
      'transactions'
    )
  }

  @action
  private initTransactions = () => {
    this.apiServicesStateFacade.initApiParamsStateByService('transactions')(
      this.queryParamsViewModel.getUrlParams
    )
    this.apiServicesStateFacade.initDefaultApiParamsStateByService(
      'transactions'
    )({
      ...(this.queryParamsViewModel.queryParamsController
        .queryParamsDefaultState as unknown as TransactionSearchRequestParams),
    })
    this.apiServicesStateFacade.injectRequestMethodByService('transactions')(
      (params) => {
        return this.explorerService.transactionsSearchRequest(this.blockchain)({
          ...params,
          trx_time_from: convertToUnixTimestamp(params.trx_time_from),
          trx_time_to: convertToUnixTimestamp(params.trx_time_to),
        })
      }
    )
  }

  @action
  public downloadCSV = () => {
    const params = this.transactionsFilters

    if (params != null) {
      this.isDownloadingCSV = true
      const formattedParams = {
        ...params,
        trx_time_from: convertToUnixTimestamp(params.trx_time_from),
        trx_time_to: convertToUnixTimestamp(params.trx_time_to),
      }
      this.explorerService
        .downloadCSVRequest(this.blockchain)(formattedParams)
        .then((r) =>
          download(
            r.data,
            `txn - ${formatDate(new Date(), 'yyyy-MM-dd-HH-mm')}.csv`,
            'text/csv'
          )
        )
        .catch((reason) => {
          Sentry.captureException(new Error(reason), {
            extra: { requestParameters: formattedParams },
          })
          Notification.notify(
            UnexpectedErrorText,
            { type: 'warning' },
            { position: 'top-right' }
          )
        })
        .finally(() => {
          this.isDownloadingCSV = false
        })
    }
  }

  @action
  public plotEntitiesOnGraph = (
    entities: Parameters<typeof this.plotEntitiesController.pushPlotEntities>[1]
  ) => {
    this.blocksHeightState.getNewProbe().then((probe) => {
      window.open(createProbePath(probe.id), '_blank')
      this.plotEntitiesController.pushPlotEntities(probe.id, entities)
    })
  }

  @action
  private initQueryParams = () => {
    this.queryParamsViewModel.initQueryParams()
  }

  @computed
  public get blockchain() {
    return this.transactionState.state.blockchain
  }

  @action
  public clear = () => {
    this.apiServicesStateFacade.clear()
    this.reactionDisposers = []
    this.queryParamsViewModel.queryParamsController.clearQueryParamsState()
  }
}

export const transactionsSearchViewModel = new TransactionsSearchViewModel()
