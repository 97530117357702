import { Stack, TypographyNew } from '@clain/core/ui-kit'
import { SelectedEntitiesCountProps } from './SelectedEntitiesCount.types'

export const SelectedEntitiesCount: React.FC<SelectedEntitiesCountProps> = ({
  count,
  entityName,
}) => {
  return count ? (
    <TypographyNew variant={'body100Normal'} color={'onBackgroundVariant1'}>
      <Stack align="center" gap="xs">
        <div>{'('}</div>
        <TypographyNew variant={'body100Accent'} color={'onBackgroundVariant1'}>
          {count}
        </TypographyNew>
        <div>
          {`${entityName}${count > 1 ? 's' : ''} on this page ${
            count > 1 ? 'are' : 'is'
          } selected`}
        </div>
        <div>{')'}</div>
      </Stack>
    </TypographyNew>
  ) : null
}
