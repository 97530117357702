import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import { Button } from '@clain/core/ui-kit'
import { ButtonSelect } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { Select } from '@clain/core/ui-kit'
import { CheckboxSwitch } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import ProbeVM from '../../../vm/ProbeViewModel'

import styles from './index.scss'

const cx = classnames.bind(styles)

const options = [
  {
    value: 'elk-layered',
    label: 'elk-layered',
  },
  {
    value: 'elk-stress',
    label: 'elk-stress',
  },
  {
    value: 'dagre',
    label: 'dagre',
  },
]

const _ExtendedLayoutPanel: React.FC = () => {
  const {
    toggle,
    active,
    activeLayout,
    elkStressOptions,
    updateElkStressOptions,
    randomize,
    setRandomize,
    lock,
    setLock,
  } = ProbeVM._extendedLayoutPanel

  return (
    <>
      <Button
        variant="solid"
        color="critical"
        onClick={toggle}
        className={cx('Button')}
      >
        setup layout
      </Button>
      <div className={cx('Overlay', { active })}>
        <ColDeprecated className={cx('Global')}>
          <RowDeprecated gap={2}>
            <ButtonSelect
              options={options}
              value={ProbeVM._extendedLayoutPanel.activeLayout}
              onChange={ProbeVM._extendedLayoutPanel.setActiveLayout}
              className={cx('ButtonSelect')}
            />
            <Button
              size="lg"
              variant="solid"
              color="primary"
              onClick={ProbeVM._extendedLayoutPanel.save}
            >
              save to local storage
            </Button>
          </RowDeprecated>
          <CheckboxSwitch
            value={randomize}
            onChange={setRandomize}
            label="Randomize node positions before layouting"
          />
          <CheckboxSwitch
            value={lock}
            onChange={setLock}
            label="Lock all nodes on canvas (works only for layouting after new nodes added)"
          />
        </ColDeprecated>
        <div className={cx('Delimiter')} />

        {activeLayout === 'elk-stress' && (
          <>
            <ColDeprecated gap={0.5}>
              <CheckboxSwitch
                value={elkStressOptions.interactive}
                onChange={(interactive) =>
                  updateElkStressOptions({ interactive })
                }
                label="interactive"
              />
            </ColDeprecated>
            <div className={cx('Delimiter')} />
            <ColDeprecated gap={0.5}>
              <RowDeprecated>
                <Select
                  variant="outline"
                  value={elkStressOptions.stressDimension}
                  options={['XY', 'X', 'Y']}
                  onChange={(stressDimension) =>
                    updateElkStressOptions({ stressDimension })
                  }
                  className={cx('Input')}
                />
                <Typography>stressDimension</Typography>
              </RowDeprecated>
            </ColDeprecated>
            <div className={cx('Delimiter')} />
            <ColDeprecated gap={0.5}>
              <RowDeprecated>
                <TextField
                  size="sm"
                  variant="outline"
                  value={elkStressOptions.desiredEdgeLength}
                  defaultValue={elkStressOptions.desiredEdgeLength}
                  onChange={(desiredEdgeLength) =>
                    updateElkStressOptions({ desiredEdgeLength })
                  }
                  className={cx('Input')}
                />
                <Typography>desiredEdgeLength</Typography>
              </RowDeprecated>
              <RowDeprecated>
                <TextField
                  size="sm"
                  variant="outline"
                  value={elkStressOptions.stressEpsilon}
                  defaultValue={elkStressOptions.stressEpsilon}
                  onChange={(stressEpsilon) =>
                    updateElkStressOptions({ stressEpsilon })
                  }
                  className={cx('Input')}
                />
                <Typography>stressEpsilon</Typography>
              </RowDeprecated>
            </ColDeprecated>
          </>
        )}
      </div>
    </>
  )
}

export default observer(_ExtendedLayoutPanel)
