import React from 'react'
import {
  FilterDropdownMenuContainerTypes,
  FiltersMenu,
} from '@clain/core/ui-kit'
import {
  AddressesAssetFilterPropsById,
  AddressesComparatorFilterPropsById,
} from './AddressesFilter.const'
import { ComparatorFilter } from '@platform/components/BlockchainFilter/containers'
import { AssetFilter } from './AddressesAssetFilter'
import {
  FILTER_RECEIVED_AMOUNT_KEY,
  FILTER_BALANCE_KEY,
  FILTER_ASSET_KEY,
} from '@platform/components/BlockchainFilter/constants'

export const AddressesFiltersMenu: React.FC = () => {
  const itemsGroup: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    [
      {
        ...AddressesAssetFilterPropsById[FILTER_ASSET_KEY.root],
        component: AssetFilter.Menu,
      },
      {
        ...AddressesComparatorFilterPropsById[FILTER_RECEIVED_AMOUNT_KEY.root],
        component: ComparatorFilter.Menu as any,
      },
      {
        ...AddressesComparatorFilterPropsById[FILTER_BALANCE_KEY.root],
        component: ComparatorFilter.Menu as any,
      },
    ] as FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[]

  const items = [itemsGroup]

  return <FiltersMenu.Wrapper items={items} />
}
