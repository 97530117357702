import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'

import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'

import styles from './index.scss'

const cx = classnames.bind(styles)

type TagsInputValue = Array<string>

interface TagsInputProps {
  value: TagsInputValue
  onChange: (value: TagsInputValue) => void
}

const TagsInput: React.FC<TagsInputProps> = ({ value, onChange }) => {
  const [inputValue, setInputValue] = React.useState<string>('')

  const removeTag = (tag: string) => {
    onChange(value.filter((tagVal) => tagVal !== tag))
  }

  React.useEffect(() => {
    if (inputValue.length && inputValue[inputValue.length - 1] === ' ') {
      const newTags = inputValue
        .trim()
        .split(' ')
        .filter((newTag) => {
          return newTag && !value.includes(newTag)
        })

      onChange([...value, ...newTags].slice(0, 5))

      setInputValue('')
    }
  }, [inputValue])

  return (
    <ColDeprecated>
      <TextField
        value={inputValue}
        onChange={setInputValue}
        variant="outline"
        placeholder="Insert up to 5 words. Separate with Space"
        disabled={value.length >= 5}
      />
      {value.length > 0 && (
        <RowDeprecated gap={0.5} wrap={true}>
          {value.map((tag) => (
            <div key={tag} className={cx('Tag')}>
              <Typography variant="body1-sm" color="ocean3">
                {tag}
              </Typography>
              <CloseIcon
                className={cx('CloseIcon')}
                onClick={() => removeTag(tag)}
              />
            </div>
          ))}
        </RowDeprecated>
      )}
    </ColDeprecated>
  )
}

export default TagsInput
