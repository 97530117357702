import {
  EntitiesCount,
  PlotOnGraphPanel,
  SelectedEntitiesCount,
} from '../../containers'
import { ListToPanelLayout } from '../../ui'

export const TransactionListToPanel: React.FC = () => {
  return (
    <ListToPanelLayout
      title="Transactions"
      leftComponent={
        <>
          <EntitiesCount />
          <SelectedEntitiesCount entityName="Transactions" />
        </>
      }
      rightComponent={<PlotOnGraphPanel />}
    />
  )
}
