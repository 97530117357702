import React from 'react'
import classnames from 'classnames/bind'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import { ScorePanel } from '@clain/core/ui-kit'
import ExposureChart from './ExposureChart'

import styles from './Exposure.scss'
import {
  ExposureItem,
  ExposureMode,
  ExposureUmbrellaProps,
} from './Exposure.types'

const cx = classnames.bind(styles)

const compactData = (data: ExposureItem[]) => {
  const reducedData = data.reduce((result, item) => {
    const score = Math.floor(item.score)
    const categoryItem = {
      amount: item.amount,
      name: item.name,
    }
    result[score] = result[score]
      ? {
          amount: result[score].amount
            ? result[score].amount + item.amount
            : null,
          value: result[score].value + item.value,
          score,
          categories: [...result[score].categories, categoryItem],
        }
      : {
          amount: item.amount,
          value: item.value,
          score,
          categories: [categoryItem],
        }

    return result
  }, {} as ExposureItem)

  return Object.values(reducedData)
}

const ExposureUmbrella = ({
  mode = 'default',
  data,
  className,
  useNewColors,
}: ExposureUmbrellaProps) => {
  return mode === 'default' ? (
    <ExposureChart
      className={className}
      data={data}
      useNewColors={useNewColors}
    />
  ) : (
    <ScorePanel data={compactData(data)} useNewColors={useNewColors} />
  )
}

interface ExposureProps {
  className?: string
  mode?: ExposureMode
  data: [Array<ExposureItem>, Array<ExposureItem>]
  useNewColors?: boolean
  withTitle?: boolean
}

const Exposure: React.FC<ExposureProps> = ({
  className,
  data: [source, destination],
  useNewColors = false,
  withTitle = true,
}) => {
  return (
    <MagicGrid className={cx('ExposurePortlet', className)} gap={0.5}>
      {withTitle && (
        <RowDeprecated align="between">
          <Typography variant="heading3" color="grey1" className={cx('Title')}>
            Exposure
          </Typography>
        </RowDeprecated>
      )}
      <RowDeprecated gap={1.5} className={cx('ExposureItems')}>
        <ColDeprecated className={cx('ExposureItem')}>
          <Typography variant="subheading2" color="grey3">
            Source
          </Typography>
          {source.length > 0 ? (
            <ExposureUmbrella
              mode={'default'}
              className={cx('ExposureChart')}
              data={source}
              useNewColors={useNewColors}
            />
          ) : (
            <Typography variant="heading2" color="grey4" className={cx('Stub')}>
              There is no exposure source data yet
            </Typography>
          )}
        </ColDeprecated>
        <ColDeprecated className={cx('ExposureItem')}>
          <Typography variant="subheading2" color="grey3">
            Destination
          </Typography>
          {destination.length > 0 ? (
            <ExposureUmbrella
              mode={'default'}
              className={cx('ExposureChart')}
              data={destination}
              useNewColors={useNewColors}
            />
          ) : (
            <Typography variant="heading2" color="grey4" className={cx('Stub')}>
              There is no exposure destination data yet
            </Typography>
          )}
        </ColDeprecated>
      </RowDeprecated>
    </MagicGrid>
  )
}

export default Exposure
