import React, { useEffect, useRef } from 'react'
import {
  ColDeprecated,
  FiltersButtons,
  RowDeprecated,
  Stub,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import { useFilterNameList, useInitFilters } from './SenkeyChartFilters.service'
import { AssetFilterContainer } from './AssetFilter'
import { ScoreFilterContainer } from './ScoreFilter'
import { CalendarFilterContainer } from './CalendarFilter'
import { useSenkeyViewModel } from '../../modules/analytics/Senkey/SenkeyData.context'

export const SenkeyChartFilters = observer(() => {
  const { isFiltersChanged, isLoading, resetFilters, excludeFilters } =
    useSenkeyViewModel()
  const _filterList = useFilterNameList()
  const filterList = excludeFilters?.length
    ? _filterList.filter((filter) => {
        const _excludeFilters: typeof _filterList = excludeFilters
        return !_excludeFilters.includes(filter)
      })
    : _filterList
  useInitFilters()
  const slots = useRef([])
  const createFilter = (filter: (typeof filterList)[number]) => {
    switch (filter) {
      case 'asset':
        return slots.current.push(<AssetFilterContainer key={filter} />)
      case 'score':
        return slots.current.push(<ScoreFilterContainer key={filter} />)
      case 'calendar':
        return slots.current.push(<CalendarFilterContainer key={filter} />)
    }
  }

  useEffect(() => {
    slots.current = []
    filterList.map(createFilter)
  }, [filterList.length])

  return (
    <Stub isActive={isLoading}>
      <ColDeprecated>
        <RowDeprecated align={'between'} fullWidth>
          <RowDeprecated>
            {slots.current?.length ? slots.current.map((el) => el) : null}
          </RowDeprecated>
          <FiltersButtons.Clear
            disabled={!isFiltersChanged}
            size="md"
            onClear={resetFilters}
            iconVariant="Restore"
            label="Reset"
          />
        </RowDeprecated>
      </ColDeprecated>
    </Stub>
  )
})
