import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Card, CheckboxSwitch, Stub } from '@clain/core/ui-kit'
import { FundsFlowFilters } from '../FundsFlowFilters'
import { FundsFlowChart } from '../FundsFlowChart'
import classnames from 'classnames/bind'
import styles from './FundsFlowSection.scss'
import { DEFAULT_USD_TOKEN } from '../ProbeSandbox/utils/convertTokenBalances'
import { FiltersProvider } from '../FundsFlowFilters/FundsFlowFilters.service'
import { Button, RowDeprecated, Tooltip } from '@clainio/web-platform'
import { useNetflowViewModel } from '../../modules/analytics/Netflow/NetflowData.context'
import { useCascadeButton } from './useCascadeButton'
import { CascadeFilterTooltipContent } from './CascadeFilterTooltipContent'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'

const cx = classnames.bind(styles)

type FundsFlowSectionProps = {
  onClickCascadeFilters: () => void
  isCascadeLoading: boolean
}

export const FundsFlowSection = observer(
  ({ onClickCascadeFilters, isCascadeLoading }: FundsFlowSectionProps) => {
    const buttonState = useCascadeButton(isCascadeLoading)
    const { assetFilter, updateFilters, isLoading, convertToFilter } =
      useNetflowViewModel()
    const assetId = assetFilter.id

    const onChangeCurrencyConversation = useCallback((isUsd: boolean) => {
      updateFilters({
        convertTo: isUsd ? 'usd' : 'native',
      })
    }, [])

    return (
      <FiltersProvider>
        <ErrorBoundaryContainer>
          <Card
            title={'Funds Flow'}
            headerSlot={
              <Stub isActive={isLoading}>
                <CheckboxSwitch
                  disabled={assetId === DEFAULT_USD_TOKEN.token.id}
                  label={'USD'}
                  value={convertToFilter === 'usd'}
                  onChange={onChangeCurrencyConversation}
                />
              </Stub>
            }
          >
            <div className={cx('FundsFlowFilters')}>
              <RowDeprecated gap={0.5}>
                <div className={cx('FullWidthBlock')}>
                  <FundsFlowFilters />
                </div>
                <Stub isActive={isLoading}>
                  <Tooltip
                    placement={'top-end'}
                    content={<CascadeFilterTooltipContent />}
                  >
                    <div style={{ minWidth: 110 }}>
                      <Button
                        fullWidth
                        disabled={isCascadeLoading}
                        isLoading={isCascadeLoading}
                        variant="outline"
                        color="secondary"
                        size="md"
                        onClick={onClickCascadeFilters}
                        endIcon={buttonState.variant}
                      >
                        {buttonState.label}
                      </Button>
                    </div>
                  </Tooltip>
                </Stub>
              </RowDeprecated>
            </div>
            <FundsFlowChart />
          </Card>
        </ErrorBoundaryContainer>
      </FiltersProvider>
    )
  }
)
