import { Button, Stack, StatusBlock, Tooltip } from '@clainio/web-platform'
import React from 'react'
import { usePresetsContext } from '@platform/components/BlockchainFilter/ui/PresetsList/PresetsContext'
import { NO_FILTERS_TOOLTIP_MESSAGE } from '@platform/components/BlockchainFilter/ui/PresetsList/Presets.constants'

export const PresetsEmpty = ({
  isDisabledCreatePreset,
}: {
  isDisabledCreatePreset: boolean
}) => {
  const { toggleCreatePreset } = usePresetsContext()

  return (
    <Stack padding={['xxxxl', 'none', 'none', 'none']}>
      <StatusBlock gap={'xxl'}>
        <StatusBlock.Image maxWidth={74} type={'filterSlider'} />
        <StatusBlock.Title
          variant={'body100Normal'}
          color={'onBackgroundVariant1'}
        >
          You can create a new preset based on the selected filter set.
        </StatusBlock.Title>
        <Tooltip
          content={NO_FILTERS_TOOLTIP_MESSAGE}
          disabled={!isDisabledCreatePreset}
        >
          <div>
            <Button
              disabled={isDisabledCreatePreset}
              onClick={toggleCreatePreset}
              variant={'outline'}
              size={'md'}
              color={'secondary'}
              endIcon={'Plus'}
            >
              Add Preset
            </Button>
          </div>
        </Tooltip>
      </StatusBlock>
    </Stack>
  )
}
