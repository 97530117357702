import {
  action,
  autorun,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  comparer,
} from 'mobx'
import { captureException } from '@sentry/react'

import ProbeApp from '@clain/graph'
import type { LayoutType } from '@clain/graph-layout'
import { ProbeApp as ProbeAppType } from '../types/ProbeApp'
import LayersViewModel from './LayersViewModel'
import UtxoController from './UtxoController'
import SearchController from './SearchController'
import SearchService from './services/SearchService/SearchService'
import ActiveEntityViewModel from './active-entity/ActiveEntityViewModel'
import { positioningController } from './PositioningController'
import { Position } from '../types/Position'
import { pointerController } from './PointerController'
import CommentsController from './CommentsController'
import TextController from './TextController/TextController'
import ShortcutMenuController from './shortcut-menu/ShortcutMenuController'
import AsyncQueue from './queue/AsyncQueue'
import { extendedLayoutPanelViewModel } from './_ExtendedLayoutPanelViewModel'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()
import type { ProbeFullData } from './services/ProbeService'
import { ProbeService } from './services/ProbeService'

import { PixiMenuEventPayload } from '@clain/graph/src/menu'
import PixiEvent from '@clain/graph/src/core/PixiEvent'
import { shiftOrMod } from '@clain/core/utils/tools'
import {
  AlertsViewModel,
  SettingsViewModel,
  UserPresenceViewModel,
} from '../../../modules'
import { ctx } from '../../../ctx'
import { getAddressIdProbe } from './active-entity/helpers/getAddressId'
import { alertEventsCountState } from '../../../modules/alerts/AlertsViewModel.utils'
import { applyAlertCount } from './vm.utils'
import CameraViewModel from './CameraViewModel'
import { ReciveEvents } from './ReciveEvents/ReciveEvents'
import { IProbeState, probeState as _probeState } from './ProbeState'
import { graphHistory } from './GraphHistory'
import { EventsGraphReaction } from './EventsGraphReaction'
import { AIReportService } from './services/AIReportService'
import { probeEvents, reactionGraphEventsSettings } from './ProbeEvents'
import { normalizeEventToNodeData } from '../utils/normalizeEventToNodeData'
import { compose } from 'ramda'
import { probeGraph } from './ProbeGraph'
import { normalizeEventWithAlertCount } from '../utils/normalizeEventWithAlertCount'
import { animationEntities } from './AnimationEntities'
import { Notification } from '@clain/core/ui-kit'
import { PROBE_NOTIFICATION_STYLES } from '../constants'
import { render as renderSVG } from '@clainio/probe-svg-renderer'
import DemixActionViewModel from './DemixActionViewModel'
import { normalizeEventReciveDataToRequest } from '../utils/normalizeEventReciveDataToRequest'
import { entityDataToSnapshot } from './EntityDataToSnapshot'
import { somePositionIsDiff } from '../utils/somePositionIsDiff'
import { EntityServices } from './services/EntitiesServices'
import { CURRENCY } from '../constants/currency'
import { IEntityServices } from './services/EntitiesServices/types'
import { GraphEntityEventFacade } from './GraphEntityEvent'
import {
  Settings,
  ServerCamera,
  ServerNodeData,
  ServerEdgeData,
  ServerGraphData,
} from '../types/serverData'
import { plotEntitiesController } from './PlotEntitiesController'
import { moveProbeNodes } from './layout'
import CrossChainSwapActionViewModel from './CrossChainSwapActionViewModel'
import {
  WORLD_WIDTH,
  WORLD_HEIGHT,
  MIN_SCALE,
  MAX_SCALE,
  GRAPH_BACKGROND_COLOR,
  DEFAULT_X_COORDINATE,
  DEFAULT_Y_COORDINATE,
  DEFAULT_ZOOM,
} from './constants'
import { deleteEntityController } from './DeleteEntityController'
import { CircularMenuViewModel } from './CircularMenuViewModel/CircularMenuViewModel'
import { circularMenuNodes } from './CircularMenuViewModel'
import { circularMenuEdges } from './CircularMenuViewModel/CircularMenuEdges'
import { circularMenuWorld } from './CircularMenuViewModel/CircularMenuWorld'
import { theme } from '../../../modules/theme'
import { paletteController } from './PaletteController'
import { rearrangeNodesController } from './controllers'
import { searchState } from './states'
import { GraphFactoryEntities } from '@clain/graph-factory-entities'
import { NodeData } from '../types/nodeEntitiesData/NodeData'
import { EdgeData } from '../types/edgeEntitiesData/EdgeData'
import { ReziseGraphViewport } from './ReziseGraphViewport'
import { getProbeModule } from '../di/probeContainer'
import { DI_PROBE_TYPES } from '../di/DITypes'
import { EntityLinkingController } from '@platform/components/ProbeSandbox/vm/GraphEntityEvent/controllers/EntityLinkingController'

class ProbeViewModel {
  public app: ProbeAppType
  private reactionDisposers: Array<IReactionDisposer> = []

  public layers = new LayersViewModel(this)
  public camera = new CameraViewModel(this)
  public alerts = new AlertsViewModel(ctx)
  //TODO make it injectable, it should not be initialized here
  public entityLinkingController = new EntityLinkingController(this)
  private graphEntityEventsFacade = new GraphEntityEventFacade(this)
  public plotEntitiesController = plotEntitiesController
  private deleteEntityController = deleteEntityController

  public history = graphHistory
  public probeEvents = probeEvents

  private graphFactoryEntitiesInstance = new GraphFactoryEntities<
    ServerNodeData,
    NodeData,
    ServerEdgeData,
    EdgeData
  >(theme, _probeState, probeGraph, this.layers)

  public factoryNodeEdge =
    this.graphFactoryEntitiesInstance.getFactoryEntities()

  public factory = this.graphFactoryEntitiesInstance.getFactoryEntity()

  public utxoController = new UtxoController(
    this,
    getProbeModule(DI_PROBE_TYPES.AutoSelectTransaction)
  )
  public demixAction = DemixActionViewModel.getInstance(this)
  public crossChainSwapAction = CrossChainSwapActionViewModel.getInstance(this)
  public searchController = new SearchController(
    this,
    this.probeEvents,
    searchState
  )
  public activeEntity = new ActiveEntityViewModel(
    this,
    this.probeEvents,
    getProbeModule(DI_PROBE_TYPES.AutoSelectTransaction)
  )
  public eventsGraphReaction = new EventsGraphReaction(this.probeState)
  public rearrangeNodesController = rearrangeNodesController

  public settings = new SettingsViewModel(ctx)
  public positioningController = positioningController
  public pointerController = pointerController
  public commentsController = new CommentsController(this)
  public textController = new TextController(this)
  public shortcutMenuController = new ShortcutMenuController(this)
  public circularMenuController = new CircularMenuViewModel(
    this,
    theme,
    paletteController,
    circularMenuNodes,
    circularMenuEdges,
    circularMenuWorld
  )
  public asyncQueue = new AsyncQueue()
  public _extendedLayoutPanel = extendedLayoutPanelViewModel
  public reciveEvents = new ReciveEvents()

  public entityServices: IEntityServices
  public searchService: SearchService
  public generateAIService: AIReportService
  private reziseGraphViewport = new ReziseGraphViewport(this)

  public probeService: ProbeService

  public get _showLayoutPanelButton(): boolean {
    return config.ENV !== 'production'
  }

  @observable public isInitialized = false
  @observable public activeSpace = false
  @observable public activeMouse = false
  @observable public isRightSidebarActive = false
  @observable public isAnalyticsLayerActive = false
  @observable public isShortcutsModalActive = false
  @observable public mouseDownNodeKey: string
  @observable public isMagneticGridActive = false

  @observable public isUsdCurrency = false
  @observable public layout: LayoutType = 'elk'
  @observable public probeData: ProbeFullData
  public container: HTMLElement
  // @observable public probeData: { name: string; data: ServerGraphData } // TODO: вернуть после выкатки бека
  @observable public userPresenceVM: UserPresenceViewModel

  @computed
  public get scaled(): number {
    return this.app.scaled
  }

  @computed
  public get center(): Position {
    return this.app.center
  }

  @computed
  public get grabbing(): boolean {
    return this.activeMouse
  }

  constructor(public probeState: IProbeState) {
    makeObservable(this)
  }

  @action
  public createApp = async (container: HTMLElement) => {
    this.isInitialized = false
    this.container = container
    try {
      this.app = new ProbeApp({
        container,
        graph: probeGraph,
        resolution: window.devicePixelRatio + MAX_SCALE,
        backgroundColor: GRAPH_BACKGROND_COLOR,
        worldWidth: WORLD_WIDTH,
        worldHeight: WORLD_HEIGHT,
        minScale: MIN_SCALE,
        maxScale: MAX_SCALE,
      })

      this.plotEntitiesController.initReactionRenderEntities()
      await this.app.init()
      this.reziseGraphViewport.observer()

      this.initReactions()
      this.searchService = SearchService.getInstance()
      this.entityServices = EntityServices.getInstance(CURRENCY)
      this.generateAIService = AIReportService.getInstance()
      this.activeEntity.init()
      this.demixAction.init()
      this.commentsController.init()
      this.textController.init()
      this.shortcutMenuController.init()
      this.generateAIService.init()
    } catch (e) {
      this.isInitialized = true
      throw new Error(e)
    }
  }

  @action
  public initApp = async ({ probeId }: { probeId: string }) => {
    try {
      this.probeState.setProbeId(parseInt(probeId))
      this.initListeners()
      await this.loadProbe()
    } catch (e) {
      if (e.reason) {
        Notification.notify(
          e.reason,
          { type: 'warning' },
          PROBE_NOTIFICATION_STYLES
        )
      }
    } finally {
      this.isInitialized = true
    }
  }

  private factoryEntities = (
    graph: Pick<ServerGraphData, 'edges' | 'nodes'>
  ) => {
    try {
      if (graph?.nodes) {
        Object.entries(graph.nodes).forEach(
          ([, { id, position, nodeData, key }]) => {
            const count = alertEventsCountState(
              this.alerts.counts,
              getAddressIdProbe(nodeData)
            )

            this.factoryNodeEdge.produce('node', {
              key,
              data: {
                id,
                nodeData: applyAlertCount(nodeData, count),
                position,
              },
              settings: { locked: true },
            })
          }
        )
      }

      if (graph?.edges) {
        Object.entries(graph.edges).forEach(([key, data]) => {
          this.factoryNodeEdge.produce('edge', {
            key,
            data,
          })
        })
      }
    } catch (e) {
      console.error(e)
      captureException(e)
    }
  }

  @action
  private loadProbe = async () => {
    this.probeService = new ProbeService({
      probeId: this.probeState.probeId,
    })
    animationEntities.injectApp(this.app, this.probeState)

    const { case: caseData, probe, graph } = await this.probeService.init()

    this.setProbeData(probe)
    this.probeState.setCaseData(caseData)
    if (graph?.settings) {
      this.setIsMagneticGridActive(graph?.settings.grid)
      this.layers.init(graph?.settings.layers)
    }

    this.camera.init(graph?.camera)
    this.factoryEntities(graph)

    this.positioningController.calculateSpaceMatrix()
    this.userPresenceViewModelInit(caseData?.owner)

    this.history.initSaveRequest(
      compose(
        this.probeService.sendGraphEvent,
        normalizeEventReciveDataToRequest
      )
    )

    this.probeEvents.initSaveRequest(this.probeService.sendGraphEvent)
    this.history.subscribe((snapshot) => {
      this.eventsGraphReaction.multipleEvents(
        normalizeEventToNodeData(snapshot)
      )
    })

    this.probeEvents.subscribe(({ events, meta }) => {
      if (meta.options.snapshotToHistory) {
        this.history.push(entityDataToSnapshot.eventToSnapshot(events))
      }
      this.eventsGraphReaction.multipleEvents(
        normalizeEventToNodeData(
          normalizeEventWithAlertCount(events, this.alerts.counts)
        )
      )
    })
    this.probeEvents.subscribe(({ emitEntitiesKeys, meta }) => {
      animationEntities.execute({
        entitiesKeys: emitEntitiesKeys,
        options: meta.options,
      })
    })

    this.probeEvents.subscribe(({ events }) => {
      const isActiveEntityContextChanged =
        this.activeEntity.selectedKey &&
        events.some(
          (event) => event.type === 'delete_node' || event.type === 'add_node'
        )
      if (isActiveEntityContextChanged) {
        this.activeEntity.detectType({ isForceDetect: true })
      }
    })

    this.probeService.subscribeUpdatedGraphEvent(this.reciveEvents.emitEvent)
    this.reciveEvents.subscribe(this.history.removeSnapshots.emitEvent)
    this.reciveEvents.subscribe((events) => {
      this.eventsGraphReaction.multipleEvents(
        normalizeEventToNodeData(
          normalizeEventWithAlertCount(events, this.alerts.counts)
        )
      )
    })
    this.rearrangeNodesController.subscribe((positions) => {
      this.asyncQueue.enQueue(async () => {
        if (somePositionIsDiff(positions, this.probeState.getNodePosition)) {
          this.probeEvents.emit(
            Object.keys(positions).map((key) => ({
              type: 'update_position',
              key,
              data: { position: positions[key] },
            })),
            { optimistic: true }
          )
          this.history.push(
            entityDataToSnapshot.nodesPositionToSnapshot(
              positions,
              this.probeState.getNodePosition
            )
          )
        }
        await moveProbeNodes({
          positions,
          graph: this.app.graph,
          probeNodes: this.probeState.nodes,
        })
      })
    })

    this.probeState.setInitialized(true)
  }

  @action
  private userPresenceViewModelInit = (caseDataOwner: number | null) => {
    this.userPresenceVM = this.probeService.userPresenceViewModel
    this.userPresenceVM.init({
      settings: this.settings,
      pointer: this.pointerController,
      app: this.app,
      caseOwner: caseDataOwner,
    })
  }

  public initListeners() {
    //TODO extract to separate class all handlers
    this.app.on('node:click', this.graphEntityEventsFacade.handleNodeClick)
    this.app.on(
      'node:mouseover',
      this.graphEntityEventsFacade.handleNodeMouseOver
    )
    this.app.on(
      'node:mouseout',
      this.graphEntityEventsFacade.handleNodeMouseOut
    )
    this.app.on(
      'node:mousedown',
      this.graphEntityEventsFacade.handleNodeMouseDown
    )
    this.app.on('node:mouseup', this.graphEntityEventsFacade.handleNodeMouseUp)
    this.app.on('edge:mouseup', this.graphEntityEventsFacade.handleEdgeClick)
    this.app.on(
      'edge:mouseover',
      this.graphEntityEventsFacade.handleEdgeMouseOver
    )
    this.app.on(
      'edge:mouseout',
      this.graphEntityEventsFacade.handleEdgeMouseOut
    )
    this.app.on('menu:mouseup', this.handleMenuMouseUp)
    this.app.on('select', this.graphEntityEventsFacade.handleSelectArea)
    this.app.on('unselect', this.graphEntityEventsFacade.handleUnSelectArea)
    this.app.on('world:mousedown', this.handleWorldMouseDown)
    this.app.on('world:click', this.graphEntityEventsFacade.handleWorldClick)
    this.app.on('world:mouseup', this.handleWorldMouseUp)
    this.app.on('world:wheel', this.handleWorldZoom)
    this.app.on('animated:zoom', this.handleWorldZoom)

    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
    document.addEventListener('mousedown', this.handleMouseDown)
    document.addEventListener('mouseup', this.handleMouseUp)
  }

  public removeListeners() {
    reactionGraphEventsSettings()
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.app?.off('node:click', this.graphEntityEventsFacade.handleNodeClick)
    this.app?.off(
      'node:mouseover',
      this.graphEntityEventsFacade.handleNodeMouseOver
    )
    this.app?.off(
      'node:mouseout',
      this.graphEntityEventsFacade.handleNodeMouseOut
    )
    this.app?.off(
      'node:mousedown',
      this.graphEntityEventsFacade.handleNodeMouseDown
    )
    this.app?.off(
      'node:mouseup',
      this.graphEntityEventsFacade.handleNodeMouseUp
    )
    this.app?.off('edge:mouseup', this.graphEntityEventsFacade.handleEdgeClick)
    this.app?.off(
      'edge:mouseover',
      this.graphEntityEventsFacade.handleEdgeMouseOver
    )
    this.app?.off(
      'edge:mouseout',
      this.graphEntityEventsFacade.handleEdgeMouseOut
    )
    this.app?.off('menu:mouseup', this.handleMenuMouseUp)
    this.app?.off('select', this.graphEntityEventsFacade?.handleSelectArea)
    this.app?.off('unselect', this.graphEntityEventsFacade?.handleUnSelectArea)
    this.app?.off('world:mousedown', this.handleWorldMouseDown)
    this.app?.off(
      'world:mousedown',
      this.graphEntityEventsFacade.handleWorldClick
    )
    this.app?.off('world:mouseup', this.handleWorldMouseUp)
    this.app?.off('world:wheel', this.handleWorldZoom)
    this.app?.off('animated:zoom', this.handleWorldZoom)

    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
    document.removeEventListener('mousedown', this.handleMouseDown)
    document.removeEventListener('mouseup', this.handleMouseUp)
    this.probeService?.clear()
    this.app?.removeAllListeners()
    this.activeEntity.clear()
    this.textController?.clear()
    this.probeState?.clear()
    this.generateAIService?.closeGenerateAIReportChannel()

    this.setIsRightSidebarActive(false)
    this.setIsAnalyticsLayerActive(false)
    this.probeState.setInitialized(false)
    this.plotEntitiesController.clear()
    probeGraph.clear()
    this.probeEvents.clear()
    this.reciveEvents.clearSubscribers()
    this.history.clear()
    this.app.destroy()
    this.reziseGraphViewport.clear()
    this.rearrangeNodesController.clearSubscribers()
  }

  public downloadSVG = async () => {
    try {
      const svg = await renderSVG({
        nodes: Array.from(this.probeState.nodes.keys()).map((key) => ({
          key,
          options: this.app.graph.getNodeAttributes(key),
        })),
        edges: Array.from(this.probeState.edges.keys())
          .map((key) => ({
            source: this.app.graph.source(key),
            target: this.app.graph.target(key),
            options: this.app.graph.getEdgeAttributes(key),
          }))
          .filter((edge) => edge.options.data.edgeType !== 'comment'),
      })
      const aElement = document.createElement('a')
      aElement.setAttribute(
        'href',
        'data:image/svg+xml;charset=utf-8, ' + encodeURIComponent(svg)
      )
      aElement.setAttribute(
        'download',
        `${this.probeData.name}_${this.probeState.probeId}.svg`
      )
      document.body.appendChild(aElement)
      aElement.click()
      document.body.removeChild(aElement)
    } catch (e) {
      Notification.notify(
        'Something went wrong during SVG generating',
        { type: 'warning' },
        PROBE_NOTIFICATION_STYLES
      )
    }
  }

  @action
  public setProbeData(probeData: ProbeFullData) {
    this.probeData = probeData
  }
  @action
  public setSelectedNodeIds(selectedNodeIds: Set<string>) {
    this.probeState.selectedNodeIds = selectedNodeIds
  }
  @action
  public setSelectedEdgeIds(selectedEdgeIds: Set<string>) {
    this.probeState.selectedEdgeIds = selectedEdgeIds
  }

  @action
  public setIsRightSidebarActive(isRightSidebarActive: boolean) {
    this.isRightSidebarActive = isRightSidebarActive
  }

  @action
  public setIsAnalyticsLayerActive(isAnalyticsLayerActive: boolean) {
    this.isAnalyticsLayerActive = isAnalyticsLayerActive
  }

  @action
  public setIsShortcutsModalActive(isShortcutsModalActive: boolean) {
    this.isShortcutsModalActive = isShortcutsModalActive
  }

  @action
  public setMouseDownNodeKey(mouseDownNodeKey: string) {
    this.mouseDownNodeKey = mouseDownNodeKey
  }

  @action
  public setIsMagneticGridActive(isMagneticGridActive: boolean) {
    this.isMagneticGridActive = isMagneticGridActive
    this.app.setIsActiveGrid(isMagneticGridActive)
  }

  @action
  public saveIsMagneticGridActive(isMagneticGridActive: boolean) {
    this.setIsMagneticGridActive(isMagneticGridActive)
    this.updateSettings({
      grid: isMagneticGridActive,
    })
  }

  @computed
  public get currentSettings(): Settings {
    return {
      grid: this.isMagneticGridActive,
      layers: this.layers.getLayers,
    }
  }

  @action
  public updateSettings(settingsData: Settings) {
    const currentSettings = this.currentSettings
    this.probeService.updateSettings({
      grid: this.isMagneticGridActive ?? currentSettings.grid,
      layers: {
        ...currentSettings.layers,
        ...settingsData.layers,
      },
    })
  }

  @action
  public updateCamera(cameraData: ServerCamera) {
    this.probeService.updateCamera(cameraData)
  }

  @action public setIsUsdCurrency = (isUsdCurrency: boolean) => {
    this.isUsdCurrency = isUsdCurrency
  }

  @action public setLayout = (layout: LayoutType) => {
    this.layout = layout
  }

  @action public setActiveSpace = (activeSpace: boolean) => {
    this.activeSpace = activeSpace
  }

  @action public setActiveMouse = (activeMouse: boolean) => {
    this.activeMouse = activeMouse
  }

  @action.bound
  public linkProbeToCase(caseId: number) {
    return this.probeService.linkProbeToCase(caseId)
  }

  @computed public get isDeleteNodeDisabled() {
    return this.probeEvents.meta.nodesIsInProcessing
  }

  public deleteActiveNode = this.deleteEntityController.deleteActiveNode

  public deleteActiveEdge = this.deleteEntityController.deleteActiveEdge

  private handleMenuMouseUp = ({
    payload: { itemId, entityKey },
  }: PixiEvent<PixiMenuEventPayload>) => {
    if (itemId === 'remove') {
      if (this.isDeleteNodeDisabled) {
        Notification.notify(
          'Removing nodes is restricted due to graph processing',
          { type: 'warning' },
          PROBE_NOTIFICATION_STYLES
        )
        return
      }

      if (this.probeState.selectedNodeIds.size > 1) {
        this.deleteEntityController.deleteActiveNode()
      } else {
        this.deleteEntityController.deleteNode(entityKey)
      }

      this.app.destroyMenu()
    }
  }

  private handleKeyUp = (event: KeyboardEvent) => {
    if (event.code === 'Space') {
      this.setActiveSpace(false)
    }
  }

  private handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Space') {
      this.setActiveSpace(true)
    }
    const eventTarget = event.target as HTMLElement
    if (!['INPUT', 'TEXTAREA'].includes(eventTarget?.tagName)) {
      if (event.key === '?') {
        this.setIsShortcutsModalActive(true)
      }

      if (event.code === 'KeyC' && !shiftOrMod(event)) {
        this.commentsController.addComment()
      }

      if (event.code === 'KeyG') {
        this.setIsMagneticGridActive(!this.isMagneticGridActive)
      }

      if (event.code === 'KeyL') {
        this.setIsRightSidebarActive(!this.isRightSidebarActive)
      }

      if (event.code === 'KeyT') {
        this.textController.createTextNode()
      }

      // Remove selection only on `escape`
      if (event.code === 'Escape') {
        this.setSelectedNodeIds(new Set())
        this.setSelectedEdgeIds(new Set())
        this.activeEntity.detectType()
        this.circularMenuController.hide()
      }

      if (event.code === 'Delete' || event.code === 'Backspace') {
        if (this.isDeleteNodeDisabled) return

        this.deleteEntityController.deleteActiveNode()
        this.deleteEntityController.deleteActiveEdge()
      }
    }

    if (event.code === 'KeyR') {
      this.rearrangeNodesController.run()
    }

    if (event.metaKey && event.code === 'KeyZ') {
      this.history.undo()
    }

    if (
      (event.metaKey && event.shiftKey && event.code === 'KeyZ') ||
      (event.metaKey && event.code === 'KeyY')
    ) {
      this.history.redo()
    }
  }

  private handleWorldMouseDown = () => {
    this.demixAction.closeDemixTrackListPopup()
    this.circularMenuController.hide()
  }

  @action
  private handleWorldMouseUp = () => {
    this.camera.updateCamera()
  }

  @action
  private handleWorldZoom = ({ payload }: { payload: { scaled: number } }) => {
    this.camera.setZoom(payload.scaled)
  }

  private handleMouseDown = (event: MouseEvent) => {
    // Left mouse button
    if (event.button === 0) {
      this.setActiveMouse(true)
    }
  }

  private handleMouseUp = (event: MouseEvent) => {
    // Left mouse button
    if (event.button === 0) {
      this.setActiveMouse(false)
    }
  }

  public _getExtendedLayoutOptions(unlocked: Array<string>) {
    return {
      randomize: this._extendedLayoutPanel.randomize,
      alias: this._extendedLayoutPanel.alias as LayoutType,
      opts: this._extendedLayoutPanel.opts,
      unlocked: this._extendedLayoutPanel.lock ? unlocked : undefined,
    }
  }

  @action.bound
  private initReactions() {
    this.reactionDisposers.push(
      reaction(
        () => ({
          isUsdCurrency: this.isUsdCurrency,
          timezone: this.settings?.userSettings?.timezone,
        }),
        ({ timezone, isUsdCurrency }) => {
          this.graphFactoryEntitiesInstance.layoutSettingsState().setState({
            isUsdCurrency,
            timezone,
            graphBackgroundColor: GRAPH_BACKGROND_COLOR,
          })
        },
        { equals: comparer.structural }
      )
    )

    this.reactionDisposers.push(
      autorun(() => {
        this.probeState.nodes.forEach((probeNode) =>
          probeNode.setLetterNotation(
            this.settings.userSettings.graph.letterNotation.graph
          )
        )
      })
    )

    this.reactionDisposers.push(
      autorun(() => {
        this.probeState.edges.forEach((probeEdge) =>
          probeEdge.setLetterNotation(
            this.settings.userSettings.graph.letterNotation.graph
          )
        )
      })
    )

    this.reactionDisposers.push(
      autorun(() => {
        this.probeState.nodes.forEach((probeNode, key) =>
          probeNode.setHighlighted(this.probeState.selectedNodeIds.has(key))
        )
      })
    )

    this.reactionDisposers.push(
      autorun(() => {
        this.probeState.edges.forEach((probeEdge, key) =>
          probeEdge.setHighlighted(this.probeState.selectedEdgeIds.has(key))
        )
      })
    )

    this.reactionDisposers.push(
      autorun(() => {
        this.shortcutMenuController.items.forEach((item) => {
          item.disabled = !this.layers.comments
        })
      })
    )

    this.reactionDisposers.push(
      reaction(
        () => this.alerts.counts,
        () => {
          this.probeState.nodes.forEach((probeNode) => {
            const count = alertEventsCountState(
              this.alerts.counts,
              getAddressIdProbe(probeNode.data)
            )

            probeNode.updateData({ alertCount: count })
          })
        }
      )
    )
  }

  @computed
  public get isAddingNodes() {
    return (
      this.searchController.addMultipleNodesInProgress ||
      this.plotEntitiesController.plotNodesIsInProcessing
    )
  }
}

export {
  ProbeViewModel,
  DEFAULT_X_COORDINATE,
  DEFAULT_Y_COORDINATE,
  DEFAULT_ZOOM,
}

export default new ProbeViewModel(_probeState)
