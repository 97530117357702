import React, { useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import classnames from 'classnames/bind'

import { CounterTabs, CounterTabPropsOption } from '@clain/core/ui-kit'

import { RowDeprecated } from '@clain/core/ui-kit'
import Pagination from '@clain/core/Pagination'

import BottombarTopPanel from '../BottombarTopPanel'
import ProbeVM from '../../../vm/ProbeViewModel'

import styles from './index.scss'
import { isUTXO } from '@clain/core/types/coin'
import { useGetTokens } from '../hooks/useGetTokens'
import {
  TransactionFilters,
  TransactionTable,
  useProbeTransactionsTableConfig,
  TransactionFlowUtxoTable,
  TransactionFlowBtcTableItem,
} from '../../../../EntitiesTable'
import { useAggregateActionData } from '../hooks'

const cx = classnames.bind(styles)

interface FlowBtcBottombarProps {
  itemsPerPage: number
}

const FlowBottombar: React.FC<FlowBtcBottombarProps> = ({ itemsPerPage }) => {
  const activeTab = ProbeVM.probeState.bottombarTabActive
  const activeEntity = ProbeVM.activeEntity.flow
  const getTokens = useGetTokens()

  useLayoutEffect(() => {
    ProbeVM.probeState.setBottombarTabActive('transactions')
  }, [])

  const flowBtcBottombarTabs: CounterTabPropsOption[] = [
    {
      value: 'transactions',
      children: 'transactions',
      counterValue: activeEntity.transactions?.state?.data?.totalEntries ?? 0,
      isShowZeroValue: true,
      // className: cx('CounterTab'), // unsupported by CounterTabs
    },
  ]

  const page = {
    transactions: activeEntity.filters.transactions.filters?.page,
  }[activeTab]

  const setPage = {
    transactions: (page) =>
      activeEntity.filters.transactions.updateFilters({ page }),
  }[activeTab]

  const totalPages = {
    transactions: activeEntity.transactions?.state?.data.totalPages,
  }[activeTab]

  const transactionsFilters = toJS(activeEntity.filters.transactions.filters)

  const transactionsTableData = useAggregateActionData({
    data: activeEntity.transactions?.state?.data?.transactions,
    type: 'transaction',
    selectedType: 'node',
    currency: activeEntity.currency,
  })

  const transactionConfig = useProbeTransactionsTableConfig({
    data: transactionsTableData,
    coinType: ProbeVM.activeEntity.currency,
    cumulative: activeEntity.transactions?.state?.cumulative,
    setSelected: activeEntity.toggleTransaction,
    setAllSelected: activeEntity.toggleAllTransactions,
    filters: transactionsFilters,
    setFilters: activeEntity.filters.transactions.updateFilters,
    isLoading: activeEntity.transactions.loading,
    getSearchResults: ProbeVM.searchService.getBlockchainResults,
    getSearchClusters: ProbeVM.searchService.getEntitiesResults,
    showInUSD: ProbeVM.isUsdCurrency,
  })

  return (
    <div className={cx('FlowBtcBottombar')}>
      <BottombarTopPanel>
        <RowDeprecated className={cx('TopPanelContent')} align="between">
          <CounterTabs
            options={flowBtcBottombarTabs}
            value={activeTab}
            onChange={ProbeVM.probeState.setBottombarTabActive}
            className={cx('CounterTabs')}
            fullHeight
            size="sm"
          />
          <Pagination
            value={page}
            totalPages={totalPages}
            onChange={setPage}
            className={cx('Pagination', {
              visible: ProbeVM.probeState.bottombarStatus !== 'hidden',
            })}
            size="md"
          />
        </RowDeprecated>
      </BottombarTopPanel>
      {activeTab === 'transactions' &&
        (isUTXO(ProbeVM.activeEntity.currency) ? (
          <TransactionFlowUtxoTable
            data={transactionsTableData as TransactionFlowBtcTableItem[]}
            cumulative={activeEntity.transactions?.state?.cumulative}
            showInUSD={ProbeVM.isUsdCurrency}
            setSelected={activeEntity.toggleTransaction}
            setAllSelected={activeEntity.toggleAllTransactions}
            filters={transactionsFilters}
            setFilters={activeEntity.filters.transactions.updateFilters}
            isLoading={activeEntity.transactions.loading}
            itemsPerPage={itemsPerPage}
            getTokens={getTokens}
            currency={ProbeVM.activeEntity.currency}
          />
        ) : (
          <TransactionTable
            filtersContent={
              <TransactionFilters
                counterpartyType={'cluster'}
                filterType="flow"
                currency={ProbeVM.activeEntity.currency}
                filters={transactionsFilters}
                initialFilters={toJS(
                  activeEntity.filters.transactions.initialFilters
                )}
                defaultFilters={toJS(
                  activeEntity.filters.transactions.defaultFilters
                )}
                excludeFilters={activeEntity.excludeFilters}
                tokens={activeEntity.transactionTokens}
                disabledAssetStaticSearch={
                  activeEntity.disabledTransactionAssetStaticSearch
                }
                onSearchAsset={activeEntity.setTokenByAddress}
                searchAssetLoading={activeEntity.tokenByAddressLoading}
                filterSize="sm"
                getSearchResults={ProbeVM.searchService.getEntitiesResults}
                setFilters={activeEntity.filters.transactions.updateFilters}
              />
            }
            data={transactionsTableData}
            isLoading={activeEntity.transactions.loading}
            itemsPerPage={itemsPerPage}
            config={transactionConfig}
            coinType={ProbeVM.activeEntity.currency}
          />
        ))}
    </div>
  )
}

export default observer(FlowBottombar)
