import { useFilterSlice } from '../TransactionUtxoFilters.service'
import { getFilterLabels } from '@clain/core/ui-kit'
import { useTransactionStaticStore } from '../../context'
import { enabledEntitiesSearch } from '../../../../../ProbeSandbox/vm/services/SearchService/SearchService.utils'
import { useCallback, useState } from 'react'
import { ClusterSearchResult } from '../../../../../ProbeSandbox/vm/services/SearchService/SearchService.types'
import { TextFilterMenuListItem } from '../../../../../Filters'
import { FILTER_CLUSTER_KEY } from '../../../../constants'

export const useClusterFilterData = () => {
  const [data, { setFilter }] = useFilterSlice(FILTER_CLUSTER_KEY.data)
  const [, { resetFilter }] = useFilterSlice(FILTER_CLUSTER_KEY.root)

  const [condition] = useFilterSlice(FILTER_CLUSTER_KEY.condition)
  const { isMultiple, multipleLabel } = getFilterLabels({
    filterValueCount: data?.length || 0,
    condition,
    pluralLabel: 'Cluster',
  })

  return {
    isMultiple,
    multipleLabel,
    selectedItems: data,
    setFilter,
    resetFilter,
  }
}

export const useClusterSearchData = () => {
  const transactionStaticStore = useTransactionStaticStore()
  const [searchClusters, setSearchCluster] = useState<TextFilterMenuListItem[]>(
    []
  )

  const getClusters = useCallback(async (query: string) => {
    if (enabledEntitiesSearch(query)) {
      const clusterResults = await transactionStaticStore.current
        .getSearchResults(query)
        .then((searchResults) =>
          searchResults.filter(
            ({ type }) => type === 'cluster' || type === 'entity'
          )
        )
        .then((searchResults) =>
          searchResults.flatMap(
            ({ result }) => result as Array<ClusterSearchResult>
          )
        )
        .then((searchResults) =>
          searchResults.filter(
            ({ currency: c }) => c === transactionStaticStore.current.currency
          )
        )

      setSearchCluster(
        clusterResults.map((cluster) => ({
          id: cluster.clusterId,
          score: cluster.score,
          label: cluster.name,
        }))
      )
    }

    if (!query) {
      setSearchCluster([])
    }
  }, [])

  return {
    menuListData: searchClusters,
    onSearch: getClusters,
  }
}

export const useSelectedClusterFilterData = () => {
  const [selectedItems] = useFilterSlice(FILTER_CLUSTER_KEY.data)

  return selectedItems?.length ? selectedItems[0] : null
}
