import React from 'react'
import classnames from 'classnames/bind'
import * as echarts from 'echarts'

import Chart from '@clain/core/Chart2'
import {
  randomFloat,
  randomInt,
  stubColor,
} from '@clain/core/Chart2/mock.utils'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { formatNumber } from '@clain/core/utils/format'
import createTooltipFormatter from '@clain/core/Chart2/createTooltipFormatter'
import { fontFamily } from '@clain/core/styles/fonts'

import styles from './Exposure.scss'
import { enterprise } from '../ui-kit'
import { ThemeProvider } from 'styled-components'
import { Typography } from '@clain/core/ui-kit'

const cx = classnames.bind(styles)

const ExposureTooltipRow = ({
  rowKey,
  rowValue,
}: {
  rowKey: string
  rowValue: React.ReactNode
}) => (
  <RowDeprecated className={cx('ExposureTooltipRow')}>
    <Typography color="black">{rowKey}: </Typography>
    <Typography color="black" className={cx('ExposureTooltipValue')}>
      {rowValue}
    </Typography>
  </RowDeprecated>
)

const ExposureTooltip = ({
  name,
  value,
  root,
  score,
  useNewColors = false,
}) => {
  if (!name) {
    return null
  }

  const splittedName = name.split(' ')
  const childrenCategoryName = splittedName.slice(0, -1).join(' ')
  const fractionInCategory = splittedName.slice(-1).join('')
  const itemName = root ? name : childrenCategoryName

  return (
    <ThemeProvider theme={enterprise['light']}>
      <div className={cx('ExposureTooltip')}>
        <RowDeprecated gap={0.5}>
          <Score value={score} useNewColors={useNewColors} />
          <Typography color="black" variant="body1">
            {itemName}
          </Typography>
        </RowDeprecated>

        {root ? (
          <div className={cx('ExposureTooltipList')}>
            <ExposureTooltipRow
              rowKey="Fraction"
              rowValue={`${formatNumber(value * 100, 1)}%`}
            />
          </div>
        ) : (
          <div className={cx('ExposureTooltipList')}>
            <ExposureTooltipRow
              rowKey={`Fraction in "${itemName}"`}
              rowValue={fractionInCategory}
            />
            <ExposureTooltipRow
              rowKey="Overall fraction"
              rowValue={`${formatNumber(value * 100, 1)}%`}
            />
          </div>
        )}
      </div>
    </ThemeProvider>
  )
}

const generateMockData = (): TopLevelDataItem[] => {
  let unit = 1

  return new Array(randomInt(8, 14)).fill(null).map((_, index, arr) => {
    let randomValue = (unit *= randomFloat(0.5, 1))

    if (index === arr.length - 1) {
      randomValue += unit
    }

    return {
      name: '',
      score: 0,
      value: randomValue,
      color: stubColor,
    }
  })
}

interface TopLevelDataItem {
  name: string
  value: number
  color: string
  score: number
  children?: Array<Omit<TopLevelDataItem, 'children'> & { link?: string }>
}

interface ExposureOptions {
  data: TopLevelDataItem[]
  useNewColors?: boolean
}

function getOptions({ data, useNewColors }: ExposureOptions) {
  const gapWidth = 4

  const treemap: echarts.TreemapSeriesOption = {
    name: '',
    type: 'treemap',
    // насколько мелкие элементы показывать
    // TODO: кажется это надо рассчитывать в зависимости от данных
    visibleMin: 1, //10000,
    label: {
      show: true,
    },
    // Делаем одинаковые отступы https://echarts.apache.org/en/option.html#series-treemap.left
    left: gapWidth,
    top: gapWidth,
    right: gapWidth,
    bottom: gapWidth,
    // Убираем зум и перетаскивание курсором
    roam: false,

    itemStyle: {
      borderWidth: 2,
      gapWidth: 8,
      borderColor: '#fff',
    },
    squareRatio: 16 / 9,

    breadcrumb: {
      show: false,
      emptyItemWidth: 0,
    },
    data: data.map(({ color, children, ...item }) => {
      const root = children?.length > 0

      return {
        ...item,
        // для тултипа
        root,
        itemStyle: {
          color,
          borderWidth: 2,
          borderColor: color,
          gapWidth: -1,
        },
        upperLabel: {
          show: true,
          height: 28,
          fontSize: 14,
          color: '#0F131B',
          width: '100%',
          textBorderColor: 'none',
          lineOverflow: 'truncate',
        },
        label: {
          fontSize: 14,
          color: '#0F131B',
          formatter: ({ name, value }) =>
            `${name} ${formatNumber(Number(value) * 100, 1)}%`,
        },
        children: children?.map(({ color, ...child }) => ({
          ...child,
          label: {
            fontSize: 14,
            color: '#0F131B',
            formatter: ({ name }) => name,
          },
          itemStyle: {
            color,
            borderWidth: 1,
            borderColor: '#374766',
          },
        })),
      }
    }),
  }

  const options: echarts.EChartsOption = {
    textStyle: {
      fontFamily,
      fontSize: 14,
    },

    tooltip: data
      ? {
          confine: true,
          appendToBody: false,
          transitionDuration: 0,
          enterable: false,
          formatter: createTooltipFormatter(
            ({ data: { name, value, root, score } }) => ({
              name,
              value,
              root,
              score,
              useNewColors,
            }),
            ExposureTooltip
          ),
          // same as in Dropdown2
          extraCssText: 'box-shadow: 0px 4px 40px rgba(0, 17, 158, 0.25);',
          borderWidth: 0,
        }
      : null,

    series: [treemap],
  }

  return options
}

interface ExposureChartProps extends ExposureOptions {
  loading?: boolean
  className?: string
  useNewColors?: boolean
}

const ExposureChart = React.memo(
  ({
    data: propsData,
    loading,
    className,
    useNewColors = false,
  }: ExposureChartProps) => {
    const data = propsData || generateMockData()

    const option = React.useMemo(
      () => getOptions({ data, useNewColors }),
      [propsData]
    )

    return (
      <Chart
        loading={loading}
        stub={!data}
        option={option}
        style={{
          height: 300,
        }}
        className={className}
      />
    )
  }
)

export default ExposureChart
ExposureChart.displayName = 'ExposureChart'
