import { useFilterSlice } from '@platform/components/EntitiesTable/tables/TransactionsTable/TransactionEvmFilters/TransactionEvmFilters.service'
import { getFilterLabels } from '@clain/core/ui-kit'
import { useTransactionStaticStore } from '../../context'
import { TextFilterMenuListItem } from '../../../../../Filters'
import { useFetchAddresses } from '@platform/components/EntitiesTable/tables/CounterpartyTable/hooks/useFetchAddresses'

export const useAddressFilterData = () => {
  const [data, { setFilter }] = useFilterSlice('cluster.data')
  const [, { resetFilter }] = useFilterSlice('cluster')

  const [condition] = useFilterSlice('cluster.condition')
  const { isMultiple, multipleLabel } = getFilterLabels({
    filterValueCount: data?.length || 0,
    condition,
    pluralLabel: 'Addresses',
  })

  return {
    isMultiple,
    multipleLabel,
    selectedItems: data,
    setFilter,
    resetFilter,
  }
}

export const useClusterSearchData = () => {
  const addressStaticStore = useTransactionStaticStore()
  const { getAddresses, addresses } = useFetchAddresses<TextFilterMenuListItem>(
    {
      getSearchResults: addressStaticStore.current.getSearchResults,
      currency: addressStaticStore.current.currency,
      normalize: ({ aid, address }) => ({
        id: aid,
        label: address,
      }),
    }
  )

  return {
    menuListData: addresses,
    onSearch: getAddresses,
  }
}

export const useSelectedClusterFilterData = () => {
  const [selectedItems] = useFilterSlice('cluster.data')

  return selectedItems?.length ? selectedItems[0] : null
}
