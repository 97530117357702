import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'

import { useFormatDate } from '../../../../../../hooks'
import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface BaseCardProps {
  date: Date
  icon: React.ElementType
  label: string
  title?: React.ReactNode
  className?: string
}

const BaseCard: React.FC<PropsWithChildren<BaseCardProps>> = ({
  date,
  icon: Icon,
  label,
  title,
  className,
  children,
}) => {
  const formatDate = useFormatDate()

  return (
    <div className={cx('BaseCard', className)}>
      <div className={cx('Heading', { withBody: children })}>
        <RowDeprecated gap={0.5}>
          <Icon className={cx('Icon')} />
          <Typography variant="body1" color="purple" transform="capitalize">
            {label}
          </Typography>
        </RowDeprecated>
        {title && (
          <Typography variant="body1" color="grey2" className={cx('Title')}>
            {title}
          </Typography>
        )}
        <Typography variant="body1" color="grey4" className={cx('Date')}>
          {formatDate(date, 'time')}
        </Typography>
      </div>
      {children}
    </div>
  )
}

export default BaseCard
