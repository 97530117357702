import React from 'react'

import { useParams } from '@clain/core/Router/router'
import { Container } from '@clain/core/ui-kit'

import { AlertCaution } from '@clain/core/ui-kit'
import { TransactionFiltersTrx } from './Filters/TrxFiltersContainer'

import { TransactionListToPanel } from './TransactionListToPanel'
import { TransactionList } from './TransactionList'
import { observer } from 'mobx-react-lite'
import { EntitiesNotFound } from '../../ui/EntitiesNotFound'
import {
  BlockchainFilterPagination,
  EntitiesModelProvider,
} from '../../containers'
import { ContentLayout } from '../../ui'
import { BlockchainFilterPathParams } from '@platform/constants'
import { dataTransactionsSearchViewModel } from '@platform/components/BlockchainFilter/di/transactions/containers'

export const btcToSatoshi = (value?: string) => {
  if (!value) return null

  return Math.round(Number(value) * 1e8)
}

const _TransactionsSearch: React.FC = () => {
  const {
    isTransactionsTableDataLoading,
    isTransactionsTableDataError,
    transactionsTableDataError,
    isTransactionsTableDataEmpty,
    isShowTopPanel,
  } = dataTransactionsSearchViewModel
  const { coin } = useParams<BlockchainFilterPathParams>()

  return (
    <EntitiesModelProvider>
      <ContentLayout
        filtersComponent={<TransactionFiltersTrx />}
        topComponent={isShowTopPanel && <TransactionListToPanel />}
        loading={isTransactionsTableDataLoading}
        bottomComponent={
          <>
            {isTransactionsTableDataEmpty && (
              <EntitiesNotFound
                title="No Matches Found"
                subtitle="Either no transactions match your filters or the request timed out."
                subtitleSecondary="Try modifying your filters or simplifying your query."
              />
            )}
            {isTransactionsTableDataError && (
              <Container gap={[0.5, 0]}>
                <AlertCaution variant="error">
                  {transactionsTableDataError}
                </AlertCaution>
              </Container>
            )}
            <BlockchainFilterPagination />
          </>
        }
      >
        <TransactionList coin={coin} />
      </ContentLayout>
    </EntitiesModelProvider>
  )
}

export const TransactionsSearch = observer(_TransactionsSearch)
