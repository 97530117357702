import React from 'react'

import { useParams } from '@clain/core/Router/router'
import { Container } from '@clain/core/ui-kit'

import { AlertCaution } from '@clain/core/ui-kit'
import { AddressesFiltersContainer } from './Filters/AddressesFiltersContainer'

import { AddressesListToPanel } from './AddressesListToPanel'
import { AddressesList } from './AddressesList'
import { observer } from 'mobx-react-lite'
import { dataAddressesSearchViewModel } from '../../model/addresses'
import { EntitiesNotFound } from '../../ui/EntitiesNotFound'
import {
  BlockchainFilterPagination,
  EntitiesModelProvider,
} from '../../containers'
import { ContentLayout } from '../../ui'
import { BlockchainFilterPathParams } from '@platform/constants'

export const btcToSatoshi = (value?: string) => {
  if (!value) return null

  return Math.round(Number(value) * 1e8)
}

const _AddressesSearch: React.FC = () => {
  const {
    isTransactionsTableDataLoading,
    isTransactionsTableDataError,
    transactionsTableDataError,
    isTransactionsTableDataEmpty,
    isShowTopPanel,
  } = dataAddressesSearchViewModel
  const { coin } = useParams<BlockchainFilterPathParams>()

  return (
    <EntitiesModelProvider>
      <ContentLayout
        filtersComponent={<AddressesFiltersContainer />}
        topComponent={isShowTopPanel && <AddressesListToPanel />}
        loading={isTransactionsTableDataLoading}
        bottomComponent={
          <>
            {isTransactionsTableDataEmpty && (
              <EntitiesNotFound
                title="No Matches Found"
                subtitle="Either no addresses match your filters or the request timed out."
                subtitleSecondary="Try modifying your filters or simplifying your query."
              />
            )}
            {isTransactionsTableDataError && (
              <Container gap={[0.5, 0]}>
                <AlertCaution variant="error">
                  {transactionsTableDataError}
                </AlertCaution>
              </Container>
            )}
            <BlockchainFilterPagination />
          </>
        }
      >
        <AddressesList coin={coin} />
      </ContentLayout>
    </EntitiesModelProvider>
  )
}

export const AddressesSearch = observer(_AddressesSearch)
