import React, { FC, useState } from 'react'
import { Tabs } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Button, Icon, Spinner } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import classnames from 'classnames/bind'
import styles from './Report.scss'
import { useClipboard } from '@clain/core/useClipboard'
import reportFacade from '../../ProbeGenerateReport.facade'
import { observer } from 'mobx-react-lite'
import { Tooltip } from '@clain/core/ui-kit'
import {
  actionButtonWidth,
  LANGUAGE,
  regenerateButtonTooltipMessage,
  tabsOptions,
} from './Report.constants'
import { TypingArea } from './ReportTypingArea'

const cx = classnames.bind(styles)

export interface TabsReportProps {
  isReportGenerating: boolean
}

export const Report: FC<TabsReportProps> = observer(
  ({ isReportGenerating = false }) => {
    const selectedTab = reportFacade.selectedTab
    const reportText = reportFacade.report.join('')
    const [isCopied, setCopied] = useState(false)

    const copyToClipboard = useClipboard(reportText, () => {
      setCopied(true)
    })

    return (
      <ColDeprecated gap={0} className={cx('Report')}>
        <RowDeprecated align={'between'}>
          <Tabs
            options={tabsOptions}
            value={selectedTab}
            onChange={reportFacade.setSelectedTab}
          />
          {isReportGenerating ? (
            <RowDeprecated gap={1.2}>
              <Typography variant="body1" color="grey2">
                Generating report
              </Typography>
              <Spinner size={'xs'} color={'grey2'} />
            </RowDeprecated>
          ) : (
            <RowDeprecated gap={1}>
              <Tooltip
                disabled={!reportFacade.isRegenerateButtonDisabled}
                className={cx('ReportTooltip')}
                content={regenerateButtonTooltipMessage}
              >
                <div>
                  <Button
                    style={{ width: actionButtonWidth }}
                    disabled={reportFacade.isRegenerateButtonDisabled}
                    onClick={reportFacade.moveToGenerateReportPage}
                    variant="outline"
                    color="secondary"
                    endIcon={'Renew'}
                  >
                    Regenerate
                  </Button>
                </div>
              </Tooltip>

              <Button
                style={{ width: actionButtonWidth, pointerEvents: 'all' }}
                onClick={copyToClipboard}
                variant="solid"
                color="primary"
                endIcon={isCopied ? 'Check' : 'Copy'}
              >
                {isCopied ? 'copied' : 'copy report'}
              </Button>
            </RowDeprecated>
          )}
        </RowDeprecated>
        {selectedTab === 'en' ? (
          <div
            className={cx('ReportContent', { finished: !isReportGenerating })}
          >
            <TypingArea text={reportText} />
          </div>
        ) : (
          <div className={cx('ReportContentComingSoon')}>
            <Typography variant="subheading1" color="grey2" as="div">
              AI Report in {LANGUAGE[selectedTab]} is coming soon.
            </Typography>
            <Typography variant="subheading1" color="grey2" as="div">
              We will notify you on updates.
            </Typography>
          </div>
        )}
      </ColDeprecated>
    )
  }
)
