import { Container } from 'inversify'
import { apiModules, probeGraphModules } from '@platform/di/modules'

const rootContainer = new Container()

rootContainer.load(apiModules)
rootContainer.load(probeGraphModules)

export const getModule = <T>(key: symbol) => {
  return rootContainer.get<T>(key)
}

export { rootContainer }
