import {
  createTokenKey,
  Foggy,
  selectToken,
  Stack,
  StyledProps,
} from '@clain/core/ui-kit'
import { styled } from 'styled-components'

export const ScoreContainer = styled.div`
  flex: 1;
  display: inline-flex;
  position: relative;
`

export const ContractContainer = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  right: -10px;
  padding: 0 2px;
`

export const Contract = styled.div`
  width: 1px;
  height: 16px;
  background-color: ${({ theme }) =>
    selectToken(
      theme,
      createTokenKey(['divider', 'muted', 'background', 'color'])
    )};
`

export const HashContainer = styled.div`
  width: 76%;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 100%;
  padding: 0 ${({ theme }) => `${selectToken(theme, 'paddingXxs')}`};
`

export const ClusterEntityIcon = styled.div<StyledProps<{ url: string }>>`
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
  background: ${({ $url }) => `center / contain no-repeat url(${$url})`};
  border-radius: ${({ theme }) =>
    `${selectToken(theme, createTokenKey(['border', 'radius', 'sm']))}`};
`

export const ClusterNameCell = styled.div`
  flex: 0 1 auto;
  height: 100%;
  align-items: center;
  overflow: hidden;
  display: inline-flex;
`

export const Container = styled(Stack)`
  height: 100%;
`

export const ClusterContainer = styled(Stack).attrs((props) => ({
  ...props,
  align: 'center',
  gap: 'sm',
}))`
  width: 33%;
  height: 100%;
  overflow: hidden;
  padding: 0 ${({ theme }) => selectToken(theme, 'paddingXs')} 0;
`

export const ContentContainer = styled.div`
  flex: 0 1 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
`

export const RightContainer = styled.div`
  position: relative;
  flex: 0 1 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
`

export const FoggyStyled = styled(Foggy)`
  min-width: 60px;
`
