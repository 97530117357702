import { observer } from 'mobx-react-lite'
import { Stack, Button } from '@clain/core/ui-kit'
import { IPlotOnGraph } from '../../types'

export const PlotOnGraphPanel: React.FC<IPlotOnGraph> = observer(
  ({
    disabledPlotOnGraph,
    selectUnSelectAll,
    isAllSelected,
    plotSelectedOnGraph,
  }) => {
    return (
      <Stack align="center" gap="md">
        <Button
          variant="outline"
          color="secondary"
          endIcon="Checks"
          onClick={selectUnSelectAll}
        >
          {isAllSelected ? 'deselect' : 'select'} page
        </Button>
        <Button
          variant="outline"
          color="secondary"
          endIcon="GraphPlot"
          disabled={disabledPlotOnGraph}
          onClick={plotSelectedOnGraph}
        >
          Plot selected
        </Button>
      </Stack>
    )
  }
)
