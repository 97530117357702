import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../../../hooks'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Avatar } from '@clain/core/ui-kit'

import { User } from '../../../../types'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface OpenCaseCardProps {
  date: Date
  user: User
  title: string
  className?: string
}

const OpenCaseCard: React.FC<OpenCaseCardProps> = ({
  date,
  className,
  title,
  user,
}) => {
  const formatDate = useFormatDate()

  return (
    <div className={cx('OpenCaseCard', className)}>
      <ColDeprecated gap={0.5} align="center">
        <Avatar size="lg" name={user.name} src={user.avatar} />
        <Typography variant="body3" color="grey2">
          {user.name}
        </Typography>
      </ColDeprecated>
      <Typography variant="body1" color="grey2">
        <Typography variant="body3">Opened case </Typography>
        labeled as <Typography variant="body3">{title} &#8226; </Typography>
        {formatDate(date, 'time')}
      </Typography>
    </div>
  )
}

export default OpenCaseCard
