import { comparators } from '@clain/core/ui-kit'
import { mergeByKeys } from '@clain/core/utils'
import { isEmpty } from 'ramda'
import { TextFilterMenuListItem } from '../../Filters'
import { Token } from '../../ProbeSandbox/types/converted/TokenBalance'
import { FILTER_VERSION_OPTIONS } from '../constants'
import { convertFromUnixTimestamp } from '@clain/core/utils/date'

export const normalizeTokenToIntern =
  <T>(key: string, data: Token) =>
  (filters: T): T => {
    if (data != null) {
      return mergeByKeys(key, [data], filters)
    }

    return mergeByKeys(key, null, filters)
  }

export const normalizeCalendarToIntern =
  <T>(key: string, data: { from?: number; to?: number }) =>
  (filters: T) => {
    if (data?.from != null || data?.to != null) {
      const date = []

      if (data?.from) date.push(convertFromUnixTimestamp(data.from))
      if (data?.to) date.push(convertFromUnixTimestamp(data.to))

      if (date.length) {
        return mergeByKeys(
          key,
          date.length == 1 ? [date[0], date[0]] : date,
          filters
        )
      }
    }

    return mergeByKeys(key, null, filters)
  }

export const normalizeScoreToIntern =
  <T>(key: string, data: { scoreFrom?: number; scoreTo?: number }) =>
  (filters: T): T => {
    if (data?.scoreFrom != null && data?.scoreTo != null) {
      return mergeByKeys(key, [data?.scoreFrom, data?.scoreTo], filters)
    }

    return mergeByKeys(key, null, filters)
  }

export const normalizeTransferTypeToIntern =
  <T>(key: string, data: 'in' | 'out' | 'both') =>
  (filters: T): T => {
    if (data) {
      return mergeByKeys(key, data, filters)
    }

    return mergeByKeys(key, null, filters)
  }

export const normalizeComparatorsValueIntern =
  <T>(key: string, data: { from?: string; to?: string }) =>
  (filters: T): T => {
    if (data?.from != null && data?.to != null) {
      return mergeByKeys(key, [data.from, data?.to], filters)
    }

    if (data?.from != null || data?.to != null) {
      return mergeByKeys(key, data?.from ? [data.from] : [data.to], filters)
    }

    return mergeByKeys(key, null, filters)
  }

export const normalizeComparatorsConditionIntern =
  <T>(key: string, data: { from?: string; to?: string }) =>
  (filters: T): T => {
    if (data?.from != null && data?.to == null) {
      return mergeByKeys(key, comparators.isGreater.value, filters)
    }

    if (data?.to != null && data?.from == null) {
      return mergeByKeys(key, comparators.isLess.value, filters)
    }

    if (data?.from != null && data?.to != null) {
      return mergeByKeys(
        key,
        +data?.from === +data?.to
          ? comparators.is.value
          : comparators.isBetween.value,
        filters
      )
    }

    return filters
  }

export const normalizeVersionIntern =
  <T>(key: string, data?: number) =>
  (filters: T) => {
    if (data) {
      const selectedValue = FILTER_VERSION_OPTIONS.find(
        ({ value }) => value === data
      )

      if (selectedValue) {
        return mergeByKeys(
          key,
          FILTER_VERSION_OPTIONS.find(({ value }) => value === +data),
          filters
        )
      }
    }

    return filters
  }

export const normalizeIsComparatorIntern =
  <T, R>(key: string, value: R, data?: boolean) =>
  (filters: T): T => {
    if (data != null) {
      return mergeByKeys(
        key,
        { data: value, condition: comparators[data ? 'is' : 'isNot'].value },
        filters
      )
    }

    return filters
  }

export const normalizeTextItemListIntern =
  <T, R extends TextFilterMenuListItem>(key: string, value: R) =>
  (filters: T): T => {
    if (value?.id != null && value?.label) {
      return mergeByKeys(key, [value], filters)
    }

    return mergeByKeys(key, null, filters)
  }

export const normalizeSelectItemIntern =
  <T, R>(key: string, value: R) =>
  (filters: T): T => {
    if (!isEmpty(value) && value != null) {
      return mergeByKeys(key, value, filters)
    }

    return mergeByKeys(key, null, filters)
  }
