import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../../../hooks'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Avatar } from '@clain/core/ui-kit'
import { LightBox } from '@clain/core/ui-kit'
import { FileList, LoadedFileNewFormat } from '@clain/core/ui-kit'
import { EditorView } from '@clain/core/Editor'
import { FilePreviewStub } from '@clain/core/ui-kit'

import { User, File } from '../../../../types'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface ComentCardProps {
  date: Date
  user: User
  text: string
  files?: Array<File>
  className?: string
}

const ComentCard: React.FC<ComentCardProps> = ({
  date,
  className,
  text,
  files,
  user,
}) => {
  const formatDate = useFormatDate()

  const [isActive, setIsActive] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(0)

  const lightBoxItems = files?.map((attachment) => {
    const extArr = attachment.name.split('.')
    const ext = extArr.length > 1 ? extArr[extArr.length - 1] : '?'

    return {
      title: attachment.name,
      content: /^image\/[png|jpg|jpeg]/gi.test(attachment.contentType) ? (
        <img src={attachment.file} />
      ) : (
        <FilePreviewStub ext={ext} />
      ),
      downloadLink: attachment.file,
    }
  })

  const handleClickOnFile = (index: number) => {
    setIsActive(true)
    setActiveIndex(index)
  }

  return (
    <div className={cx('ComentCard', className)}>
      {files?.length > 0 && (
        <LightBox
          isActive={isActive}
          setIsActive={setIsActive}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          items={lightBoxItems}
        />
      )}
      <div className={cx('Heading')}>
        <RowDeprecated gap={0.5}>
          <Avatar name={user.name} src={user.avatar} size="md" />
          <Typography variant="body3" color="grey2">
            {user.name}
          </Typography>
        </RowDeprecated>
        <Typography variant="body1" color="grey4">
          {formatDate(date, 'time')}
        </Typography>
      </div>
      <EditorView value={text} />
      <FileList
        className={cx('Files')}
        files={files as Array<LoadedFileNewFormat>}
        handleClickOnFile={handleClickOnFile}
      />
    </div>
  )
}

export default ComentCard
