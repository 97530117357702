import React, { useEffect } from 'react'
import classnames from 'classnames/bind'
import { startOfDay, endOfDay } from 'date-fns'
import mapValues from 'lodash/mapValues'
import { observer } from 'mobx-react-lite'

import useHttp from '@clain/core/useHttp'
import ActivityChart from '../../../Chart/activity'
import groupSeriesByWeek from '@clain/core/Chart2/groupSeriesByWeek'
import normalizeSeriesDate from '@clain/core/Chart2/normalizeSeriesDate'
import groupSeriesByMonth from '@clain/core/Chart2/groupSeriesByMonth'
import { CheckboxSwitchDouble, Text } from '@clain/core/ui-kit'
import { ZoomRange, ZoomRangeValue } from '@clain/core/ui-kit'
import subtractSeries from '@clain/core/Chart2/subtractSeries'
import filterSeriesByPeriod from '@clain/core/Chart2/filterSeriesByPeriod'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()

import styles from './index.scss'
import { useClusterActivityData } from './useClusterActivityData'
import { Card, RowDeprecated } from '@clain/core/ui-kit'
import { StatusBlock, Stack, ZoomRangeOption } from '@clainio/web-platform'

const cx = classnames.bind(styles)

const RATES_PAIR = {
  btc: 'btc_usd',
  eth: 'eth_usd',
  bnb: 'bnb_usd',
  trx: 'trx_usd',
  doge: 'doge_usd',
  ltc: 'ltc_usd',
}

const groupTypes = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
}

const TextValue = React.forwardRef<HTMLSpanElement, any>(
  ({ label, value }, ref) => (
    <span ref={ref} className={cx('groupBy')}>
      <Text muted className={cx('groupByLabel')}>
        {label}:
      </Text>
      <Text>{value}</Text>
    </span>
  )
)
TextValue.displayName = 'TextValue'
type Row = [number, number]

interface ActivityHttpResponse {
  cluster_growth: Row[]
  deposit_addresses: Row[]
  incoming_transactions: Row[]
  new_deposit_addresses: Row[]
}

const activitySeries = {
  incoming_transactions: 'Incoming transactions',
  deposit_addresses: 'Deposit addresses',
}

const ClusterActivityComponent = () => {
  const {
    blockchain: coin,
    entityId: id,
    statsPeriod: period = [],
  } = useClusterActivityData()

  const { data, isLoading } = useHttp<ActivityHttpResponse>(
    `${config?.PLATFORM_API}/api/private/entities/${coin}/activity/${id}`
  )
  const { data: dataRates } = useHttp(
    `${config?.PLATFORM_API}/api/private/rates/${RATES_PAIR[coin]}`
  )

  const [groupBy] = React.useState<keyof typeof groupTypes>('day')
  const [series, setSeries] = React.useState<keyof typeof activitySeries>(
    'incoming_transactions'
  )
  const [zoomValue, setZoomValue] = React.useState<ZoomRangeValue>(period)
  const [zoomSelected, setZoomSelected] = React.useState<ZoomRangeOption>('All')

  useEffect(() => {
    setZoomValue(period)
  }, [period])

  const updateDataZoom = React.useCallback((start: Date, end: Date) => {
    setZoomValue([startOfDay(start), endOfDay(end)])
  }, [])

  const handleChangeZoom = React.useCallback(
    ([start, end]: ZoomRangeValue, zoomOption: ZoomRangeOption) => {
      setZoomValue([startOfDay(start), endOfDay(end)])
      setZoomSelected(zoomOption)
    },
    []
  )

  const showNoDataStub = !data && !isLoading

  const grouppedData = React.useMemo(() => {
    if (!data || !dataRates) {
      return {}
    }

    const normalized = mapValues(data, normalizeSeriesDate)

    const extrema = normalized.incoming_transactions.reduce(
      (e, row) => {
        // время текущей точки в массиве данных
        const x = row[0]

        if (!e.min) {
          e.min = x
        }

        if (!e.max) {
          e.max = x
        }

        if (e.min > x) {
          e.min = x
        }

        if (e.max < x) {
          e.max = x
        }

        return e
      },
      { min: null, max: null } as { min: number; max: number }
    )

    const normalizedRates = filterSeriesByPeriod(
      // @ts-expect-error
      normalizeSeriesDate(dataRates),
      [extrema.min, extrema.max]
    )

    const day = {
      incoming_transactions: normalized.incoming_transactions,
      old_addresses: subtractSeries(
        normalized.deposit_addresses,
        normalized.new_deposit_addresses,
        'deposit_addresses',
        'new_deposit_addresses'
      ),
      new_addresses: normalized.new_deposit_addresses,
      rates: normalizedRates,
    }

    const week = {
      ...mapValues(day, groupSeriesByWeek),
      rates: groupSeriesByWeek(normalizedRates, 'cumulative'),
    }

    const month = {
      ...mapValues(day, groupSeriesByMonth),
      rates: groupSeriesByMonth(normalizedRates, 'cumulative'),
    }

    return {
      day,
      week,
      month,
    }
  }, [data, dataRates])
  return (
    <Card
      title="Activity"
      headerSlot={
        <RowDeprecated>
          <CheckboxSwitchDouble
            value={series === 'incoming_transactions'}
            onChange={(isActive) =>
              setSeries(
                isActive ? 'incoming_transactions' : 'deposit_addresses'
              )
            }
            leftLabel={activitySeries.deposit_addresses}
            rightLabel={activitySeries.incoming_transactions}
          />
          <span className={cx('delimiter')} />
          <ZoomRange
            selected={zoomSelected}
            value={zoomValue}
            period={period}
            onChange={handleChangeZoom}
          />
        </RowDeprecated>
      }
    >
      {showNoDataStub ? (
        <Stack padding={'xxxxl'}>
          <StatusBlock>
            <StatusBlock.Image type={'data'} />
            <StatusBlock.Title>No Data Available</StatusBlock.Title>
            <StatusBlock.Subtitle>
              We are currently processing the data. Please reload the page
              later.
            </StatusBlock.Subtitle>
          </StatusBlock>
        </Stack>
      ) : (
        <ActivityChart
          zoomSelected={zoomSelected}
          selected={series}
          rateCoin={coin}
          data={grouppedData[groupBy]}
          loading={isLoading}
          min={zoomValue?.[0]}
          max={zoomValue?.[1]}
          groupBy={groupBy}
          updateDataZoom={updateDataZoom}
        />
      )}
    </Card>
  )
}

export const ClusterActivity = observer(ClusterActivityComponent)
