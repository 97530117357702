import { comparators, ContainerSize } from '@clain/core/ui-kit'
import {
  SelectFilterComparatorValue,
  SelectFilterItem,
} from './SelectFilter.types'

export const SELECT_FILTER_OPTIONS_MAP = {
  [comparators.is.value]: comparators.is.text[0],
  [comparators.isNot.value]: comparators.isNot.text[0],
}

export const SELECT_FILTER_OPTIONS: SelectFilterItem<
  Extract<SelectFilterComparatorValue, 'is' | 'isNot'>
>[] = [
  {
    label: SELECT_FILTER_OPTIONS_MAP[comparators.is.value],
    value: comparators.is.value,
    icon: 'Check',
  },
  {
    label: SELECT_FILTER_OPTIONS_MAP[comparators.isNot.value],
    value: comparators.isNot.value,
    icon: 'Blocker',
  },
]

export const CONTAINER_ITEM_SIZE: ContainerSize = 'md'
