import React from 'react'
import classnames from 'classnames/bind'

import styles from '../index.scss'
import { RowDeprecated } from '@clain/core/ui-kit'
import { IconButton, Tooltip } from '@clain/core/ui-kit'
import { PaletteColors } from '../../../PaletteColors'
import { IPaletteController } from '../../../../vm/PaletteController'

const cx = classnames.bind(styles)

interface FlowToolpanelProps
  extends Pick<
    IPaletteController,
    'paintActiveEntities' | 'restoreColorActiveEntities' | 'selectedColor'
  > {
  showMutalFlowIcon?: boolean
  showNetFlowIcon?: boolean
  deleteFlow: () => void
  showMutalFlow: () => void
  showNetFlow: () => void
}

const FlowToolpanel: React.FC<FlowToolpanelProps> = ({
  showMutalFlowIcon,
  showNetFlowIcon,
  deleteFlow,
  showMutalFlow,
  showNetFlow,
  paintActiveEntities,
  restoreColorActiveEntities,
  selectedColor,
}) => (
  <React.Fragment>
    <RowDeprecated className={cx('Container')} direction="column" gap={0.25}>
      <PaletteColors
        value={selectedColor}
        onChange={paintActiveEntities}
        onReset={restoreColorActiveEntities}
      />
    </RowDeprecated>
    {showMutalFlowIcon && (
      <>
        <div className={cx('Delimeter')} />
        <RowDeprecated
          className={cx('Container')}
          direction="column"
          gap={0.25}
        >
          <Tooltip content="Show mutal flow" placement="right">
            <IconButton
              size="md"
              iconVariant="DoubleArrow"
              onClick={showMutalFlow}
            />
          </Tooltip>
        </RowDeprecated>
      </>
    )}
    {showNetFlowIcon && (
      <>
        <div className={cx('Delimeter')} />
        <RowDeprecated
          className={cx('Container')}
          direction="column"
          gap={0.25}
        >
          <Tooltip content="Show netflow" placement="right">
            <IconButton size="md" iconVariant="Net" onClick={showNetFlow} />
          </Tooltip>
        </RowDeprecated>
      </>
    )}
    <div className={cx('Delimeter')} />
    <RowDeprecated className={cx('Container')} direction="column" gap={0.25}>
      <Tooltip content="Remove flow" placement="right">
        <IconButton size="md" iconVariant="Delete" onClick={deleteFlow} />
      </Tooltip>
    </RowDeprecated>
  </React.Fragment>
)

export default FlowToolpanel
