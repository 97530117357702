import React from 'react'
import classnames from 'classnames/bind'

import { Tooltip } from '@clain/core/ui-kit'
import { ToolPanelAddAlert } from '../ToolPanelAddAlert'

import styles from '../index.scss'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Icon, IconButton } from '@clain/core/ui-kit'

const cx = classnames.bind(styles)

interface ClusterToolpanelProps {
  deleteNode: () => void
  isDeleteNodeDisabled?: boolean
}

const ClusterToolpanel: React.FC<ClusterToolpanelProps> = ({
  deleteNode,
  isDeleteNodeDisabled = false,
}) => (
  <React.Fragment>
    <RowDeprecated className={cx('Container')} direction="column" gap={0.25}>
      <IconButton size="md" disabled iconVariant="Connections" />
      <IconButton size="md" disabled iconVariant="AttributeBelongs" />
      <IconButton size="md" disabled iconVariant="Pallete" />
      <ToolPanelAddAlert />
    </RowDeprecated>
    <div className={cx('Delimeter')} />
    <RowDeprecated className={cx('Container')} direction="column" gap={0.25}>
      <Tooltip
        placement="right"
        content={
          isDeleteNodeDisabled
            ? 'Deleting disabled while adding a new object'
            : 'Remove node'
        }
      >
        <IconButton
          size="md"
          disabled={isDeleteNodeDisabled}
          onClick={isDeleteNodeDisabled ? null : deleteNode}
          iconVariant="Delete"
        />
      </Tooltip>
    </RowDeprecated>
  </React.Fragment>
)

export default ClusterToolpanel
