import {
  RowDeprecated,
  Tooltip,
  IconButton,
  BlockchainIcon,
  Icon,
} from '@clain/core/ui-kit'
import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'
import { CoinTypeEVM } from '@platform/types/coin'
import React from 'react'
import { ICurrencyFilter } from '../../types'

export const BlockchainMenu: React.FC<ICurrencyFilter> = ({
  onChangeCurrency,
  disabledCoins,
  coins,
  selectedCoin,
}) => {
  return (
    <RowDeprecated gap={0.25}>
      {coins.map((coin) => (
        <IconButton
          key={coin}
          active={coin === selectedCoin}
          iconOriginal
          onClick={() => onChangeCurrency(coin)}
          customIcon={() => (
            <BlockchainIcon color="original" size="sm" currency={coin} />
          )}
        ></IconButton>
      ))}

      {Object.keys(disabledCoins).map((coin: CoinTypeEVM) => (
        <Tooltip key={coin} content={`${disabledCoins[coin]} coming soon`}>
          <div>
            <IconButton
              disabled
              customIcon={() => (
                <Icon size="sm" variant={capitalizeFirstLetter(coin)} />
              )}
            />
          </div>
        </Tooltip>
      ))}
    </RowDeprecated>
  )
}
