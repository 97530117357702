import React from 'react'
import classnames from 'classnames/bind'

import { MagicGrid } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { ReactComponent as EmailIcon } from '@clain/core/assets/email.svg'
import { Container } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

const ChangePasswordCard = () => (
  <Container className={cx('ChangePasswordCard')} gap={2}>
    <MagicGrid gap={2}>
      <RowDeprecated gap={2}>
        <EmailIcon className={cx('EmailIcon')} />
        <Typography variant="heading4" color="green">
          You have successfully sent a request. Check your email and follow the
          instructions inside to change your password.
        </Typography>
      </RowDeprecated>
      <Typography variant="body1" color="grey1">
        If you heven’t receive an email try the next:
        <ul className={cx('UnorderedList')}>
          <li>Check spam folder</li>
          <li>Click “Change password” and try again</li>
        </ul>
      </Typography>
    </MagicGrid>
  </Container>
)

export default ChangePasswordCard
