import { observer } from 'mobx-react-lite'
import { useAddressesSearchViewModel } from '../../context'
import { CoinType } from '@platform/types/coin'

type TransactionListProps = {
  coin: CoinType
}

export const AddressesList: React.FC<TransactionListProps> = observer(
  ({ coin }) => {
    const {
      selectedTransactions,
      setSelectTransactions,
      tableData: transactionsTableData,
    } = useAddressesSearchViewModel()

    return <>table addresses</>
  }
)
