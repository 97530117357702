import { useEffect } from 'react'
import { DI_TYPES } from '@platform/di/DITypes'
import { getModule } from '@platform/di/rootContainer'
import {
  IPlotEntitiesOnGraph,
  PlotEntitiesData,
  PlotEntitiesOnGraphType,
} from '@platform/modules'

export const usePlotEntitiesOnGraph = <TType extends PlotEntitiesOnGraphType>(
  type: TType,
  entities?: PlotEntitiesData<TType>
) => {
  const plotEntitiesOnGraph = getModule<IPlotEntitiesOnGraph>(
    DI_TYPES.PlotEntitiesOnGraph
  )

  useEffect(() => {
    if (Array.isArray(entities)) {
      plotEntitiesOnGraph.init(type, entities)
    } else if (entities) {
      plotEntitiesOnGraph.init(type, [entities])
    }
  }, [entities])

  useEffect(() => {
    return () => {
      plotEntitiesOnGraph.clear(type)
    }
  }, [])

  return {
    selectedEntities: plotEntitiesOnGraph.selectedEntities(type),
    selectedEntitiesCount: plotEntitiesOnGraph.selectedEntitiesCount(type),
    setSelectEntities: plotEntitiesOnGraph.setSelectEntities(type),
    selectUnSelectAllEntities:
      plotEntitiesOnGraph.selectUnSelectAllEntities(type),
    isAllSelectedEntities: plotEntitiesOnGraph.isAllSelectedEntities(type),
    plotSelectedOnGraph: plotEntitiesOnGraph.plotSelectedOnGraph,
    disabledPlotOnGraph: plotEntitiesOnGraph.disabledPlotOnGraph,
  }
}
