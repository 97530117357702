import React, { useCallback } from 'react'
import { useFilterSlice } from '../ClusterFingerprintFilters/ClusterFingerprintFilters.service'
import { observer } from 'mobx-react-lite'
import TransactionsFlags from '../Chart/TransactionsFlags/TransactionsFlags'
import { Stack, StatusBlock } from '@clainio/web-platform'
import { useTransactionsFlagsViewModel } from '../../modules/analytics/TransactionsFlags/TransactionFlagsData.context'

export const ClusterFingerprintChart = observer(() => {
  const [, { setFilter }] = useFilterSlice('calendar.data')
  const {
    seriesData,
    groupByFilter,
    mockSeriesData,
    calendarFilter,
    isLoading,
    entityId,
    notFound,
  } = useTransactionsFlagsViewModel()
  const handleZoomScaleChange = useCallback((start: Date, end: Date) => {
    setFilter([start, end])
  }, [])
  return notFound ? (
    <Stack padding={'xxxxl'}>
      <StatusBlock>
        <StatusBlock.Image type={'data'} />
        <StatusBlock.Title>No Data Available</StatusBlock.Title>
        <StatusBlock.Subtitle>
          Currently, there are no outgoing transactions for this cluster. This
          section will update automatically once transactions begin to flow.
        </StatusBlock.Subtitle>
      </StatusBlock>
    </Stack>
  ) : (
    <TransactionsFlags
      key={entityId}
      groupBy={groupByFilter}
      series={seriesData}
      mockSeries={mockSeriesData}
      min={calendarFilter?.[0]}
      max={calendarFilter?.[1]}
      updateDataZoom={handleZoomScaleChange}
      isLoading={isLoading}
    />
  )
})
