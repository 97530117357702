import { computed, makeObservable } from 'mobx'

import {
  calcDefaultTokenBalance,
  transformNegativeTokensToZero,
} from '../utils'
import { commonPageData as addressCPD } from '../ClusterViewModels/ApiServicesStateFacade'
import { commonPageData as clusterCPD } from '../AddressViewModels/ApiServicesStateFacade'

import { DataTokensViewModel as AddressDT } from '../ClusterViewModels/DataTokensViewModel'
import { DataTokensViewModel as ClusterDT } from '../AddressViewModels/DataTokensViewModel'

import { DataStatsViewModel as AddressDS } from '../AddressViewModels/DataStatsViewModel'
import { DataStatsViewModel as ClusterDS } from '../ClusterViewModels/DataStatsViewModel'

export class SectionPortfolioViewModel<
  T extends ClusterDS | AddressDS,
  U extends ClusterDT | AddressDT,
  F extends typeof clusterCPD | typeof addressCPD,
> {
  private readonly dataStatsViewModel: T
  private readonly dataTokensViewModel: U
  private commonPageData: F

  constructor(
    dataStatsViewModel: T,
    dataTokensViewModel: U,
    commonPageData: F
  ) {
    makeObservable(this)
    this.dataStatsViewModel = dataStatsViewModel
    this.dataTokensViewModel = dataTokensViewModel
    this.commonPageData = commonPageData
  }

  @computed.struct
  public get data() {
    const assetsTokens = this.dataTokensViewModel.assetsTokens?.map(
      transformNegativeTokensToZero
    )
    const { data: statsData } = this.dataStatsViewModel
    const blockchain = this.commonPageData.state?.blockchain

    if (!statsData || !assetsTokens) return []

    const defaultTokenBalance = calcDefaultTokenBalance(blockchain, statsData)
    const data = [defaultTokenBalance, ...assetsTokens]

    return data.sort((t1, t2) => t2.balanceUsd - t1.balanceUsd)
  }

  @computed
  public get isLoading() {
    const { isLoading: isTokensLoading } = this.dataTokensViewModel
    const { isLoading: isStatsLoading } = this.dataStatsViewModel

    return isTokensLoading || isStatsLoading
  }
}
