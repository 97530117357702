import React from 'react'
import classnames from 'classnames/bind'
import { KeyValue } from '@clain/core/ui-kit'
import { Badge } from '@clain/core/ui-kit'
import { useFormatDate } from '../../hooks'
import { formatNumber, useFormatMoney } from '@clain/core/utils/format'

import styles from './index.scss'
import { CoinTypeEVM } from '../../types/coin'
import { RawBlockTransactionEVM } from '../../apiServices'
import { convertFromUnixTimestamp } from '@clain/core/utils/date'

interface TransactionDetailsEthProps {
  transaction: RawBlockTransactionEVM
  currency: 'src' | 'usd'
  loading: boolean
  coinType: CoinTypeEVM
}

const cx = classnames.bind(styles)

const TransactionDetailsEvm = ({
  transaction,
  currency,
  loading,
  coinType = 'eth',
}: TransactionDetailsEthProps) => {
  const formatMoney = useFormatMoney()

  const formatDate = useFormatDate()

  return (
    <KeyValue
      className={cx('transactionTable')}
      data={transaction}
      loading={loading}
    >
      {[
        {
          key: 'Status',
          value: ({ status }) =>
            status && <Badge variant="success">SUCCESS</Badge>,
        },
        {
          key: 'Block',
          value: ({ height }) => formatNumber(height, 0),
        },
        {
          key: 'Confirmations',
          value: ({ confirmations }) => formatNumber(confirmations, 0),
        },
        {
          key: 'Time',
          value: ({ time }) => formatDate(convertFromUnixTimestamp(time)),
        },
        {
          key: 'Value',
          value: ({ amount_usd, amount }) =>
            formatMoney({
              value: currency === 'usd' ? amount_usd : amount,
              currency: currency === 'src' ? coinType : currency,
            }),
        },
        {
          key: 'Fee',
          value: ({ fee_usd, fee }) =>
            formatMoney({
              value: currency === 'usd' ? fee_usd : fee,
              currency: currency === 'src' ? coinType : currency,
            }),
        },
      ]}
    </KeyValue>
  )
}

export default TransactionDetailsEvm
