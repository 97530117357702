import React, { useState } from 'react'
import {
  Button,
  ColDeprecated,
  Divider,
  TextField,
  ListItem,
  ListItemContainer,
  ListItemContent,
  RadioButton,
  Stack,
  TypographyNew,
  EllipsisTypography,
  usePopoverState,
  ListHeader,
} from '@clain/core/ui-kit'
import {
  ComparatorsValueFilterOptions,
  CONTAINER_ITEM_SIZE,
  TEXT_FIELD_SIZE,
} from './ComparatorsValueFilter.constants'
import {
  ComparatorsValueFilterMenuProps,
  ComparatorsValueFilterValues,
} from './ComparatorsValueFilter.types'
import * as S from './ComparatorsValueFilterMenu.styles'
import { FiltersBarProps } from '../../FiltersBar'
import {
  validateBetweenValues,
  validateValue,
} from './ComparatorsValueFilter.utils'
import { useParsedNumber } from '@clain/core/hooks'

export const ComparatorsValueFilterMenu = ({
  values,
  title,
  selectedCondition,
  onChange,
  size,
  label,
  isDecimalsOnly,
}: ComparatorsValueFilterMenuProps &
  Partial<Pick<FiltersBarProps, 'size'>>) => {
  const options = ComparatorsValueFilterOptions
  const [conditionValue, setConditionValue] = useState(selectedCondition)
  const [error, setError] = useState('')
  const [innerValues, setInnerValues] = useState(
    Array.isArray(values)
      ? values
      : ([null, null] as ComparatorsValueFilterValues)
  )
  const [fromValue, toValue] = innerValues

  const { setOpen } = usePopoverState()

  const handleSetFromValue = useParsedNumber((value: string) => {
    if (conditionValue === 'isBetween') {
      setInnerValues([value, toValue])
    } else {
      setInnerValues([value])
    }
  }, isDecimalsOnly)

  const handleSetToValue = useParsedNumber((value: string) => {
    if (conditionValue === 'isBetween') {
      setInnerValues([fromValue, value])
    } else {
      setInnerValues([value])
    }
  }, isDecimalsOnly)

  const handleSubmit = () => {
    const error = validateValue(innerValues)
    if (error) {
      setError(error)
      return
    }

    if (conditionValue === 'isBetween') {
      const error = validateBetweenValues(innerValues)
      if (error) {
        setError(error)
        return
      }

      onChange({ data: innerValues, condition: conditionValue })
    } else {
      onChange({
        data: [innerValues[0]],
        condition: conditionValue,
      })
    }

    setOpen(false)
  }

  return (
    <div>
      <ListHeader>
        <ListItemContainer size={CONTAINER_ITEM_SIZE}>
          <ListItemContent
            contentScale={size}
            density="sm"
            slots={[
              {
                type: 'text',
                component: ({ variant: [body] }) => (
                  <EllipsisTypography variant={body} color={'onBackgroundBase'}>
                    {title}
                  </EllipsisTypography>
                ),
              },
            ]}
          />
        </ListItemContainer>
      </ListHeader>
      <Divider type="empty" spaces={['xxs', 'none']} />
      <ul>
        {options?.map(({ value, label }) => (
          <ListItem
            key={value}
            onClick={() => setConditionValue(value)}
            size={[CONTAINER_ITEM_SIZE, size]}
            content={({ ...params }) => (
              <ListItemContent
                {...params}
                slots={[
                  {
                    type: 'checkRadio',
                    component: ({ size }) => (
                      <RadioButton
                        size={size}
                        checked={conditionValue === value}
                        onChange={() => setConditionValue(value)}
                      />
                    ),
                  },
                  {
                    type: 'text',
                    component: ({ variant: [body] }) => (
                      <EllipsisTypography
                        variant={body}
                        color={'onBackgroundBase'}
                      >
                        {label}
                      </EllipsisTypography>
                    ),
                  },
                ]}
              />
            )}
          />
        ))}
      </ul>
      <Divider
        type="line"
        color="base"
        size="1"
        spaces={['xs', 'none', 'none']}
      />
      <S.FooterContainer>
        <Stack direction="column" gap="md">
          {error && (
            <S.StyledErrorContainer>
              <TypographyNew variant="body200Normal" color="criticalBase">
                {error}
              </TypographyNew>
            </S.StyledErrorContainer>
          )}
          <Stack gap="md" align="center" padding={['none', 'lg']}>
            <S.StyledCol
              $fullWidth={conditionValue !== 'isBetween'}
              $isLabel={!!label}
            >
              <TextField
                error={!!error}
                size={TEXT_FIELD_SIZE[size]}
                variant="underline"
                value={fromValue}
                minWidth={false}
                onChange={handleSetFromValue}
              />
            </S.StyledCol>
            {conditionValue === 'isBetween' && (
              <>
                <S.DividerCol>
                  <S.StyledDivider>
                    <Divider
                      type="line"
                      color="muted"
                      size="1"
                      spaces={['sm', 'none', 'none']}
                    />
                  </S.StyledDivider>
                </S.DividerCol>
                <S.StyledCol $isLabel={!!label}>
                  <TextField
                    error={!!error}
                    variant="underline"
                    size={TEXT_FIELD_SIZE[size]}
                    value={toValue}
                    minWidth={false}
                    onChange={handleSetToValue}
                  />
                </S.StyledCol>
              </>
            )}
            {label && (
              <S.StyledLabel padding={['sm', 'none', 'none']}>
                <TypographyNew variant="body200Normal" color="onBackgroundBase">
                  {label}
                </TypographyNew>
              </S.StyledLabel>
            )}
          </Stack>
          <ListItemContainer size={CONTAINER_ITEM_SIZE}>
            <ColDeprecated align={'center'} style={{ width: '100%' }}>
              <Button
                variant="outline"
                color="secondary"
                fullWidth
                onClick={handleSubmit}
                size={size}
              >
                APPLY
              </Button>
            </ColDeprecated>
          </ListItemContainer>
        </Stack>
      </S.FooterContainer>
    </div>
  )
}
