import { useFilterSlice } from '../CounterpartyContainerFilters.service'
import { getFilterLabels } from '@clain/core/ui-kit'
import { useCounterpartyStaticStore } from '../../context'
import { enabledEntitiesSearch } from '../../../../../ProbeSandbox/vm/services/SearchService/SearchService.utils'
import { useCallback, useState } from 'react'
import { ClusterSearchResult } from '../../../../../ProbeSandbox/vm/services/SearchService/SearchService.types'
import { TextFilterMenuListItem } from '../../../../../Filters'

export const useClusterFilterData = () => {
  const [data, { setFilter }] = useFilterSlice('cluster.data')
  const [, { resetFilter }] = useFilterSlice('cluster')

  const [condition] = useFilterSlice('cluster.condition')
  const { isMultiple, multipleLabel } = getFilterLabels({
    filterValueCount: data?.length || 0,
    condition,
    pluralLabel: 'Clusters',
  })

  return {
    isMultiple,
    multipleLabel,
    selectedItems: data,
    setFilter,
    resetFilter,
  }
}

export const useClusterSearchData = () => {
  const counterpartyStaticStore = useCounterpartyStaticStore()
  const [searchClusters, setSearchCluster] = useState<TextFilterMenuListItem[]>(
    []
  )

  const getClusters = useCallback(async (query: string) => {
    if (enabledEntitiesSearch(query)) {
      const clusterResults = await counterpartyStaticStore.current
        .getSearchResults(query)
        .then((searchResults) =>
          searchResults.filter(
            ({ type }) => type === 'cluster' || type === 'entity'
          )
        )
        .then((searchResults) =>
          searchResults.flatMap(
            ({ result }) => result as Array<ClusterSearchResult>
          )
        )
        .then((searchResults) =>
          searchResults.filter(
            ({ currency: c }) => c === counterpartyStaticStore.current.coinType
          )
        )

      setSearchCluster(
        clusterResults.map((cluster) => ({
          id: cluster.clusterId,
          score: cluster.score,
          label: cluster.name,
        }))
      )
    }

    if (!query) {
      setSearchCluster([])
    }
  }, [])

  return {
    menuListData: searchClusters,
    onSearch: getClusters,
  }
}

export const useSelectedClusterFilterData = () => {
  const [selectedItems] = useFilterSlice('cluster.data')

  return selectedItems?.length ? selectedItems[0] : null
}
