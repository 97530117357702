import React, { useCallback, useEffect, useRef } from 'react'
import classnames from 'classnames/bind'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'

import useVm from '@clain/core/useVm'

import { useCtx } from '../../../../ctx'

import AlertsStub from './AlertsStub'
import AlertsTable from './AlertsTable'
import AlertEventsTable from './AlertEventsTable'

import styles from './index.scss'
import { Alert, AlertsViewModel } from '../../../../modules'
import { getEventsTabs, selectedDefaultAlertId } from './Alerts.utils'
import { useTimeout } from '@clain/core/hooks'

const cx = classnames.bind(styles)

const Alerts: React.FC = () => {
  const addressWatchId = useRef<null | Alert>(null)
  const alertsVm = useVm(AlertsViewModel, useCtx())
  const alertsCount = alertsVm.alertsCountById

  const handleRemoveAlert = useCallback((index: number) => {
    alertsVm.removeAlert(index)
    alertsVm.updateAlertEventsFilters({
      alertId: selectedDefaultAlertId(
        alertsVm?.alertsWithBaseTokens,
        alertsCount
      ),
    })
  }, [])

  useEffect(() => {
    if (alertsVm.showAlerts) {
      alertsVm.initAlertEvents({
        params: {
          alertId: selectedDefaultAlertId(
            alertsVm?.alertsWithBaseTokens,
            alertsCount
          ),
        },
      })
    }
  }, [alertsVm.showAlerts])

  useEffect(() => {
    if (
      alertsVm.selectedAlert?.addressId &&
      !alertsVm.loadingMainEvents &&
      !alertsVm.loadingEvents
    ) {
      alertsVm.watchAlertEvents({
        currency: alertsVm.selectedAlert.currency,
        addressId: alertsVm.selectedAlert.addressId,
        type: alertsVm.selectedAlert.type,
      })
      addressWatchId.current = alertsVm.selectedAlert
    }

    if (addressWatchId.current) {
      return () => {
        alertsVm.unwatchAlertEvents({
          currency: addressWatchId.current.currency,
          addressId: addressWatchId.current.addressId,
          type: addressWatchId.current.type,
        })
      }
    }
  }, [
    alertsVm.selectedAlert?.addressId,
    alertsVm.loadingMainEvents,
    alertsVm.loadingEvents,
  ])
  const markAlertEventsSeen = () => {
    if (
      alertsVm.selectedAlertCount?.false > 0 &&
      alertsVm.selectedAlert?.id &&
      !alertsVm.loadingMainEvents &&
      !alertsVm.loadingEvents
    ) {
      alertsVm.markAlertEventsSeen({ alertId: alertsVm.selectedAlert.id })
    }
  }
  useTimeout(markAlertEventsSeen, 7000)

  if (alertsVm.showStub) return <AlertsStub text="No any Alerts yet" />

  const selectedAlertId = `${
    toJS(alertsVm.alertEventsFilters)?.alertId ||
    selectedDefaultAlertId(alertsVm?.alertsWithBaseTokens, alertsCount)
  }`

  return (
    <div className={cx('Alerts')}>
      <AlertsTable
        selectedAlertId={selectedAlertId}
        alerts={alertsVm.alertsWithBaseTokens}
        counts={alertsCount}
        toggleAlert={alertsVm.toggleAlert}
        removeAlert={handleRemoveAlert}
        updateFilters={alertsVm.updateAlertEventsFilters}
        loadingMain={alertsVm.loadingMainAlerts}
        loading={alertsVm.loadingAlerts}
      />
      <AlertEventsTable
        tabValue={selectedAlertId}
        tabsOptions={getEventsTabs(alertsVm?.alertsWithBaseTokens)}
        events={alertsVm.events}
        filters={toJS(alertsVm.alertEventsFilters)}
        updateFilters={alertsVm.updateAlertEventsFilters}
        loading={alertsVm.loadingEvents}
        loadingMain={alertsVm.loadingMainEvents}
      />
    </div>
  )
}

export default observer(Alerts)
