import React from 'react'

import { useFilterSlice } from '../TrxFilters.service'

import {
  SelectFilter,
  SelectFilterCondition,
  SelectFilterMenu,
} from '../../../../../Filters'
import { FilterDropdownMenuContainerItem } from '@clainio/web-platform/dist/components/Filters/FilterDropdownMenuContainer/FilterDropdownMenuContainer.types'

import { SELECT_FILTER_OPTIONS } from '../../../../../Filters/SelectFilter/SelectFilter.constants'
import { VERSION_OPTIONS } from '@platform/components/BlockchainFilter/constants'

const ID = 'version'
const LABEL = 'Version'
const TITLE = LABEL

const MenuContainer = () => {
  const [filter, { setFilter }] = useFilterSlice(`${ID}.data`)

  return (
    <SelectFilterMenu
      selected={filter ? filter.value : null}
      title={TITLE}
      onChange={setFilter}
      size={'md'}
      options={VERSION_OPTIONS}
    />
  )
}

const FilterContainer = () => {
  const [, { resetFilter }] = useFilterSlice(ID)
  const [filter] = useFilterSlice(`${ID}.data`)
  const [condition] = useFilterSlice(`${ID}.condition`)

  return (
    <SelectFilter
      selectedCondition={condition}
      value={filter ? filter.label : ''}
      size={'md'}
      label={TITLE}
      clearFilter={resetFilter}
      menuComponent={<MenuContainer />}
      conditionComponent={<FilterConditionContainer />}
    />
  )
}

const FilterConditionContainer = () => {
  const [condition, { setFilter }] = useFilterSlice(`${ID}.condition`)

  return (
    <SelectFilterCondition
      options={SELECT_FILTER_OPTIONS}
      selected={condition}
      onChange={setFilter}
      size={'md'}
    />
  )
}

const MenuItem: FilterDropdownMenuContainerItem = {
  id: ID,
  label: LABEL,
  icon: null,
  isDisabled: false,
  component: MenuContainer,
}

export const VersionFilter = {
  MenuItem: MenuItem,
  Container: FilterContainer,
}
