import {
  ApiServicesStateReactionFacade,
  APIServiceStateFacade,
  APIServiceStateViewModel,
  StateViewModel,
} from '@clain/core/utils/mobxUtils'
import { CoinType } from '../../../types/coin'
import {
  CounterpartiesRequestParams,
  OsintsRequestParams,
  StatsByAddressRequestParams,
  TokenByAddressRequestParams,
  TokensRequestParamsAddress,
  TransactionsRequestParams,
} from '../../ProbeSandbox/types/requestParams/EntityServices'
import { AddressStats } from '../../ProbeSandbox/types/converted/Stats'
import { TransactionsBtcFilters } from '../../ProbeSandbox/types/filters/TransactionsBtcFilters'
import { Tokens } from '../../ProbeSandbox/types/converted/Tokens'
import { ClusterTransactions } from '../../ProbeSandbox/types/converted/ClusterTransactions'
import { GetTokensPayload } from '../../ProbeSandbox/vm/services/EntitiesServices/ClusterService'
import { ClusterCounterparties } from '../../ProbeSandbox/types/converted/ClusterCounterparties'
import { CounterpartiesFilters } from '../../ProbeSandbox/types/filters/CounterpartiesFilters'
import { OsintsFilters } from '../../ProbeSandbox/types/filters/OsintsFilters'
import { Osints } from '../../ProbeSandbox/types/converted/Osints'
import { AddressCounterparties } from '@platform/components/ProbeSandbox/types/converted/AddressCounterparties'
import { Token } from '@platform/components/ProbeSandbox/types/converted/TokenBalance'
import { GetTokenByAddressPayload } from '@platform/components/ProbeSandbox/vm/services/EntitiesServices/ExplorerService'

const statsFacade = new APIServiceStateFacade<
  StatsByAddressRequestParams,
  AddressStats
>(new APIServiceStateViewModel())
const tokensFacade = new APIServiceStateFacade<
  TokensRequestParamsAddress,
  Tokens
>(new APIServiceStateViewModel())
const transactionsFacade = new APIServiceStateFacade<
  TransactionsRequestParams,
  ClusterTransactions
>(new APIServiceStateViewModel())
const counterpartiesFacade = new APIServiceStateFacade<
  CounterpartiesRequestParams,
  ClusterCounterparties | AddressCounterparties
>(new APIServiceStateViewModel())
const osintFacade = new APIServiceStateFacade<OsintsRequestParams, Osints>(
  new APIServiceStateViewModel()
)
const tokenByAddressFacade = new APIServiceStateFacade<
  TokenByAddressRequestParams,
  Token
>(new APIServiceStateViewModel())

const tokensApiParams = new StateViewModel<
  Omit<GetTokensPayload, 'clusterId'>
>()
const transactionsApiParams = new StateViewModel<TransactionsBtcFilters>({})
const counterpartiesApiParams = new StateViewModel<CounterpartiesFilters>({})
const osintApiParams = new StateViewModel<OsintsFilters>({})
const tokenByAddressApiParams = new StateViewModel<
  Partial<GetTokenByAddressPayload>
>({})

export const commonPageData = new StateViewModel<{
  aid: number
  addressHash: string
  blockchain: CoinType
}>({
  aid: null,
  addressHash: '',
  blockchain: null,
})

export const apiServicesStateFacade = new ApiServicesStateReactionFacade({
  apiServiceStates: {
    tokens: tokensFacade,
    stats: statsFacade,
    transactions: transactionsFacade,
    counterparties: counterpartiesFacade,
    osint: osintFacade,
    token: tokenByAddressFacade,
  },
  apiParams: {
    tokens: tokensApiParams,
    transactions: transactionsApiParams,
    counterparties: counterpartiesApiParams,
    osint: osintApiParams,
    token: tokenByAddressApiParams,
  },
})
