import { FilterDropdownMenuContainerTypes } from '@clain/core/ui-kit'
import { AddressComparatorFilterKeys } from './AddressesFilter.types'
import { ComponentFilterKey } from 'packages/core/utils'
import {
  FILTER_BALANCE_KEY,
  FILTER_RECEIVED_AMOUNT_KEY,
} from '@platform/components/BlockchainFilter/constants'
import { ComparatorFilterContainerProps } from '@platform/components/BlockchainFilter/containers'
import { FILTER_ASSET_KEY } from '@platform/components/EntitiesTable/constants'

export const AddressesComparatorFilterPropsById: Record<
  AddressComparatorFilterKeys,
  FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem &
    ComparatorFilterContainerProps &
    ComponentFilterKey<AddressComparatorFilterKeys>
> = {
  [FILTER_RECEIVED_AMOUNT_KEY.root]: {
    id: FILTER_RECEIVED_AMOUNT_KEY.root,
    filterKey: FILTER_RECEIVED_AMOUNT_KEY,
    label: 'Received Amount',
    title: 'Received Amount',
    units: '',
    icon: null,
    isDecimalsOnly: false,
  },
  [FILTER_BALANCE_KEY.root]: {
    id: FILTER_RECEIVED_AMOUNT_KEY.root,
    filterKey: FILTER_BALANCE_KEY,
    label: 'Balance',
    title: 'Balance',
    units: '',
    icon: null,
    isDecimalsOnly: false,
    info: 'Balance that occurred within\nthe selected timeframe.',
  },
}

export const AddressesAssetFilterPropsById: Record<
  typeof FILTER_ASSET_KEY.root,
  FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem
> = {
  [FILTER_ASSET_KEY.root]: {
    id: FILTER_ASSET_KEY.root,
    label: 'Asset',
    icon: null,
  },
}
