import React from 'react'
import { observer } from 'mobx-react-lite'
import { MagicGrid } from '@clain/core/ui-kit'
import NotFound from '@clain/core/NotFound'
import { ClusterDetails } from './components/ClusterDetails'
import { ClusterActivity } from './components/ClusterActivity'
import { ClusterCounterparties } from './components/ClusterCounterparties'
import { CoinType } from '../../types/coin'
import { LoaderOverlay } from '@clain/core/ui-kit'
import { isUTXO } from '@clain/core/types/coin'
import { ClusterEntitiesTable } from './components/ClusterEntitiesTable'
import { useHeaderSlot } from './hooks/useHeaderSlot'
import {
  clusterPageViewModel,
  dataStatsViewModel,
} from '../EntityPage/ClusterViewModels'
import { ClusterFingerprintSection } from './components/ClusterFingerprintSection'
import { ClusterExposure } from './components/ClusterExposure'
import { ClusterFundsFlow } from './components/ClusterFundsFlow'
import useScrollTo from '../../hooks/useScrollTo'
import { ClusterPortfolio } from './components/ClusterPortfolio'
import { useParams } from '@clain/core/Router/router'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'

export const Cluster = observer(() => {
  const { id, coin } = useParams<{ id: string; coin: CoinType }>()
  useHeaderSlot()

  const { init, clear, pageNotFound, isShowTablesBlock, isInitialized } =
    clusterPageViewModel
  const [transactionTableRef, scrollToTransactionTable] =
    useScrollTo<HTMLDivElement>()
  const { isLoading } = dataStatsViewModel

  React.useEffect(() => {
    init(coin, Number(id))
    return () => {
      clear()
    }
  }, [id, coin])

  if (!isInitialized) {
    return null
  }

  if (pageNotFound) {
    return <NotFound />
  }

  return (
    <LoaderOverlay loading={isLoading}>
      <ErrorBoundaryContainer>
        <MagicGrid>
          <ClusterDetails />
          <ClusterPortfolio
            scrollToTransactionTable={scrollToTransactionTable}
          />
          <ClusterExposure />
          <ErrorBoundaryContainer>
            <ClusterActivity />
          </ErrorBoundaryContainer>
          {isUTXO(coin) && <ClusterFingerprintSection />}
          <ClusterFundsFlow />
          <ClusterCounterparties />
          {isShowTablesBlock ? (
            <ClusterEntitiesTable ref={transactionTableRef} />
          ) : null}
        </MagicGrid>
      </ErrorBoundaryContainer>
    </LoaderOverlay>
  )
})
