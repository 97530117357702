import React, { PropsWithChildren } from 'react'
import {
  RowDeprecated,
  FiltersButtons,
  FiltersMenu,
  Stack,
  Tooltip,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import { useSetFilters } from './AddressesFiltersContainer'

import {
  useFiltersActions,
  ADDRESSES_FILTERS_KEYS,
  useFiltersOptions,
} from './AddressesFilters.service'

import { AddressesFiltersMenu } from './AddressesFiltersMenu'
import { FilterLayout } from '@platform/components/BlockchainFilter/ui'
import { VerticalDivider } from '@platform/components/BlockchainFilter/ui/FilterLayout/FilterLayout.styles'
import {
  CalendarFilter,
  CurrencyFilter,
  FiltersBar,
} from '@platform/components/BlockchainFilter/containers'
import { createFilter } from './createFilter.utils'
import { FILTER_CALENDAR_KEY } from '@platform/components/BlockchainFilter/constants'

export const TABLE_FILTER_MENU_MAX_HEIGHT = 473

const ButtonClearFilter = () => {
  const { resetFilters } = useFiltersActions()
  const isFiltersChanged = useFiltersOptions('isFiltersChanged')

  return (
    <Tooltip content={!isFiltersChanged ? 'Set filters first' : ''}>
      <span>
        <FiltersButtons.Clear
          disabled={!isFiltersChanged}
          label="Clear Filters"
          size={'md'}
          onClear={resetFilters}
        />
      </span>
    </Tooltip>
  )
}

const TopSection = () => (
  <Stack justify={'space-between'}>
    <RowDeprecated>
      <CurrencyFilter />
      <VerticalDivider />
      <CalendarFilter filterKey={FILTER_CALENDAR_KEY} />
    </RowDeprecated>
    <RowDeprecated>
      <ButtonClearFilter />
    </RowDeprecated>
  </Stack>
)

const FilterMenuButtons = () => (
  <RowDeprecated>
    <FiltersButtons.Add
      label={'Filters'}
      maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
      size={'md'}
      variant={'text'}
      popupContent={<AddressesFiltersMenu />}
    />
  </RowDeprecated>
)

const FilterBars = () => (
  <FiltersBar
    keys={ADDRESSES_FILTERS_KEYS}
    menu={<AddressesFiltersMenu />}
    createFilter={createFilter}
  />
)

const _AddressesFilterComponent: React.FC<PropsWithChildren> = ({
  children,
}) => {
  useSetFilters()

  return <>{children}</>
}

const AddressesFiltersComponent = () => {
  return (
    <_AddressesFilterComponent>
      <FiltersMenu.Provider initValue={{ size: 'md' }}>
        <FilterLayout
          topSection={<TopSection />}
          middleSection={<FilterMenuButtons />}
          bottomSection={<FilterBars />}
        />
      </FiltersMenu.Provider>
    </_AddressesFilterComponent>
  )
}

export const AddressesFilters = observer(AddressesFiltersComponent)
