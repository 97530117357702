import React from 'react'
import { Modal, Icon } from '@clain/core/ui-kit'
import { SearchEntitiesModal } from './SearchEntitiesModal'
import classnames from 'classnames/bind'
import styles from './SearchEntities.styles.scss'
import { Hotkey } from '@clain/core/ui-kit'
import { Tooltip } from '@clain/core/ui-kit'
import { isMac } from '@clain/core/utils/tools'
import { useLeftSidebarState, useSearchEntitiesState } from '@clain/core/state'
import { RowDeprecated } from '@clain/core/ui-kit'
const cx = classnames.bind(styles)
const modButton = isMac() ? '⌘' : 'Ctrl'

type SearchEntitiesProps = {
  className?: string
  textClassName?: string
  iconClassName?: string
}

export const SearchEntities = React.forwardRef(
  ({ className, iconClassName, textClassName }: SearchEntitiesProps, _ref) => {
    const [isLeftSidebarOpen] = useLeftSidebarState()
    const [isSearchEntitiesModalOpen, setIsSearchEntitiesModalOpen] =
      useSearchEntitiesState()
    return (
      <Modal
        onRequestClose={() => {
          setIsSearchEntitiesModalOpen(false)
        }}
        visible={isSearchEntitiesModalOpen}
        overlayClassName={cx('SearchEntitiesOverlay')}
        preventScroll={true}
        size={'lg'}
        target={() => (
          <Tooltip
            placement="right"
            content={
              <RowDeprecated gap={0.5}>
                Search
                <RowDeprecated gap={0.2}>
                  <Hotkey>{modButton}</Hotkey>
                  <Hotkey>K</Hotkey>
                </RowDeprecated>
              </RowDeprecated>
            }
          >
            <div
              onClick={() => setIsSearchEntitiesModalOpen((isOpen) => !isOpen)}
              className={cx('SearchEntities', className)}
            >
              <div className={cx('SearchEntitiesIconText', iconClassName)}>
                <div>
                  <Icon variant={'Search'} />
                </div>
                {isLeftSidebarOpen ? (
                  <div className={cx(textClassName)}>Search</div>
                ) : null}
              </div>
            </div>
          </Tooltip>
        )}
      >
        {() => <SearchEntitiesModal />}
      </Modal>
    )
  }
)
SearchEntities.displayName = 'SearchEntities'
