import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'

import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Button, RadioButtonGroup } from '@clain/core/ui-kit'
import { CheckboxGroup } from '@clain/core/ui-kit'

import FilterDropdown from '../FilterDropdown'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface TransactionFeaturesFilterValue {
  rbf?: boolean
  segwit?: boolean
  lt?: boolean
  version?: 1 | 2
}

interface TransactionFeaturesFilterProps {
  value: TransactionFeaturesFilterValue
  onChange: (value: TransactionFeaturesFilterValue) => void
  disabled?: boolean
}

const TransactionFeaturesFilter: React.FC<
  PropsWithChildren<TransactionFeaturesFilterProps>
> = ({ value, onChange, children, disabled }) => {
  const radioOptions = [
    {
      value: '',
      label: (
        <Typography variant="body1-sm" color="black">
          All versions
        </Typography>
      ),
    },
    {
      value: '1',
      label: (
        <Typography variant="body1-sm" color="black">
          V1
        </Typography>
      ),
    },
    {
      value: '2',
      label: (
        <Typography variant="body1-sm" color="black">
          V2
        </Typography>
      ),
    },
  ]

  const radioValue = value.version ? `${value.version}` : ''

  const onRadioValueChange = (radioValue: string) => {
    const version = (radioValue ? parseInt(radioValue) : undefined) as 1 | 2

    onChange({ version })
  }

  const checkboxOptions = [
    {
      value: 'segwit',
      label: (
        <Typography variant="body1-sm" color="black">
          SEGWIT
        </Typography>
      ),
    },
    {
      value: 'rbf',
      label: (
        <Typography variant="body1-sm" color="black">
          RBF
        </Typography>
      ),
    },
    {
      value: 'lt',
      label: (
        <Typography variant="body1-sm" color="black">
          LT
        </Typography>
      ),
    },
  ]

  const checkboxValue = [
    value.lt && 'lt',
    value.rbf && 'rbf',
    value.segwit && 'segwit',
  ].filter(Boolean)

  const onCheckboxValueChange = (checkboxValue: Array<string>) => {
    const segwit = checkboxValue.includes('segwit') || undefined
    const rbf = checkboxValue.includes('rbf') || undefined
    const lt = checkboxValue.includes('lt') || undefined

    onChange({ segwit, rbf, lt })
  }

  const onReset = () => {
    onChange({
      rbf: undefined,
      segwit: undefined,
      lt: undefined,
      version: undefined,
    })
  }

  const content = (
    <Container className={cx('TransactionFeaturesFilter')}>
      <ColDeprecated>
        <RadioButtonGroup
          options={radioOptions}
          direction="column"
          value={radioValue}
          onChange={onRadioValueChange}
        />
        <CheckboxGroup
          options={checkboxOptions}
          value={checkboxValue}
          onChange={onCheckboxValueChange}
        />
        <Button size="md" variant="outline" color="secondary" onClick={onReset}>
          reset
        </Button>
      </ColDeprecated>
    </Container>
  )

  return (
    <FilterDropdown content={content} disabled={disabled}>
      {children}
    </FilterDropdown>
  )
}

export default TransactionFeaturesFilter
