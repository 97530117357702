import { ColDeprecated, Icon, Tooltip } from '@clain/core/ui-kit'
import { TransactionInfoProps } from './TransactionInfo.types'

export const TranscationInfo: React.FC<TransactionInfoProps> = ({
  failed = false,
  hasCrossChainSwap = false,
  hasDemix = false,
}) => {
  const enabledContent = failed || hasDemix || hasCrossChainSwap

  return enabledContent ? (
    <ColDeprecated>
      <Tooltip
        content={
          hasDemix
            ? 'AutoDemix Available'
            : hasCrossChainSwap
              ? 'Cross-chain swap'
              : 'Transaction failed'
        }
      >
        <span>
          <Icon
            variant={
              hasDemix ? 'Demix' : hasCrossChainSwap ? 'Bridge' : 'AlertCircle'
            }
            color="criticalHigh"
            size="xxs"
          />
        </span>
      </Tooltip>
    </ColDeprecated>
  ) : null
}
