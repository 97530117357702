import { ColDeprecated, Typography } from '@clainio/web-platform'
import React from 'react'

export const CascadeFilterTooltipContent = () => {
  return (
    <ColDeprecated style={{ maxWidth: 245 }}>
      <Typography variant={'body1-sm'} color={'white'}>
        {`Click "Cascade Filters" to extend the filter set to the "Top
        Counterparties" chart and the table.`}
      </Typography>
      <Typography variant={'body1-sm'} color={'white'}>
        Individual adjustments in each block remain possible.
      </Typography>
    </ColDeprecated>
  )
}
