import React from 'react'
import { BlockchainIcon, CoinType } from '@clain/core/ui-kit'
import { FiltersBar } from '../../FiltersBar'
import { comparators } from '@clain/core/ui-kit'
import { AssetFilterProps } from './AssetFilter.types'
import { FiltersBarProps } from '../../FiltersBar/FilterBar.types'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'

export const AssetFilter = ({
  isMultiple,
  firstSelectedToken,
  multipleLabel,
  menuComponent,
  size = 'md',
  clearFilter,
}: AssetFilterProps) => {
  if (!firstSelectedToken) {
    return null
  }

  const label = isMultiple ? multipleLabel : firstSelectedToken?.symbol
  const getIcon = () =>
    isMultiple ? undefined : (
      <BlockchainIcon
        size={size === 'sm' ? 'xs' : 'sm'}
        icon={firstSelectedToken.icon}
        currency={firstSelectedToken.blockchain.toLowerCase() as CoinType}
        color={isDefaultTokenId(firstSelectedToken?.id) ? 'original' : 'grey'}
      />
    )

  return (
    <FiltersBar
      size={size}
      filterName={{ label: 'Asset', icon: 'Asset' }}
      operator={{ label: comparators.is.text[0] }}
      filterValue={{
        customIcon: () => getIcon(),
        label,
        menuComponent,
      }}
      clearFilter={clearFilter}
    />
  )
}
