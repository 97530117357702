import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import { RowDeprecated } from '@clain/core/ui-kit'
import { CalendarInput } from '@clain/core/ui-kit'
import { Select } from '@clain/core/ui-kit'
import Filters2 from '@clain/core/Filters'
import { ButtonSelect } from '@clain/core/ui-kit'

import styles from './index.scss'
import { CasesFilters } from '../../types'
import { SearchTextField } from './Filters.styles'

const cx = classnames.bind(styles)

const sortingOptions = [
  {
    value: 'recent_update',
    label: 'Recent update first',
  },
  {
    value: 'oldest_update',
    label: 'Oldest update first',
  },
  {
    value: 'newest',
    label: 'Newest first',
  },
  {
    value: 'oldest',
    label: 'Oldest first',
  },
]

const sharedOptions = [
  {
    value: 'my',
    label: 'my cases',
  },
  {
    value: 'shared',
    label: 'shared with me',
  },
]

interface FiltersProps {
  filters: Filters2<CasesFilters>
}

const Filters: React.FC<FiltersProps> = ({ filters }) => {
  return (
    <div className={cx('Filters')}>
      <RowDeprecated>
        <ButtonSelect
          options={sharedOptions}
          value={filters.value('shared')}
          onChange={filters.update('shared')}
          fullWidth={true}
          className={cx('Shared')}
        />
        <RowDeprecated gap={0.5} className={cx('Inputs')}>
          <CalendarInput
            className={cx('Input', 'date')}
            variant="outline"
            label="Open:"
            placeholder="select date"
            value={filters.value('createdOn')}
            onChange={filters.update('createdOn')}
            clearable
          />
          <SearchTextField
            variant="outline"
            startAddon={{ iconVariant: 'Search' }}
            value={filters.value('search')}
            onChange={filters.update('search')}
            placeholder="Find case by name, description or tag"
            clearable
            onClear={filters.reset('search')}
          />
        </RowDeprecated>
      </RowDeprecated>
      <Select
        className={cx('SortingSelect')}
        value={filters.value('sortBy')}
        onChange={filters.update('sortBy')}
        options={sortingOptions}
        sameWidth={false}
      />
    </div>
  )
}

export default observer(Filters)
