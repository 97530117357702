import { BlockchainIcon, RowDeprecated } from '@clain/core/ui-kit'
import React from 'react'
import {
  getAsset,
  getThreshold,
} from '../../../../../../modules/alerts/AlertsViewModel.utils'
import {
  ALERT_RECURRENT_TEXT,
  ALERT_DIRECTION_TEXT,
} from '../../../../../../modules/alerts/converters'
import { InfobarTableData } from '../../InfobarTable'
import { INFO_TABLE_OPTIONS } from './AlertInfobarList.constants'
import { AlertInfobarItem, InfoTableOptionsId } from './AlertInfobarList.types'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'

const Asset: React.FC<AlertInfobarItem> = (data) => (
  <RowDeprecated gap={0.5}>
    <BlockchainIcon
      size="sm"
      icon={data?.asset?.icon}
      currency={data.currency}
      color={isDefaultTokenId(data?.asset?.id) ? 'original' : 'grey'}
    />
    {getAsset(data)}
  </RowDeprecated>
)

export const createTableOptions = (
  data: AlertInfobarItem
): InfobarTableData => {
  return [
    [
      {
        ...INFO_TABLE_OPTIONS[InfoTableOptionsId.Asset],
        value: <Asset {...data} />,
      },
      {
        ...INFO_TABLE_OPTIONS[InfoTableOptionsId.Direction],
        value: ALERT_DIRECTION_TEXT[data.direction],
      },
      {
        ...INFO_TABLE_OPTIONS[InfoTableOptionsId.Threshold],
        value: getThreshold(data),
      },
      {
        ...INFO_TABLE_OPTIONS[InfoTableOptionsId.Frequency],
        value: ALERT_RECURRENT_TEXT[data.recurrent],
      },
    ],
  ]
}
