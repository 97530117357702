import React from 'react'
import {
  Checkbox,
  CopyIcon,
  RowDeprecated,
  TypographyNew,
} from '@clainio/web-platform'
import { CoinType } from '../../../types/coin'
import { useFormatDate } from '@clain/core/utils/date'
import { fromUnixTime } from 'date-fns'
import { Link } from '@clain/core'

interface HeaderLayoutProps {
  isLinkToTransactionDisabled?: boolean
  hash: string
  blockchain: CoinType
  time: number
  children?: React.ReactNode
  memPool?: boolean
  selected?: boolean
  onSelect?: (checked: boolean) => void
}

export const HeaderLayout = ({
  hash,
  blockchain,
  time,
  children,
  isLinkToTransactionDisabled = false,
  memPool,
  selected,
  onSelect,
}: HeaderLayoutProps) => {
  const formatDate = useFormatDate()

  return (
    <RowDeprecated align={'between'}>
      <RowDeprecated gap={0.75}>
        <RowDeprecated gap={0.25}>
          <RowDeprecated gap={0.75}>
            {onSelect && <Checkbox value={selected} onChange={onSelect} />}
            {isLinkToTransactionDisabled ? (
              <TypographyNew
                variant="body100AccentCode"
                color="onBackgroundBase"
              >
                {hash}
              </TypographyNew>
            ) : (
              <Link
                to="/:coin/explorer/transaction/:hash"
                params={{ hash, coin: blockchain }}
              >
                <TypographyNew
                  as="a"
                  variant="body100AccentCode"
                  color="onBackgroundBase"
                >
                  {hash}
                </TypographyNew>
              </Link>
            )}
          </RowDeprecated>
          <CopyIcon value={hash} />
        </RowDeprecated>
        {children}
      </RowDeprecated>
      <TypographyNew variant={'body200Normal'} color={'onBackgroundVariant1'}>
        {memPool ? 'mempool' : formatDate(fromUnixTime(time))}
      </TypographyNew>
    </RowDeprecated>
  )
}
