import { ContainerModule, interfaces } from 'inversify'

import { DI_TYPES } from '@platform/di/DITypes'
import { PlotEntitiesOnGraph, IPlotEntitiesOnGraph } from '@platform/modules'

const initializeModule = (bind: interfaces.Bind) => {
  bind<IPlotEntitiesOnGraph>(DI_TYPES.PlotEntitiesOnGraph)
    .to(PlotEntitiesOnGraph)
    .inSingletonScope()
}

export const probeGraphModules = new ContainerModule(initializeModule)
