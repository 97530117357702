import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import classnames from 'classnames/bind'

import { CounterTabs, CounterTabOption } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import Pagination from '@clain/core/Pagination'

import BottombarTopPanel from '../BottombarTopPanel'

import ProbeVM from '../../../vm/ProbeViewModel'
import { addressKey } from '../../../utils/key'

import styles from './index.scss'
import { AlertEventsTable } from '../tables/AlertEventsTable'
import {
  CounterpartyFilters,
  CounterpartyTable,
  OsintTable,
  TransactionFilters,
  TransactionTable,
  useProbeCounterpartyTableConfig,
  useProbeTransactionsTableConfig,
} from '../../../../EntitiesTable'
import { CoinTypeUTXO } from '../../../../../types/coin'
import {
  useAggregateActionData,
  useAggregateCounterparties,
  useAlerts,
} from '../hooks'
import { BottombarTabActiveTypes } from '../../../vm/ProbeState'
import { TransactionsBtcFilters } from '../../../types/filters/TransactionsBtcFilters'
import { TransactionsEvmFilters } from '../../../types/filters/TransactionsEvmFilters'
import { convertFromUnixTimestamp } from '@clain/core/utils/date'
import { isEVM } from '@clain/core/types'

const cx = classnames.bind(styles)

interface AddressBtcBottombarProps {
  itemsPerPage: number
  caseId?: string
}

const AddressBottombar: React.FC<AddressBtcBottombarProps> = ({
  itemsPerPage,
  caseId,
}) => {
  const activeEntity = ProbeVM.activeEntity.address
  const activeTab = ProbeVM.probeState.bottombarTabActive
  const currency = ProbeVM.activeEntity.currency
  const counterpartyType = isEVM(currency) ? 'address' : 'cluster'

  useEffect(() => {
    ProbeVM.probeState.setBottombarTabActive('counterparties')
  }, [activeEntity.data.addressId])

  const { alertsVm, alertEventsTableData, count, address } = useAlerts(
    currency,
    caseId
  )

  const AddressBottombarTabs = useMemo(() => {
    const tabs: Array<CounterTabOption<BottombarTabActiveTypes>> = [
      {
        value: 'counterparties',
        children: 'counterparties',
        counterValue:
          activeEntity.counterparties?.state?.data?.totalEntries ?? 0,
      },
      {
        value: 'transactions',
        children: 'transactions',
        counterValue: activeEntity.transactions?.state?.data?.totalEntries ?? 0,
      },
      {
        value: 'osint',
        children: 'osint',
        counterValue: activeEntity.osints?.state?.data?.totalEntries ?? 0,
      },
    ]

    if (alertsVm?.events?.initTotalEntries > 0) {
      tabs.push({
        value: 'alertEvents',
        children: 'alert Events',
        counterValue: (count.seen ?? 0) + (count.markedSeen ?? 0),
        counterVariant: count?.seen > 0 ? 'important' : 'default',
      })
    }

    return tabs
  }, [
    activeEntity.counterparties?.state?.data?.totalEntries,
    activeEntity.transactions?.state?.data?.totalEntries,
    activeEntity.osints?.state?.data?.totalEntries,
    alertsVm?.events?.initTotalEntries,
    count.seen,
    count.markedSeen,
  ])

  const counterpartiesTableData = useAggregateCounterparties(
    activeEntity.counterparties?.state?.data?.counterparties,
    currency,
    addressKey(activeEntity.data)
  )

  const transactionsTableData = useAggregateActionData({
    data: activeEntity.transactions?.state?.data?.transactions,
    type: 'transaction',
    selectedType: 'node',
    currency,
  })

  const osintsTableData = useAggregateActionData({
    data: activeEntity.osints?.state?.data?.osints,
    type: 'osint',
    selectedType: 'node',
    currency,
  })

  const page = {
    counterparties: activeEntity.filters.counterparties.filters.page,
    transactions: activeEntity.filters.transactions.filters.page,
    osint: activeEntity.filters.osints.filters.page,
    alertEvents: alertsVm?.events?.pageNumber,
  }[activeTab]

  const setPage = {
    counterparties: (page) =>
      activeEntity.filters.counterparties.updateFilters({ page }),
    transactions: (page) =>
      activeEntity.filters.transactions.updateFilters({ page }),
    osint: (page) => activeEntity.filters.osints.updateFilters({ page }),
    alertEvents: (page) => alertsVm.updateAlertEventsFilters({ page }),
  }[activeTab]

  const totalPages = {
    counterparties: activeEntity.counterparties?.state?.data?.totalPages,
    transactions: activeEntity.transactions?.state?.data?.totalPages,
    osint: activeEntity.osints?.state?.data?.totalPages,
    alertEvents: alertsVm?.events?.totalPages,
  }[activeTab]

  const transactionsFilters = toJS(activeEntity.filters.transactions.filters)
  const counterpartiesFilters = toJS(
    activeEntity.filters.counterparties.filters
  )
  const osintsFilters = toJS(activeEntity.filters.osints.filters)

  const goToTransactions = (
    params: TransactionsBtcFilters | TransactionsEvmFilters
  ) => {
    activeEntity.filters.transactions.updateFilters(params)
    ProbeVM.probeState.setBottombarTabActive('transactions')
  }

  const config = useProbeTransactionsTableConfig({
    data: transactionsTableData,
    coinType: currency,
    cumulative: activeEntity.transactions?.state?.cumulative,
    setSelected: activeEntity.toggleTransaction,
    setAllSelected: activeEntity.toggleAllTransactions,
    filters: transactionsFilters,
    setFilters: activeEntity.filters.transactions.updateFilters,
    isLoading: activeEntity.transactions.loading,
    getSearchResults: ProbeVM.searchService.getBlockchainResults,
    getSearchClusters: ProbeVM.searchService.getEntitiesResults,
    showInUSD: ProbeVM.isUsdCurrency,
    allowedRange: [
      convertFromUnixTimestamp(activeEntity.data?.firstSeen),
      convertFromUnixTimestamp(activeEntity.data?.lastSeen),
    ],
  })

  const counterpartyConfig = useProbeCounterpartyTableConfig({
    data: counterpartiesTableData,
    counterpartyType,
    coinType: currency,
    cumulative: activeEntity.counterparties.state?.cumulative,
    categories: activeEntity.counterparties.state?.categories,
    setSelected: activeEntity.toggleCounterparty,
    setAllSelected: activeEntity.toggleAllCounterparties,
    setInflowSelected: activeEntity.toggleCounterpartyInflow,
    setOutflowSelected: activeEntity.toggleCounterpartyOutflow,
    setNetflowSelected: activeEntity.toggleCounterpartyNetflow,
    filters: counterpartiesFilters,
    setFilters: activeEntity.filters.counterparties.updateFilters,
    isLoading: activeEntity.counterparties.loading,
    getSearchResults: ProbeVM.searchService.getEntitiesResults,
    goToTransactions: goToTransactions,
    showInUSD: ProbeVM.isUsdCurrency,
    allowedRange: [
      convertFromUnixTimestamp(activeEntity.data?.firstSeen),
      convertFromUnixTimestamp(activeEntity.data?.lastSeen),
    ],
  })

  return (
    <div className={cx('AddressBtcBottombar')}>
      <BottombarTopPanel>
        <RowDeprecated className={cx('TopPanelContent')} align="between">
          <CounterTabs
            options={AddressBottombarTabs}
            value={activeTab}
            onChange={(e) => {
              ProbeVM.probeState.setBottombarTabActive(e)
            }}
            className={cx('CounterTabs')}
            size="sm"
          />
          <Pagination
            value={page}
            totalPages={totalPages}
            onChange={setPage}
            className={cx('Pagination', {
              visible: ProbeVM.probeState.bottombarStatus !== 'hidden',
            })}
            size="md"
          />
        </RowDeprecated>
      </BottombarTopPanel>
      {activeTab === 'counterparties' && (
        <CounterpartyTable
          filtersContent={
            <CounterpartyFilters
              counterpartyType={counterpartyType}
              categories={activeEntity.counterparties.state?.categories}
              filters={counterpartiesFilters}
              initialFilters={toJS(
                activeEntity.filters.counterparties.initialFilters
              )}
              defaultFilters={toJS(
                activeEntity.filters.counterparties.defaultFilters
              )}
              excludeFilters={activeEntity.excludeFilters}
              filterSize="sm"
              getSearchResults={
                counterpartyType === 'cluster'
                  ? ProbeVM.searchService.getEntitiesResults
                  : ProbeVM.searchService.getBlockchainResults
              }
              tokens={activeEntity.tokensWithoutAggregated}
              allowedRange={[
                convertFromUnixTimestamp(activeEntity.data?.firstSeen),
                convertFromUnixTimestamp(activeEntity.data?.lastSeen),
              ]}
              coinType={currency}
              setFilters={activeEntity.filters.counterparties.updateFilters}
            />
          }
          data={counterpartiesTableData}
          isLoading={activeEntity.counterparties.loading}
          itemsPerPage={itemsPerPage}
          config={counterpartyConfig}
        />
      )}
      {activeTab === 'transactions' && (
        <TransactionTable
          filtersContent={
            <TransactionFilters
              counterpartyType={counterpartyType}
              filterType="default"
              currency={currency}
              filters={transactionsFilters}
              initialFilters={toJS(
                activeEntity.filters.transactions.initialFilters
              )}
              excludeFilters={activeEntity.excludeFilters}
              defaultFilters={toJS(
                activeEntity.filters.transactions.defaultFilters
              )}
              tokens={activeEntity.transactionTokens}
              disabledAssetStaticSearch={
                activeEntity.disabledTransactionAssetStaticSearch
              }
              onSearchAsset={activeEntity.setTokenByAddress}
              searchAssetLoading={activeEntity.tokenByAddressLoading}
              filterSize="sm"
              getSearchResults={
                counterpartyType === 'cluster'
                  ? ProbeVM.searchService.getEntitiesResults
                  : ProbeVM.searchService.getBlockchainResults
              }
              allowedRange={[
                convertFromUnixTimestamp(activeEntity.data?.firstSeen),
                convertFromUnixTimestamp(activeEntity.data?.lastSeen),
              ]}
              setFilters={activeEntity.filters.transactions.updateFilters}
            />
          }
          data={transactionsTableData}
          isLoading={activeEntity.transactions.loading}
          itemsPerPage={itemsPerPage}
          config={config}
          coinType={currency}
        />
      )}
      {activeTab === 'osint' && (
        <OsintTable
          data={osintsTableData}
          setSelected={activeEntity.toggleOsint}
          setAllSelected={activeEntity.toggleAllOsints}
          filters={osintsFilters}
          setFilters={activeEntity.filters.osints.updateFilters}
          isLoading={activeEntity.osints.loading}
          itemsPerPage={itemsPerPage}
          isVisitedLink={activeEntity.isVisitedLink}
          visitedLinkAdd={activeEntity.visitedLinkAdd}
          currency={currency}
        />
      )}
      {activeTab === 'alertEvents' && (
        <AlertEventsTable
          data={alertEventsTableData}
          filters={toJS(alertsVm.alertEventsFilters)}
          updateFilters={alertsVm.updateAlertEventsFilters}
          setSelected={(data) =>
            activeEntity.toggleTransaction(
              {
                ...data,
                id: data.transactionId,
                currency: activeEntity?.data?.currency as CoinTypeUTXO,
                inputs: [],
                outputs: [],
              },
              data.selected
            )
          }
          setAllSelected={(list, select) =>
            activeEntity.toggleAllTransactions(
              list.map((data) => ({
                ...data,
                id: data.transactionId,
                currency: activeEntity?.data?.currency as CoinTypeUTXO,
                inputs: [],
                outputs: [],
              })),
              select
            )
          }
          isLoading={activeEntity.transactions.loading}
          itemsPerPage={itemsPerPage}
          currency={currency}
          address={address}
        />
      )}
    </div>
  )
}

export default observer(AddressBottombar)
