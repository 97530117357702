import { observable, action, computed, makeObservable, toJS } from 'mobx'
import {
  BlockTransactionEVM,
  BlockTransactionUTXO,
  BlockDataTransaction,
} from '@platform/apiServices'
import { BlocksHeightState } from '@platform/states/BlocksHeightState'
import {
  PlotEntitiesController,
  plotEntitiesController,
} from '@platform/components/ProbeSandbox/vm/PlotEntitiesController'
import { ctx } from '@platform/ctx'
import { createProbePath } from '@platform/constants'
import {
  LiteTransactionAddressUtxo,
  EventCluster,
  EventTransactionEVM,
  EventTransactionUTXO,
  EventClusterBasePayload,
  EventAddressCounterpartyPayload,
  EventAddress,
} from '@clain/graph-entities'
import { isUTXO } from '@clain/core/types'
import { IPlotEntitiesOnGraph } from './PlotEntitiesOnGraph.types'
import { injectable } from 'inversify'

@injectable()
export class PlotEntitiesOnGraph<
  TTransaction extends BlockDataTransaction = BlockDataTransaction,
  TCluster extends EventClusterBasePayload = EventClusterBasePayload,
  TAddresses extends
    EventAddressCounterpartyPayload = EventAddressCounterpartyPayload,
> implements IPlotEntitiesOnGraph<TTransaction, TCluster>
{
  @observable private _selectedTransactions: TTransaction[] = []
  @observable private transactions: TTransaction[] = []

  @observable private _selectedClusters: TCluster[] = []
  @observable private clusters: TCluster[] = []

  @observable private _selectedAddresses: TAddresses[] = []
  @observable private addresses: TAddresses[] = []

  private blocksHeightState: BlocksHeightState
  private plotEntitiesController: PlotEntitiesController

  constructor() {
    makeObservable(this)
    this.blocksHeightState = ctx.blocksHeightState
    this.plotEntitiesController = plotEntitiesController
  }

  @action
  public init: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster,
    TAddresses
  >['init'] = (type, data) => {
    if (type === 'clusters') {
      this.clusters = data as TCluster[]
    } else if (type === 'addresses') {
      this.addresses = data as TAddresses[]
    } else if (type === 'transactions') {
      this.transactions = data as TTransaction[]
    }
  }

  // Clusters

  @computed
  private get selectedClusters() {
    return this._selectedClusters
  }

  @computed
  private get selectedClustersCount() {
    return this._selectedClusters.length
  }

  @action
  private setSelectClusters = (data: TCluster, checked: boolean) => {
    if (checked) {
      this._selectedClusters = [...this._selectedClusters, data]
    } else {
      this._selectedClusters = this._selectedClusters.filter(
        (cluster) => cluster.clusterId !== data.clusterId
      )
    }
  }

  @action
  private resetSelectedClusters = () => {
    this._selectedClusters = []
  }

  @action
  private selectUnSelectAllClusters = () => {
    if (this.isAllSelectedClusters) {
      this.resetSelectedClusters()
      return
    }

    this._selectedClusters = this.clusters
  }

  @computed
  private get isAllSelectedClusters() {
    if (!this.clusters?.length) return false

    return this.selectedClusters.length === this.clusters.length
  }

  // Addresses
  @computed
  private get selectedAddresses() {
    return this._selectedClusters
  }

  @computed
  private get selectedAddressesCount() {
    return this._selectedAddresses.length
  }

  @action
  private setSelectAddresses = (data: TAddresses, checked: boolean) => {
    if (checked) {
      this._selectedAddresses = [...this._selectedAddresses, data]
    } else {
      this._selectedAddresses = this._selectedAddresses.filter(
        (cluster) => cluster.clusterId !== data.clusterId
      )
    }
  }

  @action
  private resetSelectedAddresses = () => {
    this._selectedClusters = []
  }

  @action
  private selectUnSelectAllAddresses = () => {
    if (this.isAllSelectedClusters) {
      this.resetSelectedClusters()
      return
    }

    this._selectedAddresses = this.addresses
  }

  @computed
  private get isAllSelectedAddresses() {
    if (!this.clusters?.length) return false

    return this.selectedAddresses.length === this.clusters.length
  }

  // Transactions
  @computed
  private get selectedTransactions() {
    return this._selectedTransactions
  }

  @computed
  private get selectedTransactionsCount() {
    return this._selectedTransactions.length
  }

  @action
  private setSelectTransactions = (data: TTransaction, checked: boolean) => {
    if (checked) {
      this._selectedTransactions = [...this._selectedTransactions, data]
    } else {
      this._selectedTransactions = this._selectedTransactions.filter(
        (transaction) => transaction.id !== data.id
      )
    }
  }

  @action
  private resetSelectedTransactions = () => {
    this._selectedTransactions = []
  }

  @action
  private selectUnSelectAllTransactions = () => {
    if (this.isAllSelectedTransactions) {
      this.resetSelectedTransactions()
      return
    }

    this._selectedTransactions = this.transactions
  }

  @computed
  private get isAllSelectedTransactions() {
    if (!this.transactions?.length) return false

    return this.selectedTransactions.length === this.transactions.length
  }

  public selectedEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['selectedEntities'] = (type) => {
    if (type === 'clusters') {
      return this.selectedClusters
    } else if (type === 'addresses') {
      return this.selectedAddresses
    } else {
      return this._selectedTransactions as any
    }
  }

  public selectedEntitiesCount: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['selectedEntitiesCount'] = (type) => {
    if (type === 'clusters') {
      return this.selectedClustersCount
    } else if (type === 'addresses') {
      return this.selectedAddressesCount
    } else {
      return this.selectedTransactionsCount
    }
  }

  @action
  public setSelectEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['setSelectEntities'] = (type) => {
    if (type === 'clusters') {
      return this.setSelectClusters as any
    } else if (type === 'addresses') {
      return this.setSelectAddresses as any
    } else {
      return this.setSelectTransactions as any
    }
  }

  public selectUnSelectAllEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['selectUnSelectAllEntities'] = (type) => {
    if (type === 'clusters') {
      return this.selectUnSelectAllClusters
    } else if (type === 'addresses') {
      return this.selectUnSelectAllAddresses
    } else if (type === 'transactions') {
      return this.selectUnSelectAllTransactions
    }
  }

  public isAllSelectedEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['isAllSelectedEntities'] = (type) => {
    if (type === 'clusters') {
      return this.isAllSelectedClusters
    } else if (type === 'addresses') {
      return this.isAllSelectedAddresses
    } else if (type === 'transactions') {
      return this.isAllSelectedTransactions
    }
  }

  @computed.struct
  public get disabledPlotOnGraph() {
    return (
      !this._selectedTransactions.length &&
      !this._selectedClusters.length &&
      !this._selectedAddresses.length
    )
  }

  @action
  public plotSelectedOnGraph = () => {
    if (!this.disabledPlotOnGraph) {
      const normalizedAddTransactions = this._selectedTransactions.map(
        (_transaction) => {
          if (isUTXO(_transaction.currency)) {
            const transaction = _transaction as unknown as BlockTransactionUTXO

            return {
              createBy: 'by-trx-id',
              strategy: 'transaction',
              currency: transaction.currency,
              id: transaction.id,
              hash: transaction.hash,
              direction: 'out',
              inputs:
                transaction.inputs as unknown as LiteTransactionAddressUtxo[],
              outputs:
                transaction.outputs as unknown as LiteTransactionAddressUtxo[],
            } satisfies EventTransactionUTXO
          } else {
            const transaction = _transaction as BlockTransactionEVM

            return {
              strategy: 'transaction',
              type: 'transfer',
              currency: transaction.currency,
              index: 0,
              from: {
                hash: transaction.transfers[0]?.sender.address,
                id: transaction.transfers[0]?.sender.addressId,
                clusterId: transaction.transfers[0]?.sender.clusterId,
              },
              to: {
                hash: transaction.transfers[0]?.receiver.address,
                id: transaction.transfers[0]?.receiver.addressId,
                clusterId: transaction.transfers[0]?.receiver.clusterId,
              },
              hash: transaction.hash,
              id: transaction.id,
            } satisfies EventTransactionEVM
          }
        }
      )

      const normalizeAddClusters = this._selectedClusters.map(
        (cluster) =>
          ({
            strategy: 'cluster',
            ...cluster,
          }) satisfies EventCluster
      )

      const normalizeAddAddresses = this._selectedAddresses.map(
        (address) =>
          ({
            strategy: 'address-cluster',
            ...address,
          }) satisfies EventAddress
      )

      this.plotEntitiesOnGraph([
        ...normalizeAddClusters,
        ...normalizeAddAddresses,
        ...normalizedAddTransactions,
      ])
    }
  }

  @action
  private plotEntitiesOnGraph = (
    entities: Parameters<typeof this.plotEntitiesController.pushPlotEntities>[1]
  ) => {
    this.blocksHeightState.getNewProbe().then((probe) => {
      window.open(createProbePath(probe.id), '_blank')
      this.plotEntitiesController.pushPlotEntities(probe.id, entities)
    })
  }

  @action
  public clear: IPlotEntitiesOnGraph<TTransaction, TCluster>['clear'] = (
    type
  ) => {
    if (type === 'clusters') {
      this.resetSelectedClusters()
      this.clusters = []
    } else if (type === 'transactions') {
      this.resetSelectedTransactions()
      //this.transactions = []
    }
  }
}
