import { BaseLink } from '@clain/core/Link'
import {
  CopyIcon,
  EllipsisTypography,
  EllipsisTypographyBlock,
  Score,
  Stack,
  Tooltip,
} from '@clain/core/ui-kit'
import * as S from './ContentCounterparty.styles'
import { ContentCounterpartyProps } from './ContentCounterparty.types'
import { FoggyStyled } from './ContentCounterparty.styles'

export const ContentCounterparty: React.FC<ContentCounterpartyProps> = ({
  score,
  clusterName,
  address,
  currency,
  clusterId,
  contract,
  entityIcon,
}) => {
  return (
    <S.Container gap="md" align="center">
      <S.ScoreContainer>
        <Score value={score} size="xs" />
        {contract && (
          <Tooltip content="Contract Account">
            <S.ContractContainer>
              <S.Contract />
            </S.ContractContainer>
          </Tooltip>
        )}
      </S.ScoreContainer>
      <S.RightContainer>
        <S.ContentContainer>
          <S.HashContainer>
            <EllipsisTypographyBlock as={Stack} fullWidth>
              <FoggyStyled color="base" content={<CopyIcon value={address} />}>
                <Tooltip content={address}>
                  <div style={{ width: '100%', display: 'flex' }}>
                    <EllipsisTypography
                      as={BaseLink}
                      to="/:coin/address/:address"
                      params={{ address, coin: currency }}
                      target="_blank"
                      variantAs="link"
                      variant="body200NormalCode"
                      color="onBackgroundBase"
                      type="center"
                    >
                      {address}
                    </EllipsisTypography>
                  </div>
                </Tooltip>
              </FoggyStyled>
            </EllipsisTypographyBlock>
          </S.HashContainer>
          <S.ClusterContainer>
            {entityIcon && <S.ClusterEntityIcon $url={entityIcon} />}
            <EllipsisTypographyBlock as={S.ClusterNameCell}>
              <FoggyStyled
                color="base"
                content={<CopyIcon value={clusterName} />}
              >
                <Tooltip content={clusterName}>
                  <div style={{ width: '100%', display: 'flex' }}>
                    <EllipsisTypography
                      as={BaseLink}
                      to="/:coin/cluster/:rootId"
                      params={{ rootId: clusterId, coin: currency }}
                      target="_blank"
                      variantAs="link"
                      variant="body200NormalCode"
                      color="onBackgroundVariant1"
                      type="end"
                    >
                      {clusterName}
                    </EllipsisTypography>
                  </div>
                </Tooltip>
              </FoggyStyled>
            </EllipsisTypographyBlock>
          </S.ClusterContainer>
        </S.ContentContainer>
      </S.RightContainer>
    </S.Container>
  )
}

ContentCounterparty.displayName = 'ContentCounterparty'
