import { TagType } from '../TransactionBlock.types'
import { getFeatureTagColor } from '../../../utils/getFeatureTagColor'
import {
  Address,
  ExtendedAddress,
  TransactionBlockItemUTXO,
  TransactionItemProps,
} from './TransactionBlockUTXO.types'
import { path } from 'ramda'
import { useMemo } from 'react'
import {
  BlockDataTransactionUTXO,
  BlockDataTransactionUTXOTags,
} from '../../../apiServices'

export const getTagLabelList = (transaction: BlockDataTransactionUTXO) => {
  const lockTimeLabel = getLockTimeLabel(transaction.lockTime)

  return [
    transaction.segwit && 'SW',
    transaction.rbf && 'RBF',
    lockTimeLabel,
    transaction.version && `V${transaction.version}`,
  ]
    .filter(Boolean)
    .map((t) =>
      typeof t === 'string' ? { label: t, color: getFeatureTagColor(t) } : t
    ) as TagType[]
}

const getLockTimeLabel = (lockTime: number | undefined) => {
  if (!lockTime) {
    return null
  }

  const isUnixTimestamp = lockTime >= 500_000_000

  return {
    label: `LT:${isUnixTimestamp ? 'T' : 'H'}`,
    color: getFeatureTagColor('LT'),
    tooltip: isUnixTimestamp
      ? `LockTime Timestamp: ${lockTime}`
      : `LockTime Height: ${lockTime}`,
  }
}

export function isInputTransaction<T extends BlockDataTransactionUTXO>(
  transaction: Partial<TransactionItemProps>
): transaction is T['inputs'][number] {
  return 'inputId' in transaction
}

export function isOutputTransaction<T extends BlockDataTransactionUTXO>(
  transaction: Partial<TransactionItemProps>
): transaction is T['outputs'][number] {
  return 'outputId' in transaction
}

const generateFeatureTagsTR = (
  type?: TransactionBlockItemUTXO['type'],
  taprootFp?: BlockDataTransactionUTXOTags['taprootFp']
) => {
  let tag =
    taprootFp?.taprootType ||
    taprootFp?.merkleLength ||
    taprootFp?.controlBlockVersion ||
    type === 'TR'
      ? 'TR'
      : ''
  let tooltip = 'Taproot'

  if (!taprootFp) {
    return (
      tag && {
        label: tag,
        color: getFeatureTagColor(tag),
        tooltip: tooltip,
      }
    )
  }

  if (taprootFp.taprootType === 'key_hash') {
    tag = `${tag}:KH`
    tooltip = `${tooltip}\nKey Hash`
  } else if (taprootFp.taprootType === 'script_hash') {
    tag = `${tag}:SH`
    tooltip = `${tooltip}\nScript Hash`
  }

  if (taprootFp.merkleLength !== null) {
    tag = `${tag}:ML${taprootFp.merkleLength}`
    tooltip = `${tooltip}\nMerkle Length ${taprootFp.merkleLength}`
  }

  if (tag) {
    return {
      label: tag,
      color: getFeatureTagColor(tag),
      tooltip: tooltip,
    }
  }

  return tag
}

const generateFeatureTagsFlags = (
  flags: BlockDataTransactionUTXOTags['flags']
) => {
  const tags: string[] = []

  flags.forEach((flag) => {
    if (flag) {
      tags.push(`T:${`${flag}`.toUpperCase()}`)
    }
  })

  return tags
}

const generateFeatureTagsSighash = (
  sighash: BlockDataTransactionUTXOTags['sighash']
) => {
  const tags: TagType[] = []
  const prefix = 'SH_'

  if (sighash === 'all') {
    tags.push({
      label: `${prefix}ALL`,
      color: getFeatureTagColor(`${prefix}ALL`),
      tooltip: 'SIGHASH_ALL',
    })
  } else if (sighash === 'none') {
    tags.push({
      label: `${prefix}NONE`,
      color: getFeatureTagColor(`${prefix}NONE`),
      tooltip: 'SIGHASH_NONE',
    })
  } else if (sighash === 'single') {
    tags.push({
      label: `${prefix}SINGLE`,
      color: getFeatureTagColor(`${prefix}SINGLE`),
      tooltip: 'SIGHASH_SINGLE',
    })
  } else if (sighash === 'single_or_anyonecanpay') {
    tags.push({
      label: `${prefix}SINGLE|ANYPAY`,
      color: getFeatureTagColor(`${prefix}SINGLE|ANYPAY`),
      tooltip: 'SIGHASH_SINGLE | SIGHASH_ANYONECANPAY',
    })
  } else if (sighash === 'none_or_anyonecanpay') {
    tags.push({
      label: `${prefix}NONE|ANYPAY`,
      color: getFeatureTagColor(`${prefix}NONE|ANYPAY`),
      tooltip: 'SIGHASH_NONE | SIGHASH_ANYONECANPAY',
    })
  } else if (sighash === 'all_or_anyonecanpay') {
    tags.push({
      label: `${prefix}ALL|ANYPAY`,
      color: getFeatureTagColor(`${prefix}ALL|ANYPAY`),
      tooltip: 'SIGHASH_ALL | SIGHASH_ANYONECANPAY',
    })
  } else if (sighash === 'anyonecanspend') {
    tags.push({
      label: `${prefix}ANYSPEND`,
      color: getFeatureTagColor(`${prefix}ANYSPEND`),
      tooltip: 'SIGHASH_ANYONECANSPEND',
    })
  }

  return tags
}

export const generateFeatureTags = (
  transaction: TransactionBlockItemUTXO & {
    lockTime?: number
    version?: number
  }
) => {
  if (isInputTransaction(transaction)) {
    const lockTimeLabel = getLockTimeLabel(transaction.lockTime)
    const tags: (string | TagType)[] = [
      transaction.type !== 'TR' && transaction.type,
      generateFeatureTagsTR(transaction.type, transaction.taprootFp),
      transaction.data && 'DATA',
      transaction.multisigType &&
        `${transaction.multisigType?.[0]}/${transaction.multisigType?.[1]}`,
      transaction.segwit && 'SW',
      transaction.rbf && 'RBF',
      lockTimeLabel,
      transaction.version && `V${transaction.version}`,
      transaction.compressed === false &&
        ['PK', 'PKH'].includes(transaction.type) && {
          label: 'UCP',
          color: getFeatureTagColor('UCP'),
          tooltip: 'Uncompressed',
        },
      transaction.compressed === true &&
        ['PK', 'PKH'].includes(transaction.type) && {
          label: 'CP',
          color: getFeatureTagColor('CP'),
          tooltip: 'Сompressed',
        },
      transaction?.nonStandard && {
        label: 'NS',
        color: getFeatureTagColor('NS'),
        tooltip: 'Non-standard',
      },
      ...(Array.isArray(transaction.flags)
        ? generateFeatureTagsFlags(transaction.flags)
        : []),
      ...(transaction?.sighash
        ? generateFeatureTagsSighash(transaction.sighash)
        : []),
    ]

    return tags
      .filter(Boolean)
      .map((t) =>
        typeof t === 'string' ? { label: t, color: getFeatureTagColor(t) } : t
      ) as TagType[]
  }

  if (isOutputTransaction(transaction)) {
    const lockTime = transaction.next?.lockTime || transaction.next?.trxLocktime
    const version = transaction.next?.version || transaction.next?.trxVersion
    const lockTimeLabel = getLockTimeLabel(lockTime)
    return [
      transaction.type !== 'TR' && transaction.type,
      generateFeatureTagsTR(transaction.type, transaction.next?.taprootFp),
      transaction.next?.data && 'DATA',
      transaction.next?.multisigType &&
        `${transaction.next?.multisigType?.[0]}/${transaction.next?.multisigType?.[1]}`,
      transaction.next?.segwit && 'SW',
      transaction.next?.rbf && 'RBF',
      lockTimeLabel,
      version && `V${version}`,
      transaction.next?.compressed === false &&
        ['PK', 'PKH'].includes(transaction.type) && {
          label: 'UCP',
          color: getFeatureTagColor('UCP'),
          tooltip: 'Uncompressed',
        },
      transaction.next?.compressed === true &&
        ['PK', 'PKH'].includes(transaction.type) && {
          label: 'CP',
          color: getFeatureTagColor('CP'),
          tooltip: 'Сompressed',
        },
      ...(Array.isArray(transaction.next?.flags)
        ? generateFeatureTagsFlags(transaction.next.flags)
        : []),
      ...(Array.isArray(transaction.next?.sighash)
        ? generateFeatureTagsSighash(transaction.next.sighash)
        : []),
    ]
      .filter(Boolean)
      .map((t) =>
        typeof t === 'string' ? { label: t, color: getFeatureTagColor(t) } : t
      ) as TagType[]
  }

  return []
}
export const compareAddresses = <
  T extends { position: number; highlighted?: boolean },
>(
  totalRows: number,
  visibleRows: number
) => {
  const lastVisibleIndex = visibleRows - 1
  const lastIndex = totalRows - 1

  return (a: T, b: T) => {
    const isALastHighlighted = a.highlighted && a.position === lastIndex
    const isBLastHighlighted = b.highlighted && b.position === lastIndex

    const subtrahend = isALastHighlighted || isBLastHighlighted ? 1 : 2

    if (a.highlighted && a.position >= lastVisibleIndex) {
      return lastVisibleIndex - b.position - subtrahend
    }

    if (b.highlighted && b.position >= lastVisibleIndex) {
      return a.position - lastVisibleIndex + subtrahend
    }

    return a.position - b.position
  }
}

const getItemId = path<string>(['address'])

export const useVisibleItems = <T extends Address[]>(
  items: T,
  visibleRows: number,
  expandedGroups: Record<number, boolean>,
  groups: Record<string, T>
) => {
  return useMemo(() => {
    const initialValue: ExtendedAddress<T[number]>[] = []

    return items.reduce((result, item) => {
      if (result.length >= visibleRows) {
        return result
      }

      const groupId = getItemId(item)
      const group = groups[groupId]

      if (group.length === 1) {
        return result.concat([{ ...item, groupId }])
      }
      if (expandedGroups[groupId]) {
        return result.concat([{ ...item, groupId }])
      }
      if (group[0] === item) {
        const mergedItem = group.reduce(
          (merged, item) => ({
            ...merged,
            position: merged.position,
            highlighted: merged.highlighted || item.highlighted,
            amount: Number(merged.amount) + Number(item.amount),
            amountUsd: Number(merged.amountUsd) + Number(item.amountUsd),
          }),
          {
            ...group[0],
            amount: 0,
            groupId,
            isGrouped: !!group.length,
          }
        )

        return result.concat(mergedItem)
      }

      return result
    }, initialValue)
  }, [items, visibleRows, groups, expandedGroups])
}
