import { ProbeGraph } from '../types/ProbeGraph'
import { ServerEdgeType, ServerNodeType } from '../types/serverData'
import { EdgeData } from '../types/edgeEntitiesData/EdgeData'
import { EdgeAttributes } from '@clain/graph'
import debounce from 'lodash/debounce'

type GetTransactionBlockReturn =
  | false
  | {
      nodeKey: string
      edgeKeys: Array<string>
    }

type NodeEdgeTypes = [ServerNodeType[], ServerEdgeType[]]
type EdgeNodeTypes = [ServerEdgeType[], ServerNodeType[]]

type NodeTypesEdgesTypes = NodeEdgeTypes[] | EdgeNodeTypes[]

const isEdgeConnectedToSelectedEdge = (
  edgeId: string,
  graph: ProbeGraph,
  source: string,
  target: string
) => {
  return graph.target(edgeId) === source || graph.source(edgeId) === target
}

const isEvmTransactionEdgeValid = (
  data: EdgeData,
  attrs: EdgeAttributes<EdgeData>
) => {
  return (
    data.edgeType === 'evm_transaction' &&
    attrs.data.edgeType === 'evm_transaction' &&
    data.type === attrs.data?.type &&
    data.index === attrs.data?.index
  )
}

const shouldIncludeEdge = (
  edgeId: string,
  graph: ProbeGraph,
  source: string,
  target: string,
  attrs: EdgeAttributes<EdgeData>,
  edgeTypes: ServerEdgeType[]
) => {
  if (!isEdgeConnectedToSelectedEdge(edgeId, graph, source, target)) {
    return false
  }

  const data = graph.getEdgeAttributes(edgeId).data

  if (edgeTypes.includes(data.edgeType)) {
    switch (data.edgeType) {
      case 'evm_transaction':
        return isEvmTransactionEdgeValid(data, attrs)
      case 'utxo_transaction':
        return true
      default:
        return true
    }
  }

  return false
}
const consoleLogEdgeType = debounce(console.info, 1000, { leading: true })

const getEdgesTypesByNodeType = (
  graph: ProbeGraph,
  nodeKey: string,
  nodeTypesEdgeTypes: NodeEdgeTypes[]
) => {
  const attrs = graph.getNodeAttributes(nodeKey)

  for (let i = 0; i < nodeTypesEdgeTypes.length; i++) {
    const [nodeType, edgeType] = nodeTypesEdgeTypes[i]
    if (nodeType.includes(attrs.data.nodeType)) {
      return edgeType
    }
  }

  return false
}

const getNodeEdgeTypesByEdgeKey = (
  graph: ProbeGraph,
  edgeKey: string,
  nodeTypesEdgeTypes: EdgeNodeTypes[]
): NodeEdgeTypes | false => {
  const attrs = graph.getEdgeAttributes(edgeKey)

  for (let i = 0; i < nodeTypesEdgeTypes.length; i++) {
    const [edgeType, nodeType] = nodeTypesEdgeTypes[i]
    if (edgeType.includes(attrs.data.edgeType)) {
      return [nodeType, edgeType] as unknown as NodeEdgeTypes
    }
  }

  return false
}

const getEdgesByEdgeTypes = (
  graph: ProbeGraph,
  nodeKey: string,
  edgeType: ServerEdgeType[]
) => {
  const edgeKeys = graph.edges(nodeKey)
  const edges: string[] = []

  edgeKeys.forEach((edgeKey) => {
    const attrs = graph.getEdgeAttributes(edgeKey)
    if (edgeType.includes(attrs.data.edgeType)) {
      edges.push(edgeKey)
    }
  })

  return edges
}

const getDomainBlock = (
  graph: ProbeGraph,
  nodeOrEdgeKey: string,
  nodeTypesEdgeTypes: NodeTypesEdgesTypes = []
): GetTransactionBlockReturn => {
  if (graph.hasNode(nodeOrEdgeKey)) {
    return getDomainBlockByNodeKey(
      graph,
      nodeOrEdgeKey,
      nodeTypesEdgeTypes as NodeEdgeTypes[]
    )
  } else {
    const attrs = graph.getEdgeAttributes(nodeOrEdgeKey)
    const nodeEdgeTypes = getNodeEdgeTypesByEdgeKey(
      graph,
      nodeOrEdgeKey,
      nodeTypesEdgeTypes as EdgeNodeTypes[]
    )

    if (!nodeEdgeTypes) return false
    const [, edgeTypes] = nodeEdgeTypes

    const source = graph.source(nodeOrEdgeKey)
    const target = graph.target(nodeOrEdgeKey)
    //@ts-expect-error
    if (attrs.data?.type) {
      consoleLogEdgeType(
        //@ts-expect-error
        `%cSelected edge type: %c ${attrs.data?.type}`,
        'color: white;',
        'color: yellow;'
      )
    }
    return graph
      .extremities(nodeOrEdgeKey)
      .map((nodeKey) => {
        const transactionBlock = getDomainBlockByNodeKey(graph, nodeKey, [
          nodeEdgeTypes,
        ])
        if (transactionBlock) {
          return {
            nodeKey: transactionBlock.nodeKey,
            edgeKeys: [
              nodeOrEdgeKey,
              ...transactionBlock.edgeKeys.filter((edgeId) =>
                shouldIncludeEdge(
                  edgeId,
                  graph,
                  source,
                  target,
                  attrs,
                  edgeTypes
                )
              ),
            ],
          }
        }

        return transactionBlock
      })
      .find((result) => result !== false)
  }
}

const getDomainBlockByNodeKey = (
  graph: ProbeGraph,
  nodeKey: string,
  nodeTypesEdgeTypes: NodeEdgeTypes[]
): GetTransactionBlockReturn => {
  const edgeTypes = getEdgesTypesByNodeType(graph, nodeKey, nodeTypesEdgeTypes)

  if (!edgeTypes) return false

  const edgeKeys = getEdgesByEdgeTypes(graph, nodeKey, edgeTypes)

  return {
    nodeKey,
    edgeKeys,
  }
}

export const getBoundedDomainBlock = (
  graph: ProbeGraph,
  nodeOrEdgeKey: string
) =>
  getDomainBlock(graph, nodeOrEdgeKey, [
    [['cluster'], ['flow']],
    [
      ['address'],
      [
        'address_belongs',
        'evm_transaction',
        'transaction_address_belongs',
        'flow',
        'cross_chain_swap_flow',
      ],
    ],
    [['evm_transaction'], ['evm_transaction']],
    [['utxo_transaction'], ['utxo_transaction']],
    [['demix'], ['demix']],
  ])

export default getDomainBlock
