import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { CounterTabs, CounterTabPropsOption } from '@clain/core/ui-kit'
import useVm from '@clain/core/useVm'
import { useNavigate } from '@clain/core/Router/router'

import Filters from '../Filters'
import NewCaseModalForm from '../NewCaseModalForm'
import { CasesHeaderViewModel } from './CasesHeaderViewModel'
import { useCtx } from '../../../../ctx'

import styles from './index.scss'

const cx = classnames.bind(styles)

const CasesHeader = () => {
  const casesHeaderVm = useVm(CasesHeaderViewModel, [useCtx(), useNavigate()])
  const tabOptions: CounterTabPropsOption[] = [
    {
      value: 'in_progress',
      children: 'in progress',
      counterValue: casesHeaderVm.counters?.inProgress,
    },
    {
      value: 'on_hold',
      children: 'on hold',
      counterValue: casesHeaderVm.counters?.onHold,
    },
    {
      value: 'archived',
      children: 'archived',
      counterValue: casesHeaderVm.counters?.archived,
    },
    {
      delimiter: true,
    },
    {
      value: 'all',
      children: 'all',
      counterValue: casesHeaderVm.counters?.all,
    },
  ]

  return (
    <div className={cx('CasesHeader')}>
      <RowDeprecated align="between" className={cx('CasesTop')}>
        <Typography variant="heading1" color="grey1">
          Cases
        </Typography>
        <RowDeprecated gap={0.5}>
          <NewCaseModalForm onSubmit={casesHeaderVm.createCase} />
        </RowDeprecated>
      </RowDeprecated>
      <Filters filters={casesHeaderVm.filters} />
      <CounterTabs
        options={tabOptions}
        value={casesHeaderVm.filters.value('status')}
        onChange={casesHeaderVm.filters.update('status')}
        className={cx('CasesBottom')}
      />
    </div>
  )
}

export default observer(CasesHeader)
