import React from 'react'

import { Typography } from '@clain/core/ui-kit'
import { ConfirmButton, ConfirmButtonProps } from '@clain/core/ui-kit'
import { ImportantCategoryProps } from './ImportantCategory.types'
import { MagicGrid } from '@clain/core/ui-kit'
import { TagDeprecated } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'

export const ImportantCategoryRemoveConfirm: React.FC<
  React.PropsWithChildren<
    Pick<ConfirmButtonProps, 'onClick'> &
      Pick<ImportantCategoryProps, 'label' | 'color'>
  >
> = ({ color, label, onClick, children }) => (
  <ConfirmButton
    as="span"
    onClick={onClick}
    confirmVariant="delete"
    title={
      <Typography variant="heading5" color="black">
        Delete importance category
      </Typography>
    }
    content={
      <MagicGrid>
        <Typography variant="body1" color="black">
          <RowDeprecated gap={0.5}>
            <TagDeprecated style={{ background: color, color: '#fff' }}>
              {label}
            </TagDeprecated>
            importance category will be deleted.
          </RowDeprecated>
        </Typography>
        <Typography variant="body1" color="black">
          Any cases currently assigned to this category will be moved to the
          Default category. Are you sure you want to delete this category?
        </Typography>
      </MagicGrid>
    }
  >
    {children}
  </ConfirmButton>
)
