import React from 'react'

import { HeaderSlot } from '@clain/core/Layout/slots'
import { Header } from '@clain/core/Layout'
import { Button } from '@clain/core/ui-kit'
import { InjectComponentModel } from '@clain/core/utils'

import { IDownloadCsv } from '../../types'
import { useInjectBlockchainModel } from '../../hooks'

const _BlockchainFilterHeader: React.FC<IDownloadCsv> = (props) => {
  const { isDisabledDownloadCsv, downloadCSV, isDownloadingCSV } = props

  HeaderSlot.useContent(
    () => (
      <Header
        title="Transaction Filter"
        iconVariant={'TxnFilter'}
        actions={
          <Button
            isLoading={isDownloadingCSV}
            variant="text"
            color="secondary"
            onClick={downloadCSV}
            disabled={isDisabledDownloadCsv || isDownloadingCSV}
            endIcon="FileCsv"
          >
            Export data
          </Button>
        }
      />
    ),
    [isDownloadingCSV, isDisabledDownloadCsv]
  )

  return null
}

export const BlockchainFilterHeader = InjectComponentModel<IDownloadCsv>(
  _BlockchainFilterHeader,
  {
    useFactory: () => {
      const { isDisabledDownloadCsv, downloadCSV, isDownloadingCSV } =
        useInjectBlockchainModel()

      return { isDisabledDownloadCsv, downloadCSV, isDownloadingCSV }
    },
  }
)
