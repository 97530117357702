import { action, makeObservable } from 'mobx'
import { QueryParamsController } from '@clain/core/utils/queryParamsUtils'
import { StateViewModel } from '@clain/core/utils/mobxUtils'
import { ClusterPageQueryParams } from '../../Cluster/Cluster.types'
import { defaultFilters } from '../../Cluster/Cluster.constants'
import { dataStatsViewModel } from './DataStatsViewModel'
import { commonPageData } from './ApiServicesStateFacade'
import { CoinType } from '../../../types/coin'
import { QueryParamsViewModel } from '../baseClasses/QueryParamsViewModel'
import { getDefaultTimestamp, getConvertToValue } from '../utils'
import {
  DEFAULT_COUNTERPARTIES_FILTERS_CURRENCY,
  DEFAULT_FILTERS,
} from '../constants'

export const queryParamsController = new QueryParamsController(
  new StateViewModel<ClusterPageQueryParams>()
)

class ClusterQueryParamsViewModel extends QueryParamsViewModel<
  Partial<ClusterPageQueryParams>
> {
  private statsViewModel = dataStatsViewModel
  private commonPageData = commonPageData

  constructor() {
    super(queryParamsController)
    makeObservable(this)
  }
  public queryParamsController = queryParamsController

  @action
  public initQueryParams = (): void => {
    const blockchain: CoinType = this.commonPageData.state.blockchain
    const defaultPeriodStart: Date = this.statsViewModel.period?.[0]!
    const defaultPeriodEnd: Date = this.statsViewModel.period?.[1]!

    const prevFilters: Partial<ClusterPageQueryParams> =
      this.queryParamsController.getUrlFormattedQueryParams({
        include_tokens: 'parseAsString',
        timestamp_from: 'parseAsDate',
        timestamp_to: 'parseAsDate',
        tbf: {
          timestamp_from: 'parseAsDate',
          timestamp_to: 'parseAsDate',
        },
        cp: {
          timestamp_from: 'parseAsDate',
          timestamp_to: 'parseAsDate',
          include_tokens: 'parseAsString',
        },
        counterparties: {
          includeTokens: ['parseAsString'],
          page: 'parseAsInt',
          sortBy: 'parseAsString',
          sortOrder: 'parseAsString',
          counterpartyName: 'parseAsString',
        },
        addresses: {
          includeTokens: ['parseAsString'],
          page: 'parseAsInt',
          sortBy: 'parseAsString',
          sortOrder: 'parseAsString',
          addressName: 'parseAsString',
        },
        trns: {
          includeTokens: ['parseAsString'],
          page: 'parseAsInt',
          sortBy: 'parseAsString',
          sortOrder: 'parseAsString',
        },
      })

    const mergedFilters = { ...defaultFilters, ...prevFilters }

    this.queryParamsController.initDefaultQueryParamsState({
      ...defaultFilters,
      timestamp_from: defaultPeriodStart,
      timestamp_to: defaultPeriodEnd,
      convert_to: getConvertToValue(defaultFilters.convert_to),
      cp: {
        ...defaultFilters.cp,
        timestamp_from: defaultPeriodStart,
        timestamp_to: defaultPeriodEnd,
        convert_to: getConvertToValue(defaultFilters.cp.convert_to),
      },
      tbf: {
        ...defaultFilters.tbf,
        timestamp_from: defaultPeriodStart,
        timestamp_to: defaultPeriodEnd,
      },
      trns: {
        ...defaultFilters.trns,
        convert_to: getConvertToValue(defaultFilters.trns.convert_to),
      },
      counterparties: {
        ...defaultFilters.counterparties,
        convert_to: getConvertToValue(defaultFilters.counterparties.convert_to),
      },
      addresses: {
        ...defaultFilters.addresses,
        convert_to: getConvertToValue(defaultFilters.addresses.convert_to),
      },
    })

    const dynamicInitialFilters = {
      ...DEFAULT_COUNTERPARTIES_FILTERS_CURRENCY[blockchain],
      includeTokens: DEFAULT_COUNTERPARTIES_FILTERS_CURRENCY[
        blockchain
      ].includeTokens?.map((token) => `${token.id}`),
    }

    this.queryParamsController.initQueryParamsState({
      ...mergedFilters,
      timestamp_from: getDefaultTimestamp(
        mergedFilters.timestamp_from,
        defaultPeriodStart
      ),
      timestamp_to: getDefaultTimestamp(
        mergedFilters.timestamp_to,
        defaultPeriodEnd
      ),
      convert_to: getConvertToValue(mergedFilters.convert_to),
      cp: {
        ...mergedFilters.cp,
        timestamp_from: getDefaultTimestamp(
          mergedFilters.cp.timestamp_from,
          defaultPeriodStart
        ),
        timestamp_to: getDefaultTimestamp(
          mergedFilters.cp.timestamp_to,
          defaultPeriodEnd
        ),
        convert_to: getConvertToValue(mergedFilters.cp.convert_to),
      },
      tbf: {
        ...mergedFilters.tbf,
        timestamp_from: getDefaultTimestamp(
          mergedFilters.tbf.timestamp_from,
          defaultPeriodStart
        ),
        timestamp_to: getDefaultTimestamp(
          mergedFilters.tbf.timestamp_to,
          defaultPeriodEnd
        ),
      },
      trns: {
        ...dynamicInitialFilters,
        ...mergedFilters.trns,
        convert_to: getConvertToValue(mergedFilters.trns.convert_to),
      },
      counterparties: {
        ...dynamicInitialFilters,
        ...mergedFilters.counterparties,
        convert_to: getConvertToValue(mergedFilters.counterparties.convert_to),
      },
      addresses: {
        ...dynamicInitialFilters,
        ...mergedFilters.addresses,
        convert_to: getConvertToValue(mergedFilters.addresses.convert_to),
      },
    })

    this.queryParamsController.initDefaultSchemaQueryParamsState({
      trns: DEFAULT_FILTERS,
      counterparties: DEFAULT_FILTERS,
      addresses: DEFAULT_FILTERS,
    })
  }
}

export const queryParamsViewModel = new ClusterQueryParamsViewModel()
