import React, { useEffect, useState } from 'react'

import classnames from 'classnames/bind'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'

import styles from './ImportantCategory.scss'
import { ImportantCategoryFieldProps } from './ImportantCategory.types'
import { RowDeprecated } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { ColorPicker, COLORS } from '@clain/core/ui-kit'

const cx = classnames.bind(styles)

export const ImportantCategoryField: React.FC<ImportantCategoryFieldProps> = ({
  id,
  label,
  color,
  onSave,
  list,
  onCancel,
  isEdit,
  onIsEdit,
  isDefault,
  onError,
}) => {
  const [fieldLabel, setFieldLabel] = useState(label)
  const [selectedColor, setSelectedColor] = useState(color)
  const error = list?.length
    ? list.some((option) => option.label === fieldLabel)
    : false

  useEffect(() => {
    onError(error)

    return () => {
      onError(false)
    }
  }, [error])

  return (
    <div className={cx('ImportantCategorySection', isEdit && 'Edit')}>
      <div className={cx('ImportantCategoryEdit')}>
        <RowDeprecated gap={0} fullWidth align="between">
          <ColDeprecated gap={1}>
            <RowDeprecated fullWidth>
              <TextField
                value={fieldLabel}
                onChange={setFieldLabel}
                variant="outline"
                className={cx('ImportantCategoryField')}
                error={error}
              />
              <ColorPicker
                list={COLORS}
                value={selectedColor}
                onChange={setSelectedColor}
              />
            </RowDeprecated>
          </ColDeprecated>
          <ColDeprecated gap={0}>
            <RowDeprecated gap={0.5}>
              <Button
                variant="solid"
                color="primary"
                size="lg"
                className={cx('ImportantCategoryButton')}
                onClick={() => {
                  onSave({
                    id,
                    label: fieldLabel,
                    color: selectedColor,
                    isDefault,
                  })
                }}
                disabled={!fieldLabel || error}
              >
                Save
              </Button>
              <Button
                variant="outline"
                color="secondary"
                size="lg"
                onClick={() => {
                  onIsEdit && onIsEdit(false)
                  setSelectedColor(color)
                  setFieldLabel(label)
                  onCancel && onCancel()
                }}
                className={cx('ImportantCategoryButton')}
              >
                Cancel
              </Button>
            </RowDeprecated>
          </ColDeprecated>
        </RowDeprecated>
      </div>
    </div>
  )
}
