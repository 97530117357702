import { Button, Stack, TypographyNew } from '@clainio/web-platform'
import React, { FC } from 'react'
import * as S from './PresetDeleteModal.styles'

type PresetDeleteModalProps = {
  isOpen: boolean
  onDelete: () => void
  onClose: () => void
}

export const PresetDeleteModal: FC<PresetDeleteModalProps> = ({
  isOpen,
  onDelete,
  onClose,
}) => (
  <S.ModalWrapper
    visible={isOpen}
    onRequestClose={onClose}
    title={
      <TypographyNew
        as={'span'}
        variant="heading300Accent"
        color="onBackgroundBase"
      >
        Delete Preset
      </TypographyNew>
    }
    target={null}
    //TODO need to fix jump scrollbar when preventScroll={true}
    preventScroll={false}
    closable
  >
    {() => {
      return (
        <Stack
          gap={'xl'}
          direction={'column'}
          padding={['xxs', 'none', 'none', 'none']}
        >
          <Stack padding={['md', 'none', 'xxl', 'none']}>
            <TypographyNew variant={'body100Normal'} color={'onBackgroundBase'}>
              You are about to delete your custom filter preset. This action is
              irreversible and cannot be undone. Are you sure you want to
              proceed?
            </TypographyNew>
          </Stack>

          <Stack align="center" justify="space-between" gap="xs">
            <Button
              variant="solid"
              color={'critical'}
              size="lg"
              onClick={() => {
                onDelete()
                onClose()
              }}
            >
              Delete
            </Button>
            <Button
              variant="outline"
              color="secondary"
              size="lg"
              onClick={() => {
                onClose()
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )
    }}
  </S.ModalWrapper>
)
